import { getAxios } from '../axiosClient'
import moment from 'moment'
import { put, select } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../index'
import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { QueryProjectsListArgs } from '../../generated/graphql'
type LoadProjectsParams = {
    accountId: string | number | null
}
export function* loadProjects(params: LoadProjectsParams) {
    //localhost:8080/api/v1/list.json?accountid=1&_=1584532511984
    const response = yield getAxios().get('/api/v1/list.json', {
        params: {
            accountid: params.accountId,
            _: moment().unix().toString(),
        },
    })
    yield put(ReduxHelper.updateInAction(['projects', 'list'], () => response.data))
    return response.data
}

export function* loadProjectsGraphQL(params: QueryProjectsListArgs) {
    const response = yield graphQLRequest(
        gql`
            query ($accountId: Float, $filters: ProjectFilter) {
                projectsList(accountId: $accountId, filters: $filters) {
                    id
                    accountid
                    name
                    img_url
                    address
                    status_pct
                    status_msg
                    blurb
                    description
                    floorplanImgName
                    floorplanImgSystemName
                    floorplanzoomimg
                    dashboardimg
                    createDate
                    lastModified
                    deleteDate
                    archiveDate
                    originalProjectId
                    hypothesisLastSync
                    theme
                    loans {
                        id
                        projectid
                        type
                        amount
                    }
                    complete
                    generalInputs {
                        loanDetails {
                            requestedLoanAmount
                            acquisitionOrRefinance
                            ARMType
                            supplemental
                        }
                        team {
                            producer
                            analyst
                            productionManager
                        }
                    }
                }
            }
        `,
        {
            accountId: params.accountId,
            filters: params.filters,
        },
    )

    yield put(ReduxHelper.updateInAction(['projects', 'list'], () => response?.data?.projectsList || []))
    return response?.data?.projectsList
}

type DeleteProjectParams = {
    id: number | null | undefined
}

export function* deleteProject(params: DeleteProjectParams) {
    const url = '/api/v1/project.json'
    const formData = new FormData()
    formData.append('projectId', `${params.id}`)
    formData.append('action', 'delete')
    const response = yield getAxios().post(url, formData)
    yield put(
        ReduxHelper.updateInAction(['projects', 'list'], (projects) => {
            const id = response.data ? response.data.projectId : null
            return projects.filter((item) => {
                return item.id !== id
            })
        }),
    )
    return response.data
}

type SendEmailsParams = {
    projectId: number | null | undefined
    emails: string
}

export function* sendEmails(params: SendEmailsParams) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $emails: [String!]!) {
                inviteGuests(projectId: $projectId, emails: $emails)
            }
        `,
        params,
    )

    return response.data.inviteGuests
}
type GetEmailsParams = {
    id: number | null
}
export function* getLendersEmails(params: GetEmailsParams) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                guestUserList(projectId: $projectId) {
                    email
                }
            }
        `,
        { projectId: params.id },
    )

    return response.data.guestUserList
}

export function* changeProjectStatus(projectId: number | string, status: string) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $status: String!) {
                changeProjectStatus(projectId: $projectId, status: $status)
            }
        `,
        { projectId: parseInt(projectId as string), status },
    )
    const projects = yield select((state: ReduxStoreState) => state.projects.list)
    let pos
    for (const ind in projects) {
        if (projects[ind].id == projectId) {
            pos = ind
            break
        }
    }
    yield put(
        ReduxHelper.updateInAction(['projects', 'list', pos], (project) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            project.status_msg = status
            return { ...project }
        }),
    )
}
