import { SagaHelper } from '../../../store/sagaHelper'

export async function showErrorNotification(message: string) {
    await SagaHelper.run(['notification', 'show'], message, 'error')
}

export async function showProjectSavedNotifications() {
    await SagaHelper.run(['notification', 'show'], 'Project was saved')
}

export async function showNarrativeSavedNotifications() {
    await SagaHelper.run(['notification', 'show'], 'Narrative Draft was saved')
}

export async function showNotification(message) {
    await SagaHelper.run(['notification', 'show'], message)
}

export async function showProgress() {
    await SagaHelper.run(['UI', 'showProgressSaga'])
}

export async function hideProgress() {
    await SagaHelper.run(['UI', 'hideProgressSaga'])
}
