import { FormApi } from 'final-form'
import { useEffect, useRef } from 'react'
import numeral from 'numeral'
export * from './yupValidateObject'

export function parseQuery(): { [key: string]: string } {
    let query = window.location.search
    if (query.length == 0) return {}
    query = query.substr(1, query.length - 1)
    const arr = query.split('&')
    const out: { [key: string]: string } = {}
    for (const p of arr) {
        const params = p.split('=')
        if (params.length == 2) {
            out[params[0]] = params[1]
        } else {
            out[p] = '1'
        }
    }
    return out
}

export function removeItemFromArray(array: Array<any>, index: number = array.length) {
    array.splice(index, 1)
    return array
}
export function removeItemsFromArray(array: Array<any>, index: number = array.length, length?: number) {
    if (!length) length = 1
    //console.log('removeItemsFromArray', index, length, array)
    array.splice(index, length)
    return array
}
export function objectToFormData(obj: any) {
    const formData: FormData = new FormData()
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] instanceof Blob) {
                formData.append(key, obj[key], obj?.[key]?.name || 'file.jpg')
            } else {
                formData.append(key, obj[key])
            }
        }
    }
    return formData
}

export function makeRandomId(length = 20) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
export function formIsModified(formApi: FormApi) {
    const modified = formApi.getState().modified
    for (const fld in modified) {
        if (modified[fld]) return true
    }
    return false
}

export function addToObject(obj, key, value, index) {
    // Create a temp object and index variable
    const temp = {}
    let i = 0

    // Loop through the original object
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            // If the indexes match, add the new item
            if (i === index && key && value) {
                temp[key] = value
            }

            // Add the current item in the loop to the temp obj
            temp[prop] = obj[prop]

            // Increase the count
            i++
        }
    }

    // If no index, add to the end
    if (!index && key && value) {
        temp[key] = value
    }

    return temp
}

export function calculateProjectStepStatus(values, schema) {
    let totalFields = 0
    let passedFields = 0

    for (const key in schema) {
        totalFields++
        if (schema[key] == 'value') {
            if (!!values[key]) {
                passedFields++
            }
        }
        if (schema[key] == 'array') {
            if (values[key]?.length > 0) {
                passedFields++
            }
        }
    }

    return totalFields !== 0 ? Math.floor((passedFields / totalFields) * 100) : 0
}
//parser for final form
export const roundNumber = (value: any): any => {
    const out = value ? Math.round(parseFloat(value)).toString() : value
    return out.toString() == 'NaN' ? '' : +out
}
export const CurrencyConverter = {
    parse: (value: string | number | null) => {
        if (!value || typeof value == 'number') return value
        const regEx = /^\d+$/
        if (value.toString().match(regEx)) return value
        value = value.replace(/,/g, '')
        return parseInt(value)
    },
    format: (value: any) => {
        if (!isNaN(parseInt(value))) return numeral(value).format('0,0')
        else return value
    },
}
export const intParser = (value) => {
    if (!value || typeof value == 'number') return value
    const regEx = /^\d+$/
    if (value.toString().match(regEx)) return value
    return parseInt(value)
}
export const floatParser = (value) => {
    if (!value || typeof value == 'number') return value
    const regEx = /^\d+\.0*$/
    if (value.toString().match(regEx)) return value
    return parseFloat(value).toString() == 'NaN' ? '' : +parseFloat(value)
}

export function ucFirst(str: string): string {
    if (!str) return str

    return str[0].toUpperCase() + str.slice(1)
}

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

export const parseAreaInformationFieldName = (fieldName: string) => {
    const fieldTitles = {
        ['rented']: 'Rented',
        ['owned']: 'Owned',

        ['with_children']: 'With Children',
        ['without_children']: 'Without Children',

        ['occupied_household']: 'Occupied Household',
        ['vacant_housing']: 'Vacant Housing',

        ['family_occupants']: 'Family Occupants',
        ['non-family_occupants']: 'Non-family Occupants',

        ['single_detached']: 'Single Detached',
        ['2_to_49_units']: '2 to 49 Units',
        ['50+_units']: '50+ units',
        ['mobile_home']: 'Others',

        ['less_than_$20000']: 'Less than $20,000',
        ['$20000_to_$34999']: '$20,000 to $34,999',
        ['$35000_to_$49999']: '$35,000 to $49,999',
        ['$50000_to_$99999']: '$50,000 to $99,999',
        ['$100000_to_$199999']: '$100,000 to $199,999',
        ['$200000_or_more']: '$200,000 or more',

        ['1939_or_earlier']: '1939 or earlier',
        ['1940_to_1949']: '1940 to 1949',
        ['1950_to_1959']: '1950 to 1959',
        ['1960_to_1969']: '1960 to 1969',
        ['1970_to_1979']: '1970 to 1979',
        ['1980_to_1989']: '1980 to 1989',
        ['1990_to_1999']: '1990 to 1999',
        ['2000_to_2010']: '2000 to 2010',
        ['2010_or_later']: '2010 or later',
    }

    return fieldTitles[fieldName] || fieldName
}

export const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
}
export function isObjectEmpty(obj) {
    for (const key in obj) {
        if (obj[key]) return false
    }
    return true
}

export function useDebouncedCallback<A extends any[]>(callback: (...args: A) => void, wait: number) {
    // track args & timeout handle between calls
    const argsRef = useRef<A>()
    const timeout = useRef<ReturnType<typeof setTimeout>>()

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
    }

    // make sure our timeout gets cleared if
    // our consuming component gets unmounted
    useEffect(() => cleanup, [])

    return function debouncedCallback(...args: A) {
        // capture latest args
        argsRef.current = args

        // clear debounce timer
        cleanup()

        // start waiting again
        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current)
            }
        }, wait)
    }
}

export const metersToMiles = (meters: number) => {
    return (meters / 1000 / 1.6).toFixed(2) || 0
}

export const capitalize = (str: string) => {
    const splitStr = str.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
}
export function lcFirst(text: string) {
    if (!text) return ''
    const f = text.substr(0, 1)
    const rest = text.substr(1)
    return f.toLocaleLowerCase() + rest
}
