import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { myStorage } from '../../storage'
import { getSerilazeDate } from 'utils/getSerilazeDate'

import { GeneralInputs } from '@generated/graphql'
const cloneDeep = require('lodash.clonedeep')

export function* getData(params: { projectId: number }) {
    const DEBUG = false
    const { projectId } = params
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getProjectGeneralInputsTeam {
                    team {
                        memberName
                        memberTitle
                        jobFunction
                    }
                }
                getProjectGeneralInputs(projectId: $projectId) {
                    propertyAndBorrower {
                        propertyName
                        numberOfUnits
                        yearBuilt
                        yearRenovated
                        address
                        city
                        zip
                        state
                        county
                        msa
                        censusTract
                        propertyType
                        buildingType
                        MAH
                        lowRatioAffordable
                        borrowerName
                        principals {
                            name
                            guarantor
                        }
                    }
                    loanDetails {
                        targetLoanClosing
                        requestedLoanAmount
                        requestedLoanAmortization
                        requestedLoanTerm
                        requestedLoanIO
                        requestedLoanPrePaymentLock
                        requestedTierLevel
                        interestType
                        lienPosition
                        acquisitionOrRefinance
                        borrowerLoanPurpose
                        loanPurposeGateway
                        fannieMaeRefinanceType
                        ARMType
                        supplemental
                        assumption
                        transfer
                        _currentOldSeniorLoanAmount
                    }
                    dealDetails {
                        purchasePrice
                        currentUPB
                        cashout
                        constructionCost
                        recentConstruction
                        escrowedImprovements
                        prepay
                        budgetedCapEx
                        greenFinancingType
                        greenBuildingCertificationType
                        moderateRehabilitationGateway
                        rehabilitationWorkPerUnit
                        rehabilitationWorkEscrowAmount
                        condominiumRegimeType
                        propertyStabilizationLevel
                        expectedtoReachStable
                        landOwnershipRights
                    }
                    team {
                        teamName
                        producer
                        analyst
                        originatorOffice
                        productionManager
                        productionManagementAnalyst
                        underwriter
                        underwritingAnalyst
                        underwritingManager
                        chiefUnderwriter
                        loanNumberTitle
                        loanNumber
                    }
                }
            }
        `,
        {
            projectId: projectId,
        },
    )

    if (DEBUG) console.log('Model respnse', response)
    // transform server data to more usefull format
    const reduxData: GeneralInputs = response.data.getProjectGeneralInputs
    yield put(ReduxHelper.setInAction(['lender', 'generalInputs'], reduxData || null))
    return response?.data
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'generalInputs'], null))
}

export function* setGeneralInputs(params: { projectId: number; data: any }, step: string) {
    // console.log('params', cloneDeep(params))
    if (!!params?.data?.loanDetails?.targetLoanClosing) {
        params.data.loanDetails.targetLoanClosing = getSerilazeDate(params.data.loanDetails.targetLoanClosing)
    }
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: GeneralInputsInput!) {
                setProjectGeneralInputs(projectId: $projectId, data: $data)
            }
        `,
        {
            ...params,
        },
    )
    const hasGeneralInputChanged: any = yield select(
        (state: ReduxStoreState) => state.lenderProjects.hasGeneralInputChanged,
    )
    //console.log('step', step)
    if (step && hasGeneralInputChanged) {
        if (step == 'deal') {
            yield put(
                ReduxHelper.setInAction(
                    ['lenderProjects', 'hasGeneralInputChanged', 'deal'],
                    response?.data?.setProjectGeneralInputs,
                ),
            )
        }
        if (step == 'loan') {
            yield put(
                ReduxHelper.setInAction(
                    ['lenderProjects', 'hasGeneralInputChanged', 'loan'],
                    response?.data?.setProjectGeneralInputs,
                ),
            )
        }
        if (step == 'property') {
            yield put(
                ReduxHelper.setInAction(
                    ['lenderProjects', 'hasGeneralInputChanged', 'property'],
                    response?.data?.setProjectGeneralInputs,
                ),
            )
        }
    }

    return response?.data?.setProjectGeneralInputs || false
}
