import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { parseAgGridToJson, parseJsonToAgGridDealHighlights } from '../../../utils/agGrid'
import { makeRandomId, objectToFormData } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.DEAL_HIGHLIGHTS_PART_II

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        const data = response?.data?.project
        yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'data'], () => data))
        const parsedStoreDataDetails = data.details ? JSON.parse(data.details) : {}
        const parsedStoreDataDefault = data.default ? JSON.parse(data.default) : {}

        let shortTablesData = []
        let longTablesData = []

        let initialLoans = null
        let initialDetails = null

        if (parsedStoreDataDetails?.tables_short || parsedStoreDataDetails?.tables_long) {
            shortTablesData = parsedStoreDataDetails.tables_short || []
            longTablesData = parsedStoreDataDetails.tables_long || []
        } else {
            shortTablesData = parsedStoreDataDefault?.tables_short || []
            longTablesData = parsedStoreDataDefault?.tables_long || []
            yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'isDefault'], () => true))
        }

        if (parsedStoreDataDetails?.loan) {
            initialLoans = parsedStoreDataDetails.loan
        } else {
            initialLoans = parsedStoreDataDefault.loan
        }

        if (parsedStoreDataDetails?.details) {
            initialDetails = parsedStoreDataDetails.details
        } else {
            initialDetails = parsedStoreDataDefault.details
        }

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialDetails'], (item) => {
                return [...initialDetails]
            }),
        )
        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialLoans'], (item) => {
                return [...initialLoans]
            }),
        )

        for (const key in shortTablesData) {
            shortTablesData[key] = parseJsonToAgGridDealHighlights(shortTablesData[key])
            shortTablesData[key]['gridId'] = makeRandomId()
        }

        if (!(shortTablesData.length % 2 == 0)) {
            shortTablesData.push({
                cols: [],
                rows: [],
                gridId: makeRandomId(),
            })
        }

        for (const key in longTablesData) {
            longTablesData[key] = parseJsonToAgGridDealHighlights(longTablesData[key])
            longTablesData[key]['gridId'] = makeRandomId()
        }

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], () => {
                return [...shortTablesData]
            }),
        )
        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], () => {
                return [...longTablesData]
            }),
        )

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialShortTables'], () => {
                return JSON.stringify(shortTablesData)
            }),
        )
        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialLongTables'], () => {
                return JSON.stringify(longTablesData)
            }),
        )

        const defaultShortTables = parsedStoreDataDefault.tables_short
        for (const key in defaultShortTables) {
            defaultShortTables[key] = parseJsonToAgGridDealHighlights(defaultShortTables[key])
            defaultShortTables[key]['gridId'] = makeRandomId()
        }

        const defaultLongTables = parsedStoreDataDefault.tables_long
        for (const key in defaultLongTables) {
            defaultLongTables[key] = parseJsonToAgGridDealHighlights(defaultLongTables[key])
            defaultLongTables[key]['gridId'] = makeRandomId()
        }

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultShortTables'], () => {
                return JSON.stringify(defaultShortTables)
            }),
        )
        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultLongTables'], () => {
                return JSON.stringify(defaultLongTables)
            }),
        )

        return response.data
    }
}

type RemoveTableParams = {
    index: number
}

export function* removeLongTable(params: RemoveTableParams) {
    let gridId: string | null = null

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], (tables) => {
            return tables.filter((table, index) => {
                if (index == params.index) {
                    gridId = table.gridId
                }
                return index !== params.index
            })
        }),
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            return tables.filter((table) => table.gridId !== gridId)
        }),
    )
}

type AddTableParams = {
    index: number
}

export function* addLongTable(params: AddTableParams) {
    const gridId = makeRandomId(10)
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            let startKey: any = 0
            for (const key in tables) {
                if (tables[key]['tableType'] == 'longTable') {
                    startKey = key
                    break
                }
            }
            tables.splice(+startKey + +params.index + 1, 0, {
                gridId,
            })
            return [...tables]
        }),
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], (tables) => {
            tables.splice(params.index + 1, 0, {
                cols: [],
                rows: [],
                gridId,
            })
            return [...tables]
        }),
    )
}

type ClearTableParams = {
    index: number
}

export function* clearLongTable(params: ClearTableParams) {
    let gridId: string | null = null
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], (tables) => {
            gridId = tables[params.index].gridId
            tables[params.index] = {
                cols: [],
                rows: [],
                gridId,
            }
            return [...tables]
        }),
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            return tables.filter((table) => table.gridId !== gridId)
        }),
    )
}

type SetupTableParams = {
    index: number
    cols: number
    gridId: string
}

export function* setupLongTable(params: SetupTableParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], (tables) => {
            for (const key in tables) {
                if (tables[key].gridId === params.gridId) {
                    tables[key] = generateEmptyTable(params.cols, params.gridId)
                }
            }
            return [...tables]
        }),
    )
}

export function* removeShortTable(params: RemoveTableParams) {
    let gridId = null
    let gridId2 = null

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], (tables) => {
            for (const key in tables) {
                if (+key == +params.index) {
                    gridId = tables[key]['gridId']
                }
                if (+key == +params.index - 1) {
                    gridId2 = tables[key]['gridId']
                }
            }
            return tables.filter((table, index) => {
                return index !== params.index && index !== params.index - 1
            })
        }),
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            return tables.filter((table) => {
                return table.gridId !== gridId
            })
        }),
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            return tables.filter((table) => {
                return table.gridId !== gridId2
            })
        }),
    )
}

export function* addShortTable(params: AddTableParams) {
    const gridId = makeRandomId(10)
    const gridId2 = makeRandomId(10)

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            tables.splice(params.index + 1, 0, {
                gridId,
            })

            tables.splice(params.index + 1, 0, {
                gridId: gridId2,
            })

            return [...tables]
        }),
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], (tables) => {
            tables.splice(params.index + 1, 0, {
                cols: [],
                rows: [],
                gridId: gridId,
            })
            tables.splice(params.index + 1, 0, {
                cols: [],
                rows: [],
                gridId: gridId2,
            })
            return [...tables]
        }),
    )
}

export function* clearShortTable(params: ClearTableParams) {
    let gridId: string | null = null
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], (tables) => {
            gridId = tables[params.index].gridId
            tables[params.index] = {
                cols: [],
                rows: [],
                gridId,
            }
            return [...tables]
        }),
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (tables) => {
            return tables.filter((table) => table.gridId !== gridId)
        }),
    )
}

export function* setupShortTable(params: SetupTableParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], (tables) => {
            for (const key in tables) {
                if (tables[key].gridId === params.gridId) {
                    tables[key] = generateEmptyTable(params.cols, params.gridId)
                }
            }
            return [...tables]
        }),
    )
}

type AddColParams = {
    tableIndex: number
    colIndex: number
    field: string
}
export function* addShortTableCol(params: AddColParams) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'dealHighlightsPartTwo', 'shortTables', params.tableIndex],
            (table) => {
                table.cols.splice(params.colIndex + 1, 0, {
                    headerName: '',
                    field: params.field,
                    editable: true,
                })
                return { ...table }
            },
        ),
    )
}

export function* addLongTableCol(params: AddColParams) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'dealHighlightsPartTwo', 'longTables', params.tableIndex],
            (table) => {
                table.cols.splice(params.colIndex + 1, 0, {
                    headerName: '',
                    field: params.field,
                    editable: true,
                })
                return { ...table }
            },
        ),
    )
}

type removeTableColParams = {
    tableIndex: number
    colIndex: number
}

export function* removeShortTableCol(params: removeTableColParams) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'dealHighlightsPartTwo', 'shortTables', params.tableIndex],
            (table) => {
                table.cols.splice(params.colIndex, 1)
                return { ...table }
            },
        ),
    )
}

export function* removeLongTableCol(params: removeTableColParams) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'dealHighlightsPartTwo', 'longTables', params.tableIndex],
            (table) => {
                table.cols.splice(params.colIndex, 1)
                return { ...table }
            },
        ),
    )
}

type SetFormDataParams = {
    gridId: string
    gridData: Array<any>
    tableType: string
}

export function* setFormData(params: SetFormDataParams) {
    let token = null
    switch (params.tableType) {
        case 'shortTable':
            token = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.shortTables
            break
        case 'longTable':
            token = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.longTables
            break
    }

    let tables
    if (token) {
        tables = yield select(token)
    }

    const table = tables?.find((item) => {
        return item.gridId === params.gridId
    })

    if (table) {
        const colNames = table.cols.map((item) => item.field)
        const sortedGridData = []

        for (const key in params.gridData) {
            const row = {}
            let i = 1
            if (+key > 0) {
                row['col0'] = params.gridData[key]['col0']
            }
            for (const col of colNames) {
                if (!(col === 'control')) {
                    row[`col${i}`] = params.gridData[key][col] || ''
                    i++
                }
            }
            sortedGridData.push(row)
        }
        params.gridData = sortedGridData
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => {
            const existGrid = form.filter((item) => {
                return item.gridId === params.gridId
            })
            if (existGrid.length === 0) {
                form.push(params)
            } else {
                let existIndex = null
                form.find((elem, index) => {
                    if (elem.gridId === params.gridId) {
                        existIndex = index
                        return true
                    }
                    return false
                })
                form[existIndex] = params
            }
            return [...form]
        }),
    )
}

export function* clearFormData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => []))
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo
    const data = yield select(token)

    const initialLoans = yield select((store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.initialLoans)
    const initialDetails = yield select(
        (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.initialDetails,
    )

    const details = parseAgGridToJson(data.form)
    //@ts-ignore
    details.loan = initialLoans
    //@ts-ignore
    details.details = initialDetails

    const requestBody = {
        projectId: data.projectId,
        statusp2: 100,
        method: 'p2',
        details: JSON.stringify(details),
    }

    const response = yield getAxios().post(API_URL, objectToFormData({ propertyDetails: JSON.stringify(requestBody) }))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.DEAL_HIGHLIGHTS_II], () => 100))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'isDefault'], () => false))

    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => []))
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'data'], () => {
            return {
                details: '',
                default: '',
            }
        }),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultShortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultLongTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialShortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialLongTables'], () => []))

    return response.data
}

export function* setProjectId(params) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'projectId'], () => params))
}

export function* setInitialTableData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => []))
    const shortTableToken = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.initialShortTables
    const longTableToken = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.initialLongTables

    const shortTableData = yield select(shortTableToken)
    const longTableData = yield select(longTableToken)

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], () =>
            JSON.parse(shortTableData),
        ),
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], () =>
            JSON.parse(longTableData),
        ),
    )
}

export function* setDefaultTableData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => []))
    const shortTableToken = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.defaultShortTables
    const longTableToken = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartTwo.defaultLongTables

    const shortTablesData = yield select(shortTableToken)
    const longTablesData = yield select(longTableToken)

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], () =>
            JSON.parse(shortTablesData),
        ),
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], () =>
            JSON.parse(longTablesData),
        ),
    )
}

export function* clearStore() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'isDefault'], () => false))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'form'], (form) => []))
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'data'], () => {
            return {
                details: '',
                default: '',
            }
        }),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'shortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'longTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultShortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'defaultLongTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialShortTables'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartTwo', 'initialLongTables'], () => []))
}

function generateEmptyTable(colsNumber: number, gridId: string) {
    const newEmptyTable = {
        gridId,
        cols: [],
        rows: [],
    }
    const newTitleRow = {}
    const newFirstRow = {}
    for (let i = 1; i <= colsNumber; i++) {
        newEmptyTable.cols.push({
            field: `col${i}`,
            // @ts-ignore
            editable: true,
        })
        newTitleRow[`col${i}`] = `Title ${i}`
        newFirstRow[`col${i}`] = ``
    }
    newEmptyTable.rows.push(newTitleRow)
    newEmptyTable.rows.push(newFirstRow)
    newEmptyTable.cols.push({
        headerName: '',
        field: 'control',
        cellRenderer: 'DealHighlightsPartTwoAgGridControlCell',
    })

    return newEmptyTable
}
