import { UserSettingsInput } from '@generated/graphql'
import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper } from '../index'

export function* getData() {
    const response = yield graphQLRequest(
        gql`
            query {
                userSettings {
                    accountName
                    fullName
                    phone
                    showMFA
                    enableMFA
                    useNCFnotNOI
                    allowViewAllProjects
                }
            }
        `,
        {},
    )
    yield put(ReduxHelper.setInAction(['account', 'user', 'settings'], response.data.userSettings))
    return response?.data?.userSettings
}

export function* askSMSCode(data: UserSettingsInput) {
    const response = yield graphQLRequest(
        gql`
            mutation ($data: UserSettingsInput!) {
                askSMSCode(data: $data) {
                    status
                    message
                }
            }
        `,
        { data },
    )

    return response?.data?.askSMSCode
}
export function* setData(data: UserSettingsInput) {
    const response = yield graphQLRequest(
        gql`
            mutation ($data: UserSettingsInput!) {
                saveUerSettings(data: $data) {
                    status
                    message
                }
            }
        `,
        {
            data,
        },
    )

    return response?.data?.saveUerSettings
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['account', 'user', 'settings'], null))
}
