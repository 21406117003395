import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { AdjustmentCommentGraphQl } from '@generated/graphql'

export function* saveAdjustmentComment(params: { projectId: number; data: AdjustmentCommentGraphQl }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: AdjustmentCommentInputGraphQL!) {
                saveAdjustmentComment(projectId: $projectId, data: $data)
            }
        `,
        {
            projectId: params.projectId,
            data: params.data,
        },
    )
    return response.data.saveQuote
}
