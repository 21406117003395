import { put, delay, take, fork, select } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../index'
import { SagaHelper } from '../sagaHelper'
import { eventChannel } from 'redux-saga'
import { graphQLRequest, graphqlSubscribe } from '../graphQLClient'
import gql from 'graphql-tag'
import { showErrorNotification, showNotification } from '../../components/pages/fullproject/notifications'
import { getData as getDataComparables } from './fullProject/comparables'
import { myStorage } from '../storage'
import { arrayToObject } from '../../utils/arrayUtils'

const DEBUG = false

export function* show(message: string, type = 'info') {
    yield put(ReduxHelper.mergeInAction(['notification'], { show: true, message, type }))
    if (type == 'error') {
        yield delay(4000)
    } else {
        yield delay(2000)
    }
    yield put(ReduxHelper.mergeInAction(['notification'], { show: false }))
    return true
}

export function* showAndHold(message: string, type = 'info'): Generator<any, boolean, any> {
    yield put(ReduxHelper.mergeInAction(['notification'], { show: true, message, type }))
    return true
}
export function* hideHolded(): Generator<any, boolean, any> {
    yield put(ReduxHelper.mergeInAction(['notification'], { show: false }))
    return true
}

async function createSocketChannel() {
    if (DEBUG) console.log(' notification createSocketChannel')
    const res = await graphQLRequest(
        gql`
            query {
                subscriptionToken
            }
        `,
        {},
    )
    return eventChannel((emit) => {
        const subscription = graphqlSubscribe(
            gql`
                subscription {
                    userNotification {
                        type
                        message
                        payload {
                            key
                            value
                            type
                        }
                    }
                }
            `,
            {},
            { Authorization: res.data.subscriptionToken },
        ).subscribe((data) => {
            const notification = data.data.userNotification
            notification.payload = arrayToObject(notification.payload)
            emit(notification)
        })
        //  console.log('Subscribed to notifications', res.data.subscriptionToken)
        return () => {
            subscription.unsubscribe()
        }
    })
}
export function* handleNotifications() {
    const channel = yield createSocketChannel()
    while (true) {
        const notification = yield take(channel)
        if (DEBUG) console.log('got  notification', notification)
        switch (notification?.type) {
            case 'COMPARABLE_SCRAPING_FINISHED':
                yield onScrapFinished(notification)
                break
            case 'COMPARABLE_SCRAPING_FAILED':
                yield onScrapFailed(notification)
                break
            case 'COMPARABLE_SCRAPING_PROGRESS':
                yield onScrapProgress(notification)
                break
            case 'PDF_EXTRACT_FINISHED':
                yield onPdfExtractFinished(notification)
                break
            case 'MIXED_EXCEL_FINISHED':
                yield onMixedExcelFinished(notification)
                break
            case 'PROJECT_VERSION_BACKUP_SAVED':
                yield onProjectVersionFinished(notification)
                break
            case 'NARRATIVE_VERSION_BACKUP_SAVED':
                yield onNarrativeVersionFinished(notification)
                break
            default:
                yield put(ReduxHelper.setInAction(['notification', 'lastNotification'], notification))
        }
    }
}
export function* listen() {
    if (DEBUG) console.log('listen')
    if (myStorage.getItem('socketChannelListener')) {
        if (DEBUG) console.log('found listen')
        return
    }
    const listener = yield fork(handleNotifications)
    myStorage.setItem('socketChannelListener', listener)
}
function* onScrapFinished(notification: any) {
    yield showNotification(notification.message)
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], false))
    const projectId = yield select((state: ReduxStoreState) => state.fullProject.id)
    yield getDataComparables({ projectId })
}
function* onScrapFailed(notification: any) {
    yield showErrorNotification(notification.message)
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], false))
}
function* onScrapProgress(notification: any) {
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapProgress'], notification.payload.progress))
}

function* onPdfExtractFinished(notification: any) {
    SagaHelper.run(['lenderProjects', 'getProjectInfo'], {
        projectId: notification.payload.projectId,
    })
    yield put(ReduxHelper.setInAction(['projectWizard', 'pdfExtracted'], notification.payload.fileId))
}

function* onMixedExcelFinished(notification: any) {
    //console.log('done mixed excel')
    SagaHelper.run(['lenderProjects', 'getProjectInfo'], {
        projectId: notification.payload.projectId,
    })
    yield put(ReduxHelper.setInAction(['projectWizard', 'pdfExtracted'], notification.payload.fileId))
}

function* onProjectVersionFinished(notification: any) {
    //ToDo
    const projectId = notification?.payload?.projectId //who invoked it
    const versionedProjectId = notification?.payload?.versionedProjectId
}
function* onNarrativeVersionFinished(notification: any) {
    //ToDo
    const projectId = notification?.payload?.projectId //who invoked it
    const narrativeId = notification?.payload?.narrativeId //who invoked it
    const versioneNarrativeId = notification?.payload?.versioneNarrativeId
}
