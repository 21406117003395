import { getAxios } from '../../axiosClient'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper } from '../../index'
import { API } from '../../../utils/constants/api'

const API_URL = API.FULL_PROJECT.STATUS

type GetStatusParams = {
    projectId: string | any
}

export function* getStatuses(params: GetStatusParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        yield put(ReduxHelper.mergeInAction(['fullProject', 'status'], response?.data?.project))
        yield put(ReduxHelper.mergeInAction(['fullProject'], { name: response?.data?.name }))

        return response.data
    }
}

export function* clearStatuses() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'status'], (statuses) => {
            for (let key in statuses) {
                statuses[key] = '0'
            }
            return { ...statuses }
        }),
    )
}
