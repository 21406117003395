import { LenderDashboardSetting } from '../../../generated/graphql'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper } from '../../index'

export function* getData(p?: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int) {
                lenderDashboardSetting(projectId: $projectId) {
                    freeCashFlowBeforeDebt
                    freeCashFlowAfterDebt
                    dscr
                    debtYield
                    showEditAssumptions
                    data {
                        maintenancePerUnitTopThreshold
                        maintenancePerUnitBottomThreshold
                        payrollPerUnitTopThreshold
                        payrollPerUnitBottomThreshold
                    }
                }
            }
        `,
        { projectId: p?.projectId || null },
    )

    yield put(ReduxHelper.setInAction(['lender', 'settings'], response.data.lenderDashboardSetting))
    return response?.data?.lenderDashboardSetting
}

export function* setData(params: { userId: number; data: LenderDashboardSetting }) {
    const { userId, data } = params

    data.debtYield = +data.debtYield
    data.freeCashFlowAfterDebt = +data.freeCashFlowAfterDebt
    data.freeCashFlowBeforeDebt = +data.freeCashFlowBeforeDebt
    data.dscr = +data.dscr

    const response = yield graphQLRequest(
        gql`
            mutation ($userId: Int!, $data: LenderDashboardSettingInput!) {
                saveLenderDashboardSetting(userId: $userId, data: $data)
            }
        `,
        {
            userId,
            data,
        },
    )

    return response?.data?.saveLenderDashboardSetting
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'settings'], null))
}
