import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.SPONSOR_COMPANY_INFO

type getSponsorCompanyInfoParams = {
    projectId: string | any
}

export function* getData(params: getSponsorCompanyInfoParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        yield put(ReduxHelper.mergeInAction(['fullProject', 'sponsorsCompanyInfo', 'data'], response?.data?.project))
        return response.data
    }
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.sponsorsCompanyInfo.form
    const formData = yield select(token)

    const status = calculateProjectStepStatus(formData, {
        companies: 'array',
    })

    formData.status = status

    const payloadFormData = new FormData()
    payloadFormData.set('propertyDetails', JSON.stringify(formData))
    const response = yield getAxios().post(API_URL, payloadFormData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'sponsorsCompanyInfo', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.SPONSOR_COMPANY_INFO], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'sponsorsCompanyInfo', 'form'], () => params))
}
