// Should be used only from server side scripts !!!
// It will not work in browser, because process.env is not accessible in client side
if (typeof window !== 'undefined') {
    throw new Error("Don't import config, use require in server side scripts")
}
export const Config = {
    LINKEDIN_CLIENT_ID: process.env.LINKEDIN_CLIENT_ID || '78d5t1j85crfsd',
    LINKEDIN_CLIENT_SECRET: process.env.LINKEDIN_CLIENT_SECRET || 'srJe7l0szhjtJDjL',
    LINKEDIN_REDIRECT_URI: process.env.LINKEDIN_REDIRECT_URI || 'http://localhost:8080/auth/linkedin',
    GOOGLE_ANALYTICS_KEY: process.env.GOOGLE_ANALYTICS_KEY || 'UA-152790750-2',
    GOOGLE_CLIENT_ID:
        process.env.GOOGLE_CLIENT_ID || '6294657395-66p5m5nba3s7t1ine3ikcbl6lm3in55i.apps.googleusercontent.com',
    GOOGLE_REDIRECT_URI: process.env.GOOGLE_REDIRECT_URI || 'http://localhost:8080/auth/google',
    PHP_API: process.env.NODE_ENV == 'development' ? 'http://localhost:8080' : 'http://phpapp',
    NODE_API: process.env.NODE_ENV == 'development' ? 'http://localhost:4000/graphql' : 'http://nodeapp:4000/graphql',
    VIEW_OM_ACCESS_TOKEN: '83E6GZ4JeyTyRQPt4z3HcnZW',
    ENABLED_MFA: false,
    SECRET_KEY: 'Seecares special key', //should be same as prod
    ENV: process.env.NODE_ENV,
}
