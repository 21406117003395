// import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, DocumentNode, execute, makePromise } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { reportError } from '../utils/errorReporting'
const { createUploadLink } = require('apollo-upload-client')

class ApiError extends Error {
    code: string
    graphqlErrors: Array<any>
}
export async function graphQLRequest(
    gqlQuery: DocumentNode,
    variables: Record<string, any> = {},
    headers = {},
): Promise<any> {
    try {
        let url = '/graphql'
        const operation = {
            query: gqlQuery,
            variables,
        }
        let link: ApolloLink
        if (typeof window == 'undefined') {
            const { Config } = require('../config')
            url = Config.NODE_API
            const fetch = require('node-fetch')
            link = createUploadLink({
                uri: url,
                credentials: 'include',
                headers,
                fetch,
            })
        } else {
            link = createUploadLink({
                uri: url,
                credentials: 'include',
                headers,
                fetchOptions: {},
            })
        }

        const data: any = await makePromise(execute(link, operation))
        if (data.errors) {
            const e = data.errors[0]
            const error = new ApiError(e.message)
            error.code = e.extensions.code
            error.graphqlErrors = data.errors
            try {
                reportError(
                    null,
                    error,
                    (gqlQuery ? JSON.stringify(gqlQuery) : 'no gqlQuery') + +JSON.stringify(variables),
                    JSON.stringify(data.errors),
                )
            } catch (re) {}
            throw error
        }
        return data
    } catch (e) {
        console.error('======================================')
        console.error(e.message)
        console.error('======================================')
        console.error('======================================')
        console.error(JSON.stringify(e))
        console.error('======================================')
        console.error('REQUEST', gqlQuery?.loc?.source?.body)
        for (const error of e.result?.errors || []) {
            console.error('GQL ERROR', error)
        }
        console.error('PARAMS', variables)
        try {
            reportError(
                null,
                e,
                'QUERY:    ' + JSON.stringify(gqlQuery) + 'Variables:   ' + JSON.stringify(variables),
                JSON.stringify(e.result?.errors),
            )
        } catch (re) {}

        throw e
    }
}
export function graphqlSubscribe(gqlQuery: DocumentNode, variables: Record<string, any> = {}, headers = {}) {
    let protocol = 'ws'
    if (window.location.protocol == 'https:') {
        protocol = 'wss'
    }
    const wsLink = new WebSocketLink({
        uri: `${protocol}://${location.host}/graphql-ws`,
        options: {
            reconnect: true,
            connectionParams: {
                ...headers,
            },
        },
    })
    const operation = {
        query: gqlQuery,
        variables,
    }
    return execute(wsLink, operation)
}
