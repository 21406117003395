import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { QuoteGraphQl } from '@generated/graphql'
import { put, select } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../index'

export function* saveQuote(params: { projectId: number; data: QuoteGraphQl }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: QuoteInputGraphQL!) {
                saveQuote(projectId: $projectId, data: $data)
            }
        `,
        {
            projectId: params.projectId,
            data: params.data,
        },
    )
    return response.data.saveQuote
}
export function* setQuoteAsDefault(params: { projectId: number; quoteId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $quoteId: Int!) {
                setQuoteAsDefault(projectId: $projectId, quoteId: $quoteId)
            }
        `,
        {
            ...params,
        },
    )
    const quotes: QuoteGraphQl[] = yield select((state: ReduxStoreState) => state.lender.quoteData)
    for (const q of quotes || []) {
        q.default = q.id == params.quoteId
    }
    yield put(ReduxHelper.setInAction(['lender', 'quoteData'], [...quotes]))
    return response.data.setQuoteAsDefault
}
