export const STEP_ID = Object.freeze({
    DEAL_OUTLINE: 'dealoutline',
    THE_PROPERTY: 'theproperty',
    MARKET_OVERVIEW: 'market',
    SPONSORS: 'sponsors',
    SCRAP_SPONSORS: 'scrap_company_info',
    PAST_PROTFOLIO: 'protfoilo',
    SPONSOR_COMPANY_INFO: 'companyinfo',
    PRO_FORMA_USER_INPUT: 'proforma',
    SHORT_PROFORMA: 'shortproforma',
    DEAL_HIGHLIGHTS_I: 'dealhighlightsp1',
    DEAL_HIGHLIGHTS_II: 'dealhighlightsp2',
    FILES_UPLOAD: 'files',
    LINKS: 'links',
    MARKET_HOUSE: 'marketHouse',
    MARKET_RENT: 'marketRent',
    COMPARABLES: 'comparables',
    ZONING: 'zoning',
    AREA_INFORMATION: 'areaInformation',
    CONSTRUCTION: 'construction',
    PROPERTY_HISTORY: 'propertyHistory',
    RENT_COMPARABLES: 'rentComparables',
    TRANSPORTATION: 'transportation',
    MANAGEMENT_COMPANY: 'managementCompany',
    SELLER_BACKGROUND: 'sellerBackground',
    TAX_RECORDS: 'tax_records',
    PAST_TRANSACTIONS: 'pasttransactions',
    MORTGAGE_RECORDS: 'mortgagerecords',
    GRID_LOCATIONS: 'gridlocations',
    EMPLOYMENT_LOCATIONS: 'emplocatoins',
    EMPLOYMENT_COMPANIES: 'empcompanies',
    SCRAP_STATUS: 'scrap_status',
    FINANCIALS: 'financials',
    RENOVATION_PLAN: 'renocationPlan',
    MIXED_USED_RETAIL: 'mixUseRetail',
    PUBLISH: 'publish',
})

export const SHORT_FLOW_LIST = Object.freeze([
    STEP_ID.DEAL_OUTLINE, //
    STEP_ID.THE_PROPERTY, //
    STEP_ID.MARKET_OVERVIEW, //
    STEP_ID.SPONSORS, //
    STEP_ID.PRO_FORMA_USER_INPUT, //
    STEP_ID.SHORT_PROFORMA,
    STEP_ID.DEAL_HIGHLIGHTS_I,
    STEP_ID.DEAL_HIGHLIGHTS_II, //
    STEP_ID.FILES_UPLOAD, //
    STEP_ID.LINKS, //
    STEP_ID.GRID_LOCATIONS, //
    STEP_ID.EMPLOYMENT_LOCATIONS, //
    STEP_ID.PAST_PROTFOLIO, //
    STEP_ID.PUBLISH,
])

export const COMPARABLES_FLOW_LIST = Object.freeze([
    STEP_ID.DEAL_OUTLINE,
    STEP_ID.COMPARABLES,
    STEP_ID.RENT_COMPARABLES,
    STEP_ID.PUBLISH,
])

export const USER_FLOW = Object.freeze({
    SHORT: 'short',
    FULL: 'full',
    ADMIN: 'admin',
    COMPARABLES_ONLY: 'comps_only',
    LENDER: 'lender',
    LENDER_AND_BORROWER: 'lender_borrower',
})
