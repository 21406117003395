import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { myStorage } from '../../storage'

import { Pricing } from '@generated/graphql'
const cloneDeep = require('lodash.clonedeep')
const remove = require('lodash/remove')

export function* getData(params: { projectId: number }) {
    const DEBUG = false
    const projectId = params.projectId
    const response = yield graphQLRequest(
        gql`
            fragment PricingSizer on PricingSizerColumn {
                loanAmount
                dscRequestedLoanAmount
                capRate
                debtServiceConstrainedLoan
                requiredCapRateAtDSCRLoan
                valueConstrainedLoan
                recentAquisitionLoanLimit
                maxFirstPosLoan
                constraint
                upbOfFirst
                combinedUPB
                underwrittenNCF
                availableForDebtService
                currentDebtService
                availableForSupplementalDebtService
                fixedRateSizing
            }
            fragment PricingScenario on PricingScenarioColumn {
                financeOptions {
                    loanTerm
                    intrestType
                    tier
                    io
                    minDSCR
                    maxLTV
                    amortization
                    prepayment
                    intrestBasis
                    prepaymentAdjusted
                }
                loanPricing {
                    pricingMethod
                    refIndex
                    indexRate
                    investorSpread
                    fannieMaeGuarantyFee
                    lenderServiceFee
                    totalSpread
                    todayEstimateIntrestRate
                    uwFloor
                    actualintrestRate
                    uwLoanConstant
                    actualLoanConstant
                    stressFactor
                    strikeRate
                    capCostFactor
                }
            }
            fragment ExitAnalysisSummary on ExitAnalysisSummaryGraphQL {
                ncf
                refiRate
                capRate
            }
            query ($projectId: Int!) {
                getProjectPricing(projectId: $projectId) {
                    bondsRate {
                        timeStamp
                        years10
                        years7
                        years5
                        days30
                        sofr
                        sofr30
                    }
                    fanniePricing {
                        pricingOptions {
                            id
                            name
                            isCollapsed
                            isSelected
                            isArm
                            fixedOptionReferenceId
                            armOptionsReferenceIds
                            nmLoanAmountManuallyChanged
                            creditFees {
                                loanTerms {
                                    amortization
                                    loanTerm
                                    io
                                    prepayment
                                    tier
                                    supplementalType
                                    exercisingTierDroppingOption
                                    propertyType
                                }
                                pricingFees {
                                    targetedGS
                                    greenFinancingType
                                    rateLockType
                                    investorSpread
                                    fannieMaeGuarantyFee
                                    lenderServiceFee
                                }
                                spreadFees {
                                    investorSpread
                                    fannieMaeGuarantyFee
                                    lenderServiceFee
                                    comment
                                }
                            }
                            sizer {
                                underwritten {
                                    ...PricingSizer
                                }
                                actual {
                                    ...PricingSizer
                                }
                            }
                            scenario {
                                underwritten {
                                    ...PricingScenario
                                }
                                actual {
                                    ...PricingScenario
                                }
                            }
                            capCost {
                                capCost
                                capCostTerm
                                costPerYear
                                loanAmount
                                capCostFactor
                                monthlyEscrow
                            }
                            exitAnalysis {
                                standard {
                                    ...ExitAnalysisSummary
                                }
                                alternative {
                                    ...ExitAnalysisSummary
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            projectId: projectId,
        },
    )

    if (DEBUG) console.log('Model respnse', response)
    // transform server data to more usefull format
    const reduxDataPricing: Pricing = response.data.getProjectPricing
    yield put(ReduxHelper.setInAction(['lender', 'pricing'], reduxDataPricing || null))
    yield put(ReduxHelper.setInAction(['lender', 'isPricingLoaded'], reduxDataPricing ? true : false))

    return response?.data
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'pricing'], null))
}

export function* resetIsPricingLoaded() {
    yield put(ReduxHelper.setInAction(['lender', 'isPricingLoaded'], false))
}

export function* setPricing(params: { projectId: number; pricing: any }) {
    //console.log('setPricing', params)
    if (params && params.pricing && params.pricing.fanniePricing?.pricingOptions) {
        const data = cloneDeep(params.pricing)
        const optionsToRemove = []

        for (const option of data.fanniePricing.pricingOptions) {
            if (option.isDefaultFixedRate) {
                if (
                    !data.fanniePricing.pricingOptions.find(
                        (item) => item.isArm && item.fixedOptionReferenceId === option.id,
                    )
                ) {
                    optionsToRemove.push(option.id)
                }
            }

            if (option.creditFees.spreadFees.investorSpread && option.creditFees.spreadFees.investorSpread != '') {
                option.creditFees.spreadFees.investorSpread = parseFloat(option.creditFees.spreadFees.investorSpread)
            } else {
                option.creditFees.spreadFees.investorSpread = 0
            }

            if (option.creditFees.spreadFees.lenderServiceFee && option.creditFees.spreadFees.lenderServiceFee != '') {
                option.creditFees.spreadFees.lenderServiceFee = parseFloat(
                    option.creditFees.spreadFees.lenderServiceFee,
                )
            } else {
                option.creditFees.spreadFees.lenderServiceFee = 0
            }

            if (
                option.creditFees.spreadFees.fannieMaeGuarantyFee &&
                option.creditFees.spreadFees.fannieMaeGuarantyFee != ''
            ) {
                option.creditFees.spreadFees.fannieMaeGuarantyFee = parseFloat(
                    option.creditFees.spreadFees.fannieMaeGuarantyFee,
                )
            } else {
                option.creditFees.spreadFees.fannieMaeGuarantyFee = 0
            }

            if (option.isDefaultFixedRate) {
                delete option.isDefaultFixedRate
            }
        }

        delete data.computed
        delete data.armType
        delete data.boundsRate

        optionsToRemove.forEach((item) => remove(data.fanniePricing.pricingOptions, (elem) => elem.id === item))

        const response = yield graphQLRequest(
            gql`
                mutation ($projectId: Int!, $data: PricingInput!) {
                    setProjectGPricing(projectId: $projectId, data: $data)
                }
            `,
            {
                projectId: params.projectId,
                data: data,
            },
        )

        return response?.data?.setProjectGPricing || false
    }
    return true
}
