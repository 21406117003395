import { graphQLRequest } from '../../../store/graphQLClient'
import gql from 'graphql-tag'
import { QuickAnalysisBorrowerInputGraphQl, QuickAnalysisInputGraphQl } from '@generated/graphql'
export function* loadQABorrower(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                quickAnalysisBorrower(projectId: $projectId) {
                    income {
                        grossPotentialRent {
                            abs
                            type
                        }
                        vacancy {
                            abs
                            percentage
                            type
                        }
                        lossToLease {
                            abs
                            percentage
                            type
                        }
                        badDebt {
                            abs
                            percentage
                            type
                        }
                        concession {
                            abs
                            percentage
                            type
                        }
                    }
                    expense {
                        taxes {
                            abs
                            percentage
                            type
                        }
                        otherExpenses {
                            abs
                            percentage
                            type
                        }
                    }
                    purchasePrice
                    assessedValue
                    assessedValueFromPurchasePrice
                    assessmentRatio
                    loanAmount
                    loanRate
                    capRate
                    property {
                        address
                        yearBuilt
                        yearRenovated
                        buildingClass
                        numberOfUnits
                        totalRentableSF
                    }
                    t12 {
                        rentalIncome {
                            id
                            aprDetails
                            t12
                        }
                        rentalLoss {
                            id
                            aprDetails
                            t12
                        }
                        operatingExpenses {
                            id
                            aprDetails
                            t12
                        }
                        noi {
                            id
                            aprDetails
                            t12
                        }
                        totals {
                            id
                            aprDetails
                            t12
                        }
                    }
                    valuation
                    assessedValue
                    capRate
                }
            }
        `,
        {
            projectId: p.projectId,
        },
    )
    return response.data.quickAnalysisBorrower
}
export function* loadQADemographicData(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                quickAnalysisDemographics(projectId: $projectId) {
                    medianHouseHoldIncomeUS
                    medianHouseHoldIncomeZip
                    medianHouseHoldIncomeCity
                    medianHousePriceZip
                    elementarySchoolRatings
                    assignedElementarySchoolName
                    population2010
                    population2019
                    googleRating
                }
            }
        `,
        {
            projectId: p.projectId,
        },
    )
    return response.data.quickAnalysisDemographics
}
export function* performQABorrower(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                performQuickAnalysisBorrower(projectId: $projectId)
            }
        `,
        p,
    )
    return response.data.performQuickAnalysisBorrower
}
export function* saveQABorrower(p: { projectId: number; data: QuickAnalysisBorrowerInputGraphQl }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: QuickAnalysisBorrowerInputGraphQL!) {
                quickAnalysisBorrowerSave(projectId: $projectId, data: $data)
            }
        `,
        p,
    )
    return response.data.quickAnalysisBorrowerSave
}
