import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { CashFlow as CashFlowRedux } from '../../types/OriginationModelRedux'

import { OriginationModelReworked } from '@generated/graphql'
import { select } from 'redux-saga/effects'
import { regenerateCashFlow } from 'origination-model'
import { myStorage } from '../../storage'
import { getUnitMix } from '../lenderProjects'

const cloneDeep = require('lodash.clonedeep')

export function* getData(params: { projectId: number }) {
    const DEBUG = false
    if (DEBUG) console.log('Model getData from Server')
    const { projectId } = params
    const response = yield graphQLRequest(
        gql`
            fragment CFRow on CashFlowRowGraphQL {
                id
                title
                apr
                originalApr
                years
                aprDetails
                months
                comment
                t12
                t12Total
                adjustedT12
                label
                parent
                originalCFGroup
            }
            fragment adjRow on T12AdjustedRowGraphQL {
                id
                title
                label
                value
                comment
                group
                defaultValue
            }
            fragment costList on CostItemGraphQL {
                type
                abs
                percentage
                title
                monthStart
                monthEnd
            }
            fragment loanDrawList on LoanDrawItemGraphQL {
                abs
                title
                monthStart
            }
            query ($projectId: String!) {
                cashFlow(projectId: $projectId) {
                    totalOperatingExpenses {
                        ...CFRow
                    }
                    effectiveGrossRent {
                        ...CFRow
                    }
                    effectiveGrossIncome {
                        ...CFRow
                    }
                    totalPotentialGrossRent {
                        ...CFRow
                    }
                    noi {
                        ...CFRow
                    }
                    cashFlowAfterDebtService {
                        ...CFRow
                    }
                    rentalIncome {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    rentalLoss {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    operatingExpenses {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    fcfBeforeDebt {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    removedLineItems {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    otherIncome {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    retailRentalIncome {
                        ...CFRow
                    }
                    retailRentalLoss {
                        ...CFRow
                    }
                    retailOperatingExpenses {
                        ...CFRow
                        subRows {
                            ...CFRow
                        }
                    }
                    retailOtherIncome {
                        ...CFRow
                    }
                    other {
                        ...CFRow
                    }
                    totals {
                        ...CFRow
                    }
                    t12FirstMonth
                    useLabelsAsTitle
                }
                originationModel(projectId: $projectId) {
                    cenusTract
                    t12Annualized
                    numberOfUnits
                    totalUnitsFromDataProvider
                    totalSQFTFromDataProvider
                    totalSF
                    hasRetail
                    numberOfRetailUnits
                    totalRetailSF
                    yearBuilt
                    yearRenovated
                    useUnitMixForGPR
                    useRetailUnitMixForGPR
                    useNCFnotNOI
                    allowFannieAPricing
                    marketCapRate
                    t12CollectionAnalysisComments
                    t12AnalysisComments
                    t12smoothStr
                    cashFlowFieldsToIgnore
                    t12AdsutmentDefaults {
                        defaults {
                            id
                            subid
                            value
                            comment
                        }
                    }
                    rentRollTab {
                        comments
                        fileDate
                        rentRollSettings {
                            allowFannieAffordability
                            showComments
                            threshold
                            trendType
                            floorPlanSelected
                            isAffordabilityDeal
                        }
                    }
                    sources {
                        seniorDebt
                        seniorDebtPercent
                        seniorDebtType
                        mezzanine
                        mezzaninePercent
                        mezzanineType
                        totalEquity
                        totalEquityPercent
                        totalEquityType
                    }
                    uses {
                        purchasePrice
                        transactionCosts
                        transactionCostsPercent
                        transactionCostsType
                        lenderFee
                        lenderFeePercent
                        lenderFeeType
                        renovationProgram
                        renovationProgramPercent
                        renovationProgramType
                        preFunded
                        preFundedPercent
                        preFundedType
                        hardCost
                        hardCostPercent
                        hardCostType
                        softCost
                        softCostPercent
                        softCostType
                        financingCost
                        financingCostPercent
                        financingCostType
                        other {
                            title
                            id
                            percentage
                            abs
                            type
                        }
                    }
                    exitAssumption {
                        startAnalysisDate
                        saleYearAnalysisPeriod
                        saleDate
                        saleCost
                        exitNoi
                        exitCapRate
                        capRateSpread
                    }
                    returnMetrics {
                        netProfit
                        dealEquityMultiple
                        cocYear1
                        cocAvg
                        roeYear1
                        roeAvg
                    }

                    underwritingAssumption {
                        organicRentGrowth
                        otherIncomeGrowth
                        mtm
                        lossToLease
                        vacancy
                        badDebt
                        concession
                        replacementReserves
                        expenseGrown
                        assetManagementFee
                        assetManagementFeeGP
                    }
                    underwritingAssumptionRetail {
                        otherIncomeGrowth
                        vacancy
                        expenseGrown
                        capitalReserves
                    }
                    debtAssumptionSenior {
                        rate
                        rateType
                        loanAmount
                        loanTerm
                        amortizationTerm
                        ioPeriod
                        bc10year
                    }
                    debtAssumptionMez {
                        rate
                        rateType
                        loanAmount
                        loanTerm
                        amortizationTerm
                        ioPeriod
                        bc10year
                    }
                    saleAssumption {
                        netSalesProceeds
                        salesPrice
                        salesPriceTotal
                        sellingCost
                        sellingCostTotal
                        seniorLoanRepayment
                    }
                    propertyTaxAssumption {
                        taxGrowthRates
                        mileageRate
                        percentValueAssessed
                        reassessedUponSale
                        currentAssessedValue
                        fixedChargeAssessments
                        currentValuation
                        landMarketValue
                        improvementMarketValue
                        totalMarketValue
                        assessedLandValue
                        assessedImprovementValue
                        totalAssessedValue
                        taxAmount
                        totalTaxDue
                        manualAdjust
                        totalTaxDueAdjusted
                        comments
                        taxAbatementPeriod
                        abatedTaxBill
                        fullTaxBill
                        taxAbatementGrowthRate
                        npvRate
                    }
                    adjustmentsSettings {
                        incomePctOption
                        expensesPctOption
                        incomeValueType
                        expenseValueType
                        trendType
                        threshold
                        showComments
                        showPCT
                        replacementReservesApplyAll
                        mgmtFeeApplyAll
                        columnsSelected
                        useCollectionsAsNRI
                        useCollectionsAsNRISelectedValue
                        useCollectionsAsNRIType
                        useCollectionsAsNRIEconomicValue
                        forceCreateEconomicValue
                        forceCreateEconomicValueManual
                        collectionsSettings {
                            expandT12Internal
                            showT12WithSmooth
                        }
                        pnlStructureRules {
                            addTotalLines {
                                idsToSum
                                placeAfterId
                                section
                                title
                            }
                        }
                    }
                    t12Adjustments {
                        ...adjRow
                        subRows {
                            ...adjRow
                        }
                    }
                    t12AdjustmentsIncome {
                        ...adjRow
                        subRows {
                            ...adjRow
                        }
                    }
                    t12AdjustmentsRetail {
                        ...adjRow
                        subRows {
                            ...adjRow
                        }
                    }
                    t12AdjustmentsIncomeRetail {
                        ...adjRow
                        subRows {
                            ...adjRow
                        }
                    }
                    unitMixRetail {
                        id
                        unitType
                        floorPlan
                        tenant
                        unitsize
                        rentprice
                        originalrentprice
                        stabilizedRentPrice
                        leaseStart
                        leaseEnd
                        leaseTerm
                        rentStart
                        status
                        recordType
                        tenantFromRR
                        overhang
                    }
                    renovationProgram {
                        enabled
                        startMonth
                        endMonth
                        renovationDuration
                        costPerUnit
                        maxUnitsPerMonth
                        isDetailed
                        quickmode_avgPremium
                        quickmode_totalRenovatedUnits
                    }
                    breakdownCosts {
                        disabled
                        transactionCostsList {
                            ...costList
                        }
                        loanCostsList {
                            ...costList
                        }
                    }
                    renovationBudgetCosts {
                        disabled
                        exteriorList {
                            ...costList
                        }
                        interiorList {
                            ...costList
                        }
                    }
                    SeniorBreakdown {
                        disabled
                        list {
                            ...costList
                        }
                    }
                    MezzanineBreakdown {
                        disabled
                        list {
                            ...costList
                        }
                    }
                    renovationLoanDraws {
                        disabled
                        list {
                            ...loanDrawList
                        }
                    }
                    newDevelopmentBudget {
                        enabled
                    }
                    newDevBudgetCosts {
                        disabled

                        softCostsList {
                            ...costList
                        }
                        hardCostsList {
                            ...costList
                        }
                        landCostsList {
                            ...costList
                        }
                        financingCostsList {
                            ...costList
                        }
                    }
                    operatingAssumptionsSettings {
                        usesType
                        t12AnalysisCollectionsSettings {
                            showSmoothAanalysis
                            showComments
                            trendType
                            threshold
                            columnsSelected
                        }
                        t12AnalysisSettings {
                            showComments
                            showMarking
                            trendType
                            threshold
                            average
                        }
                        taxSettings {
                            showComments
                            showTaxAbatementScenario
                            showLeadingQuestions
                            columnsSelected
                        }
                    }
                    refinance {
                        ltv
                        noi
                        dscr
                        newfcf
                        ioPeriod
                        loanTerm
                        exitCapRate
                        loanRepayment
                        refinanceType
                        ioPeriodMonths
                        loanTermMonths
                        amortizationTerm
                        lenderFeePercent
                        existingLoanPenaltyPercentage
                        newPropertyValue
                        refinanceLoanCost
                        refinanceLoanAmount
                        refinanceIntrestRate
                        amortizationTermMonths
                        newAmortizationPayment
                        remainingCashflowtoBorrower
                    }
                    promote {
                        showOnOM
                        equity_share {
                            title
                            percentage
                        }
                        hurdles {
                            title
                            ratios
                            irr
                        }
                    }
                    fannieAffordabilityEstimatorValues {
                        pctUnits
                        qualify120AMI
                        qualify100AMI
                        qualify80AMI
                        qualify60AMI
                        qualify50AMI
                        exclusion
                    }
                    fanniePricing {
                        priorLienSelectedOptionId
                        valuationComparison {
                            assumedValue
                            capRate
                            comment
                            selected
                        }
                        loanInfo {
                            type
                            dateOfPricing
                        }
                        supplemental {
                            name
                            priorLien {
                                nmLoanId
                                fnmaId
                                upbOriginalLoanAmount
                                _targetLoanClosing
                                estimatedUpbAtSupClosing
                                upbAtmaturity
                                intrestRate
                                intrestBasis
                                closed
                                maturityDate
                                amortizationYears
                                ioYears
                                minDSCR
                                actualDSCR
                                actualDSCRDate
                                anualAmortization
                                testApply
                            }
                            valueChanges {
                                appraised
                                underwritten
                                appraisedCapRate
                            }
                            terms {
                                firstPayment
                                maturityDate
                                termMonths
                                termYears
                                ymMonths
                                ymYears
                                actualDSCR
                                interestType
                            }
                            _terms {
                                firstPayment
                                maturityDate
                                termMonths
                                termYears
                                ymMonths
                                ymYears
                                actualDSCR
                                interestType
                            }
                        }
                    }
                    fannieExitScenarioRefinance {
                        selectedOptionId
                        refinanceAssumptions {
                            assumptions {
                                amortization
                                dscr
                                ltv
                            }
                            rateAssumptions {
                                benchmarkExitRefiRate
                                originalRefiRate
                            }
                        }
                    }
                    cfTrends {
                        trendsName
                        trends {
                            id
                            trend
                        }
                        gpr
                        tax {
                            override
                            val
                        }
                    }
                }
            }
        `,
        {
            projectId: projectId.toString(),
        },
    )
    if (DEBUG) console.log('Model respnse', response)
    // transform server data to more usefull format
    const reduxData: OriginationModelReworked = response.data.originationModel
    if (reduxData.allowFannieAPricing && !reduxData.fanniePricing) reduxData.fanniePricing = {}

    if (!reduxData.fannieExitScenarioRefinance) {
        reduxData.fannieExitScenarioRefinance = {}
    }
    if (!reduxData.fannieExitScenarioRefinance.refinanceAssumptions) {
        reduxData.fannieExitScenarioRefinance.refinanceAssumptions = []
    }
    if (!reduxData.fannieExitScenarioRefinance.refinanceAssumptions[0]) {
        reduxData.fannieExitScenarioRefinance.refinanceAssumptions[0] = {}
    }
    if (!reduxData.fannieExitScenarioRefinance.refinanceAssumptions[1]) {
        reduxData.fannieExitScenarioRefinance.refinanceAssumptions[1] = {}
    }
    for (let item of reduxData.fannieExitScenarioRefinance.refinanceAssumptions) {
        if (!item.assumptions) {
            item.assumptions = {}
        }
        if (!item.rateAssumptions) {
            item.rateAssumptions = {}
        }
    }

    const unitMix = yield getUnitMix(params)
    const cashFlow = regenerateCashFlow({
        cashFlow: response.data.cashFlow,
        //@ts-ignore
        model: reduxData,
        unitMix: unitMix,
    })
    if (cashFlow?.noi?.aprDetails?.length) {
        ReduxHelper.setIn(['operatingAssumptions', 'historicalTab', 'income', 'chartPeriodSelected'], {
            value: `t${cashFlow.noi.aprDetails.length.toString()}`,
            type: 'month',
        })
    }
    Object.keys(cashFlow).forEach((item1, ind) => {
        if (cashFlow[item1]?.comment) {
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], true)
        } else if (!cashFlow[item1]?.comment) {
            !cashFlow[item1]?.hasOwnProperty('id') &&
                cashFlow[item1] &&
                Object.keys(cashFlow[item1]).forEach((item2, ind) => {
                    if (cashFlow[item1][item2].comment) {
                        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], true)
                    } else if (cashFlow[item1][item2].subRows) {
                        Object.keys(cashFlow[item1][item2].subRows).forEach((subRow, ind) => {
                            if (cashFlow[item1][item2]['subRows'][subRow].comment) {
                                ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], true)
                            }
                        })
                    }
                })
        }
    })

    const reduxOut = []
    cashFlow.operatingExpenses.map((itm, ind) => {
        reduxOut.push(itm.title.replace('Total ', ''))
    })
    // ReduxHelper.setIn(['financials', 'historicalTab', 'expenses', 'expensesTrends'], reduxOut)

    //@ts-ignore
    reduxData.cashFlow = cashFlow
    //@ts-ignore
    yield put(ReduxHelper.setInAction(['lender', 'originationModel'], reduxData || null))
    yield put(ReduxHelper.setInAction(['lender', 'originationModelDefault'], cloneDeep(reduxData) || null))
    // hide T12 in lender cashflow if orinalApr of totals is zero, it means that no t12 was uploaded
    if (cashFlow?.noi?.originalApr > 0 || cashFlow?.totalOperatingExpenses?.originalApr > 0) {
        yield put(
            ReduxHelper.updateInAction(
                ['lenderDashboard', 'lenderCashFlowTab', 'columnsSelected'],
                (tabs: string[]) => {
                    if (!tabs) return tabs
                    const ind = tabs.indexOf('underwriting')
                    if (!tabs.includes('t12')) {
                        tabs.splice(ind, 0, 't12')
                        // insert before underwriting
                        return [...tabs]
                    } else return tabs
                },
            ),
        )
    }
    if (response.data.originationModel?.sources?.mezzanine > 0) {
        myStorage.setItem('hasMez_' + projectId, { val: true })
    } else {
        myStorage.setItem('hasMez_' + projectId, { val: false })
    }

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'columnsSelected'],
            reduxData?.adjustmentsSettings?.columnsSelected || ['underwriting', 't12'],
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'showComments'],
            reduxData?.adjustmentsSettings?.showComments || false,
        ),
    )
    if (reduxData?.adjustmentsSettings?.showPCT != null) {
        yield put(
            ReduxHelper.setInAction(
                ['operatingAssumptions', 'adjustments', 'showPCT'],
                reduxData?.adjustmentsSettings?.showPCT || false,
            ),
        )
    }
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'useCollectionsAsNRI'],
            reduxData?.adjustmentsSettings?.useCollectionsAsNRI || false,
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'useCollectionsAsNRISelectedValue'],
            reduxData?.adjustmentsSettings?.useCollectionsAsNRISelectedValue || '',
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'useCollectionsAsNRIType'],
            reduxData?.adjustmentsSettings?.useCollectionsAsNRIType || 'manual',
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'useCollectionsAsNRIEconomicValue'],
            reduxData?.adjustmentsSettings?.useCollectionsAsNRIEconomicValue,
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'collections', 'expandT12Internal'],
            reduxData?.adjustmentsSettings?.collectionsSettings?.expandT12Internal || false,
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'adjustments', 'collections', 'showT12WithSmooth'],
            reduxData?.adjustmentsSettings?.collectionsSettings?.showT12WithSmooth || false,
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 'collection', 'columnsSelected'],
            reduxData?.operatingAssumptionsSettings?.t12AnalysisCollectionsSettings?.columnsSelected || ['t12'],
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 'collection', 'showSmoothAanalysis'],
            reduxData?.operatingAssumptionsSettings?.t12AnalysisCollectionsSettings?.showSmoothAanalysis || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 'collection', 'showComments'],
            reduxData?.operatingAssumptionsSettings?.t12AnalysisCollectionsSettings?.showComments || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 't12analysis', 'showComments'],
            reduxData?.operatingAssumptionsSettings?.t12AnalysisSettings?.showComments || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 't12analysis', 'showMarking'],
            reduxData?.operatingAssumptionsSettings?.t12AnalysisSettings?.showMarking || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 't12analysis', 'comments'],
            reduxData?.t12AnalysisComments ? JSON.parse(reduxData?.t12AnalysisComments) : {},
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'historicalTab', 'collection', 'comments'],
            reduxData?.t12CollectionAnalysisComments ? JSON.parse(reduxData?.t12CollectionAnalysisComments) : {},
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'taxTab', 'settings', 'columnsSelected'],
            reduxData?.operatingAssumptionsSettings?.taxSettings?.columnsSelected || [],
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'taxTab', 'settings', 'showComments'],
            reduxData?.operatingAssumptionsSettings?.taxSettings?.showComments || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'taxTab', 'settings', 'showTaxAbatementScenario'],
            reduxData?.operatingAssumptionsSettings?.taxSettings?.showTaxAbatementScenario || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'taxTab', 'settings', 'showLeadingQuestions'],
            reduxData?.operatingAssumptionsSettings?.taxSettings?.showLeadingQuestions || false,
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'taxTab', 'comments', 'text'],
            reduxData?.propertyTaxAssumption?.comments || '',
        ),
    )

    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'rentRollTab', 'rentRollSettings', 'showComments'],
            reduxData?.rentRollTab?.rentRollSettings?.showComments || false,
        ),
    )
    yield put(
        ReduxHelper.setInAction(
            ['operatingAssumptions', 'rentRollTab', 'comments'],
            reduxData?.rentRollTab?.comments ? JSON.parse(reduxData?.rentRollTab?.comments) : {},
        ),
    )

    if (reduxData?.t12smoothStr && reduxData?.t12smoothStr != null && reduxData?.t12smoothStr != '') {
        // @ts-ignore
        reduxData.t12smooth = JSON.parse(reduxData?.t12smoothStr)
    }
    return response?.data
}

export function* saveCashFlow(params: { projectId: number; cashFlow: CashFlowRedux }) {
    for (const operatingExpences of params.cashFlow.operatingExpenses) {
        // @ts-ignore
        delete operatingExpences['avg']
        delete operatingExpences['total']
    }
    delete params.cashFlow['readOnly']
    delete params.cashFlow['totalEquity']
    delete params.cashFlow['seniorLoans']
    delete params.cashFlow['mezLoans']
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $cashFlow: CashFlowInputGraphQL!) {
                setCashFlow(projectId: $projectId, cashFlow: $cashFlow)
            }
        `,
        params,
    )
    return response?.data?.setCashFlow
}

export function* setOriginationModel(params: { projectId: number; originationModel: any }) {
    const refinanceAssumptions = params.originationModel.fannieExitScenarioRefinance.refinanceAssumptions
    for (let item of refinanceAssumptions) {
        delete item.balanceNewLoan
        delete item.balanceExistingLoan
        delete item.balanceCombined
        delete item.debtServiceNewLoan
        delete item.debtServiceExistingLoan
        delete item.totalDebtService
        delete item.combinedDscr
        delete item.loanDetails
        delete item.ltvAtCapRate
        delete item.ncf
        delete item.newLoanDSCR
        delete item.upbDscrAtRefiRate
        delete item.valueAtCapRate
        delete item.rateAssumptions.calculatedExitRefiRate
        delete item.rateAssumptions.calculatedTerminalCapRate
    }
    delete params.originationModel.fannieExitScenarioRefinance.selectedPage

    try {
        if (params.originationModel.hasOwnProperty('t12smooth')) {
            if (params.originationModel.t12smooth && params.originationModel.t12smooth != '')
                params.originationModel.t12smoothStr = JSON.stringify(params.originationModel.t12smooth)

            delete params.originationModel.t12smooth
        }
    } catch (e) {}
    try {
        const fannieAffordabilityEstimatorValues = params?.originationModel?.fannieAffordabilityEstimatorValues
        if (fannieAffordabilityEstimatorValues) {
            Object.keys(fannieAffordabilityEstimatorValues).forEach((key) => {
                if (typeof fannieAffordabilityEstimatorValues[key] == 'string') {
                    fannieAffordabilityEstimatorValues[key] =
                        parseFloat(fannieAffordabilityEstimatorValues[key].replace('%', '')) / 100
                }
            })
        }
    } catch (e) {}
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $originationModel: OriginationModelReworkedInput!) {
                setOriginationModel(projectId: $projectId, originationModel: $originationModel)
            }
        `,
        {
            ...params,
        },
    )

    return response?.data?.setOriginationModel || false
}
export function* setOriginationModelAndRecalcCashFlow(params: { projectId: number; originationModel: any }) {
    const cloneParams = cloneDeep(params)

    if (cloneParams?.originationModel?.t12Adjustments) {
        for (const item of cloneParams?.originationModel?.t12Adjustments) {
            delete item['total']
        }
    }

    if (cloneParams?.originationModel?.t12AdjustmentsIncome) {
        for (const item of cloneParams?.originationModel?.t12AdjustmentsIncome) {
            delete item['total']
        }
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $originationModel: OriginationModelReworkedInput!) {
                setOriginationModelRecalcCashFlow(projectId: $projectId, originationModel: $originationModel)
            }
        `,
        {
            ...cloneParams,
        },
    )

    return response?.data?.setOriginationModel || false
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'originationModel'], null))
}

export function* loadQuoteData(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                quotes(projectId: $projectId) {
                    id
                    default
                    name
                    rate
                    mezRate
                    minimumDSCR
                    mezMinimumDSCR
                    maximumLTC
                    maximumLTV
                    mezMaximumLTC
                    mezMaximumLTV
                    maximumLoanAmount
                    mezMaximumLoanAmount
                    index
                    estimatedAllInRate
                    repaymentType
                    loanPeriod
                    amortization
                    interestOnly
                    mezLoanPeriod
                    mezAmortization
                    mezInterestOnly
                    estimatedNOI
                    preliminaryValue
                    interestAccuralBase
                    recourse
                    isLoanAssumable
                    supplementalFinancingAvailable
                    replacementReserveAndTIEscrow
                    vacancy
                    refinanceLTV
                    exitCapRate
                    valuationCapRate
                    borrowerNOI
                    createdAt
                    updatedAt
                    useSeniorMaxLTCorV
                    useMezMaxLTCorV
                }
            }
        `,
        {
            projectId: +params.projectId,
        },
    )
    yield put(ReduxHelper.setInAction(['lender', 'quoteData'], response.data.quotes))

    return response.data.quotes
}

export function* saveQuotes(params: { projectId: number; data: any[] }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: [QuoteInputGraphQL!]!) {
                saveQuotes(projectId: $projectId, data: $data)
            }
        `,
        {
            ...params,
        },
    )

    return response?.data?.setOriginationModel || false
}
export function* loadProforma(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                proforma(projectId: $projectId) {
                    label
                    totalOperatingExpenses {
                        id
                        title
                        years
                    }
                    effectiveGrossRent {
                        id
                        title
                        years
                    }
                    effectiveGrossIncome {
                        id
                        title
                        years
                    }
                    totalPotentialGrossRent {
                        id
                        title
                        years
                    }
                    noi {
                        id
                        title
                        years
                    }
                    cashFlowAfterDebtService {
                        id
                        title
                        years
                    }
                    rentalIncome {
                        id
                        title
                        years
                        subRows {
                            id
                            title
                            years
                        }
                    }
                    rentalLoss {
                        id
                        title
                        years
                        subRows {
                            id
                            title
                            years
                        }
                    }
                    operatingExpenses {
                        id
                        title
                        years
                        subRows {
                            id
                            title
                            years
                        }
                    }
                    otherIncome {
                        id
                        title
                        years
                        subRows {
                            id
                            title
                            years
                        }
                    }
                    other {
                        id
                        title
                        years
                        subRows {
                            id
                            title
                            years
                        }
                    }
                }
            }
        `,
        params,
    )
    yield put(ReduxHelper.setInAction(['lender', 'proforma'], response.data.proforma))
    if (response.data.proforma) {
        // add proforma to tabs
        yield put(
            ReduxHelper.updateInAction(
                ['lenderDashboard', 'lenderCashFlowTab', 'columnsSelected'],
                (tabs: string[]) => {
                    if (response.data.proforma.length > 0) {
                        const proforma = response.data.proforma[0]
                        const label =
                            proforma.label && proforma.label != ''
                                ? 'proforma' + proforma.label + (proforma.noi.years.length > 1 ? '1' : '')
                                : 'proforma1'
                        if (tabs.includes(label)) return tabs
                        const ind = tabs.indexOf('underwriting')
                        // insert before underwriting
                        tabs.splice(ind, 0, label)
                        return [...tabs]
                    } else return [...tabs]
                },
            ),
        )
    }
    return response.data.proforma
}
export function* loadT12History(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            fragment Fields on CashFlowRowGraphQL {
                id
                title
                years
                apr
                aprDetails
                t12
                t12Total
                label
                parent
            }

            query ($projectId: Int!) {
                t12History(projectId: $projectId) {
                    year
                    data {
                        totalOperatingExpenses {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        effectiveGrossRent {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        effectiveGrossIncome {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        totalPotentialGrossRent {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        noi {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        cashFlowAfterDebtService {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        rentalIncome {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        rentalLoss {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        operatingExpenses {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        otherIncome {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                        other {
                            ...Fields
                            subRows {
                                ...Fields
                            }
                        }
                    }
                }
            }
        `,
        params,
    )
    yield put(ReduxHelper.setInAction(['lender', 't12History'], response.data.t12History))
    return response.data.t12History
}
export function* saveCashFlowComments(p: { projectId: number }) {
    const cashFlow = yield select((state: ReduxStoreState) => state.lender.originationModel.cashFlow)
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $cashFlow: CashFlowInputGraphQL!) {
                saveCashFlowComments(projectId: $projectId, cashFlow: $cashFlow)
            }
        `,
        { projectId: p.projectId, cashFlow },
    )
    return response?.data?.saveCashFlowComments
}
export function* reloadComparables(p: { projectId: number; buildingClass: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $buildingClass: String!) {
                quickAnalysisReloadComparables(projectId: $projectId, buildingClass: $buildingClass)
            }
        `,
        { projectId: p.projectId, buildingClass: p.buildingClass },
    )
    return response?.data?.quickAnalysisReloadComparables
}
