import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { PastTransaction } from '../../types/fullproject'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import moment from 'moment'
// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.PAST_TRANSACTIONS

// eslint-disable-next-line @typescript-eslint/naming-convention
type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        const data = response?.data?.project?.transactions

        if (data.length === 0) {
            data.push({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                transactions__date: '',
                buyer: '',
                seller: '',
                saleprice: '',
            })
        }
        yield put(ReduxHelper.updateInAction(['fullProject', 'pastTransactions', 'data', 'transactions'], () => data))
        yield put(
            ReduxHelper.updateInAction(
                ['fullProject', 'pastTransactions', 'data', 'pastTransactionsChartVisible'],
                () => {
                    return response?.data?.project?.pastTransactionsChartVisible == '1' ? ['1'] : []
                },
            ),
        )

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'pastTransactions', 'data', 'pastTransactionsChartType'], () => {
                return response?.data?.project?.pastTransactionsChartType || 'linear'
            }),
        )

        return response.data
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type setNewTransactionsParams = {
    transactions: Array<PastTransaction>
}

export function* setNewTransactions(params: setNewTransactionsParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'pastTransactions', 'data', 'transactions'], () => {
            return params.transactions
        }),
    )
}

export function* addNewTransaction() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'pastTransactions', 'data', 'transactions'], (transactions) => {
            return [
                ...transactions,
                {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    transactions__date: '',
                    buyer: '',
                    seller: '',
                    saleprice: '',
                },
            ]
        }),
    )
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.pastTransactions.form
    const formData = yield select(token)

    const status = calculateProjectStepStatus(formData, {
        transactions: 'array',
    })

    formData.status = status
    formData.pastTransactionsChartVisible =
        formData.pastTransactionsChartVisible.length > 0 && formData.pastTransactionsChartVisible[0] == '1' ? '1' : '0'

    const response = yield getAxios().post(API_URL, formData)
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'pastTransactions', 'data', 'transactions'],
            () => formData.transactions,
        ),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.PAST_TRANSACTIONS], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'pastTransactions', 'form'], () => params))
}
export function* scrapTransactions(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                scrapPastTransactions(projectId: $projectId) {
                    id
                    buyer
                    seller
                    transactionsDate
                    saleprice
                }
            }
        `,
        { projectId },
    )
    const out = []
    for (const item of response.data.scrapPastTransactions) {
        const record = {
            id: item.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transactions__date: moment(item.transactionsDate).format('YYYY-MM-DD'),
            buyer: item.buyer,
            seller: item.seller,
            saleprice: item.saleprice,
        }
        out.push(record)
    }
    if (out.length == 0) {
        out.push({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transactions__date: '',
            buyer: '',
            seller: '',
            saleprice: '',
        })
    }
    yield put(ReduxHelper.setInAction(['fullProject', 'pastTransactions', 'data', 'transactions'], out))
}
