import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { EmploymentCompany } from '../../types/fullproject'
import { calculateProjectStepStatus, objectToFormData } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.EMPLOYMENT_COMPANIES

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        let data = response?.data?.project
        if (data?.employmentCompanies?.length === 0) {
            data?.employmentCompanies.push({
                id: '',
                name: '',
                url: '',
                imgname: '',
                img: '',
            })
        }

        yield put(ReduxHelper.updateInAction(['fullProject', 'employmentCompanies', 'data'], () => data))
        return response.data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'employmentCompanies', 'form'], () => params))
}

export function* setEmploymentCompanies(params: Array<EmploymentCompany>) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'employmentCompanies', 'data', 'employmentCompanies'], () => params),
    )
}

export function* addEmploymentCompany() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'employmentCompanies', 'data', 'employmentCompanies'],
            (units: Array<EmploymentCompany>) => {
                return [
                    ...units,
                    {
                        id: '',
                        name: '',
                        url: '',
                        imgname: '',
                        img: '',
                    },
                ]
            },
        ),
    )
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.employmentCompanies.form
    const data = yield select(token)

    let status = calculateProjectStepStatus(data, {
        empcompanies: 'array',
    })
    data.status = status

    let preparedData = {
        propertyDetails: '',
    }

    let filesCount = 0
    for (let i in data.empcompanies) {
        let fileField = data?.empcompanies?.[i]?.empcimg
        if (typeof fileField?.name == 'string') {
            preparedData[`empcimg${filesCount}`] = fileField
            data.empcompanies[i].empcimg = `empcimg${filesCount}`
            filesCount++
        }
    }
    preparedData.propertyDetails = JSON.stringify(data)
    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.EMPLOYMENT_COMPANIES], () => status))
    return response.data
}
