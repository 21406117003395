import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { MortgageRecord } from '../../types/fullproject'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.MORTGAGE_RECORDS

// eslint-disable-next-line @typescript-eslint/naming-convention
type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        const data = response?.data?.project?.mortgagerecords

        if (data?.length === 0) {
            data.push({
                start_date: '',
                maturity_date: '',
                lender: '',
                amount: '',
            })
        }

        yield put(ReduxHelper.updateInAction(['fullProject', 'mortgageRecords', 'data', 'mortgagerecords'], () => data))

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'mortgageRecords', 'data', 'mortgageRecordsChartType'], () => {
                return response?.data?.project?.mortgageRecordsChartType || 'linear'
            }),
        )
        yield put(
            ReduxHelper.updateInAction(
                ['fullProject', 'mortgageRecords', 'data', 'mortgageRecordsChartVisible'],
                () => {
                    return response?.data?.project?.mortgageRecordsChartVisible == '1' ? ['1'] : []
                },
            ),
        )
        return response.data
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type setNewMortgageRecordsParams = {
    mortgagerecords: Array<MortgageRecord>
}

export function* setNewMortgageRecords(params: setNewMortgageRecordsParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'mortgageRecords', 'data', 'mortgagerecords'], () => {
            return params.mortgagerecords
        }),
    )
}

export function* addNewMortgageRecord() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'mortgageRecords', 'data', 'mortgagerecords'], (mortgagerecords) => {
            return [
                ...mortgagerecords,
                {
                    start_date: '',
                    maturity_date: '',
                    lender: '',
                    amount: '',
                },
            ]
        }),
    )
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.mortgageRecords.form
    const formData = yield select(token)

    const status = calculateProjectStepStatus(formData, {
        mortgagerecords: 'array',
    })

    formData.status = status
    formData.mortgageRecordsChartVisible =
        formData.mortgageRecordsChartVisible.length > 0 && formData.mortgageRecordsChartVisible[0] == '1' ? '1' : '0'

    const response = yield getAxios().post(API_URL, formData)
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'mortgageRecords', 'data', 'mortgagerecords'],
            () => formData.mortgagerecords,
        ),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.MORTGAGE_RECORDS], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'mortgageRecords', 'form'], () => params))
}
export function* scrapMortgageRecords(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                scrapMortgageRecords(projectId: $projectId) {
                    id
                    lender
                    startDate
                    maturityDate
                    amount
                }
            }
        `,
        { projectId },
    )
    const out = []
    for (const item of response.data.scrapMortgageRecords) {
        const record = {
            id: item.id,
            start_date: moment(item.startDate).format('YYYY-MM-DD'),
            maturity_date: item.maturityDate ? moment(item.maturityDate).format('YYYY-MM-DD') : '',
            lender: item.lender,
            amount: item.amount,
        }
        out.push(record)
    }
    if (out.length == 0) {
        out.push({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transactions__date: '',
            buyer: '',
            seller: '',
            saleprice: '',
        })
    }
    yield put(ReduxHelper.setInAction(['fullProject', 'mortgageRecords', 'data', 'mortgagerecords'], out))
}
