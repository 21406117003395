import moment from 'moment'

export function getSerilazeDate(fldDate) {
    // console.log('getSerilazeDate', fldDate, typeof fldDate)
    if (typeof fldDate == 'number') {
        const d = new Date(fldDate)
        // console.log('getDate number', d)
        return (
            d.getFullYear() +
            '-' +
            (d.getMonth() + 1 < 10 ? '0' : '') +
            (d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' : '') +
            d.getDate()
        )
    } else if (typeof fldDate == 'string' && /^\d+$/.test(fldDate)) {
        const d = new Date(parseInt(fldDate))
        const result =
            d.getFullYear() +
            '-' +
            (d.getMonth() + 1 < 10 ? '0' : '') +
            (d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' : '') +
            d.getDate()
        return result
    } else {
        // console.log('getDate else', fldDate, moment(fldDate))
        return moment(fldDate).format('YYYY-MM-DD')
    }
}
