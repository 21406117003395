import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { Sponsor, SponsorPrincipal } from '../../types/fullproject'
import { calculateProjectStepStatus, makeRandomId, objectToFormData } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
const API_URL = API.FULL_PROJECT.SPONSORS

type GetDataParams = {
    projectId: string | any
}

export function* getData(params: GetDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        for (const key in response?.data?.sponsors?.companies) {
            const sponsor = response?.data?.sponsors?.companies[key]
            try {
                sponsor.statistics = JSON.parse(sponsor.statistics).cols
            } catch (e) {
                console.log(e.message)
                sponsor.statistics = []
            }

            if (sponsor.statistics?.length == 0) {
                sponsor.statistics.push({ title: '', value: '' })
            }
        }

        const data = response?.data?.sponsors || { companies: [] }
        if (data.companies?.length === 0) {
            data.companies.push({
                id: '',
                name: '',
                tagline: '',
                description: '',
                linkedin: '',
                twitter: '',
                instagram: '',
                facebook: '',
                website: '',
                logoname: '',
                logourl: '',
                statistics: [{ title: '', value: '' }],
                principals: [
                    {
                        id: '',
                        name: '',
                        title: '',
                        highlights: '',
                        bio: '',
                        linkedin: '',
                        profilefilename: '',
                        profileurl: '',
                    },
                ],
            })
        }

        yield put(ReduxHelper.mergeInAction(['fullProject', 'sponsors', 'data'], data))
        return data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'sponsors', 'form'], () => params))
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.sponsors.form
    const data = yield select(token)

    const status = calculateProjectStepStatus(data, {
        companies: 'array',
    })

    data.status = status

    const preparedData = {
        sponsors: '',
    }

    for (const i in data.companies) {
        const sponsor = data.companies[i]

        if (typeof sponsor?.logoformparamname?.name === 'string') {
            const randomId = makeRandomId()
            preparedData[randomId] = sponsor.logoformparamname
            sponsor.logoformparamname = randomId
        }
        // sponsor.showCorporateChain = sponsor.showCorporateChain[0] || 0
        for (const k in sponsor.principals) {
            const principal = sponsor.principals[k]
            if (typeof principal?.profileformparamname?.name === 'string') {
                const randomId = makeRandomId()
                preparedData[randomId] = principal.profileformparamname
                principal.profileformparamname = randomId
            }
        }

        sponsor.statistics = JSON.stringify({
            cols:
                sponsor?.statistics?.filter((item) => {
                    return item.title !== '' || item.value !== ''
                }) || [],
        })
    }

    preparedData.sponsors = JSON.stringify(data)
    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.SPONSORS], () => status))
    return response.data
}

export function* addSponsor() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'sponsors', 'data', 'companies'], (sponsors: Array<Sponsor>) => {
            return [
                ...sponsors,
                {
                    id: '',
                    name: '',
                    tagline: '',
                    description: '',
                    linkedin: '',
                    twitter: '',
                    instagram: '',
                    facebook: '',
                    website: '',
                    logoname: '',
                    logourl: '',
                    principals: [
                        {
                            id: '',
                            name: '',
                            title: '',
                            highlights: '',
                            bio: '',
                            linkedin: '',
                            profilefilename: '',
                            profileurl: '',
                        },
                    ],
                },
            ]
        }),
    )
}

type AddSponsorParams = {
    sponsorIndex: number
}

export function* addSponsorsPrincipal(params: AddSponsorParams) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'sponsors', 'data', 'companies', params.sponsorIndex, 'principals'],
            (principals: Array<SponsorPrincipal>) => {
                return [
                    ...principals,
                    {
                        id: '',
                        name: '',
                        title: '',
                        highlights: '',
                        bio: '',
                        linkedin: '',
                        profilefilename: '',
                        profileurl: '',
                    },
                ]
            },
        ),
    )
}

export function* setSponsorPrincipals(params: { sponsorIndex: number; principals: any }) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'sponsors', 'data', 'companies', params.sponsorIndex, 'principals'],
            () => {
                return params.principals
            },
        ),
    )
}

export function* addStatisticRecord(params: { sponsorIndex: number }) {
    yield put(
        ReduxHelper.updateInAction(
            // @ts-ignore
            ['fullProject', 'sponsors', 'data', 'companies', params.sponsorIndex, 'statistics'],
            (items) => {
                const newItems = items ? items : []
                return [...newItems, { title: '', value: '' }]
            },
        ),
    )
}
export function* deleteStatisticRecord(params: {
    sponsorIndex: number
    newStatistics: Array<{ title: string; value: string }>
}) {
    yield put(
        ReduxHelper.setInAction(
            // @ts-ignore
            ['fullProject', 'sponsors', 'data', 'companies', params.sponsorIndex, 'statistics'],
            params.newStatistics,
        ),
    )
}

export function* loadSponsorsList({ projectId, searchStr, account }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!, $searchStr: String!, $account: Int!) {
                getSponsorsList(projectId: $projectId, searchStr: $searchStr, account: $account) {
                    id
                    name
                    logofilename
                    logosysname
                    tagline
                    description
                    linkedin
                    twitter
                    instagram
                    facebook
                    website
                    statistics
                    principals {
                        id
                        name
                        title
                        highlights
                        bio
                        profilepicname
                        profilepicsysname
                        linkedin
                    }
                }
            }
        `,
        {
            projectId: +projectId,
            searchStr,
            account,
        },
    )

    yield put(
        ReduxHelper.setInAction(['fullProject', 'sponsors', 'sponsorsList'], response?.data?.getSponsorsList || []),
    )

    return response
}

export function* clearSponsorsList() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'sponsors', 'sponsorsList'], () => []))
}
