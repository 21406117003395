import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'

export function* loadScrapStatus(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                project(projectId: $projectId) {
                    scraperStatus {
                        status
                        id
                        error
                        alternatives {
                            name
                        }
                        name
                    }
                }
            }
        `,
        { projectId },
    )
    return response.data.project.scraperStatus
}
export function* reRunScraper(projectId: number, params: any[]) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $params: [RunScrapInputGraphQL!]!) {
                reScrapProject(projectId: $projectId, params: $params)
            }
        `,
        { projectId, params },
    )
    return response.data.reScrapProject
}
export function* reRunScrapersForProject(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                reRunScrapersForProject(projectId: $projectId)
            }
        `,
        { projectId },
    )
    return response.data.reRunScrapersForProject
}

export function* getPropertyHistory(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) { 
                getPropertyHistory(projectId: $projectId)
            }
        `,
        { projectId },
    )
    return response.data.reRunScrapersForProject
}

