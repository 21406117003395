import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { MarketHouse } from '../../types/fullproject'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { arrayToObject } from '../../../utils/arrayUtils'

export function* getData(params: { projectId: number; pageType: string }) {
    if (params.projectId) {
        const response = yield graphQLRequest(
            gql`
                query ($projectId: Int!, $pageType: String!) {
                    getMarketHouseContent(projectId: $projectId, pageType: $pageType) {
                        id
                        title
                        content
                        enabled
                        data {
                            key
                            value
                            items {
                                key
                                type
                                value
                                items {
                                    key
                                    value
                                    type
                                    items {
                                        key
                                        value
                                        type
                                        items {
                                            key
                                            value
                                            type
                                        }
                                    }
                                }
                            }
                            type
                        }
                    }
                }
            `,
            {
                projectId: +params.projectId,
                pageType: params.pageType,
            },
        )
        const marketHouse: MarketHouse = response?.data?.getMarketHouseContent || {
            title: '',
            content: '',
            enabled: false,
        }
        // @ts-ignore
        if (marketHouse.data) {
            // @ts-ignore
            marketHouse.data = arrayToObject(marketHouse.data)
        }

        yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data'], () => marketHouse))
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'form'], () => params))
}

export function* uploadFiles(params: { projectId: number; blobInfo: any }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $picture: String!, $fileName: String!) {
                uploadMarketHouseImage(projectId: $projectId, picture: $picture, fileName: $fileName)
            }
        `,
        {
            projectId: params.projectId,
            picture: params.blobInfo.base64(),
            fileName: params.blobInfo.filename(),
        },
    )

    return response.data.uploadMarketHouseImage
}

export function* setData(params: { pageType: string }) {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.marketHouse.form)
    const status = 100

    if (formData?.data?.chart) {
        for (const item of formData.data.chart) {
            item.year = +item.year
            item.value = +item.value
        }
    }
    if (formData?.data?.rentComparables) {
        for (const item of formData.data.rentComparables) {
            item.avgUnitSf = typeof item.avgUnitSf == 'number' ? item.avgUnitSf : null
            item.avrRentSF = typeof item.avrRentSF == 'number' ? item.avrRentSF : null
        }
    }
    for (const key in formData.data) {
        if (key.match(/error/i)) {
            delete formData.data[key]
        }
    }
    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $title: String!
                $content: String!
                $enabled: Boolean!
                $status: Int!
                $pageType: String!
                $data: MarketHouseDataInput
            ) {
                updateExtraPageContent(
                    projectId: $projectId
                    title: $title
                    content: $content
                    enabled: $enabled
                    status: $status
                    pageType: $pageType
                    data: $data
                ) {
                    projectId
                    title
                    content
                    enabled
                }
            }
        `,
        {
            projectId: +formData.projectId,
            title: formData.title || '',
            content: formData.content || '',
            enabled: formData.enabled || false,
            status,
            pageType: params.pageType,
            data: formData.data,
        },
    )

    let sectionName
    switch (params.pageType) {
        case 'market_house':
            sectionName = STEP_ID.MARKET_HOUSE
            break
        case 'market_rent':
            sectionName = STEP_ID.MARKET_RENT
            break
    }

    if (sectionName) {
        yield put(ReduxHelper.updateInAction(['fullProject', 'status', sectionName], () => status))
    }

    return response.data.updateLinks
}

export function* clearData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data'], () => null))
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'form'], () => null))
}

export function* scrapData(params: { projectId: number }) {
    const { projectId } = params
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapMarketHousing(projectId: $projectId) {
                    id
                    title
                    content
                    enabled
                    data {
                        items {
                            items {
                                key
                                value
                                type
                            }
                        }
                    }
                }
            }
        `,
        {
            projectId,
        },
    )

    const marketHouse = response?.data?.scrapMarketHousing
    const items = []
    if (marketHouse?.data?.[0]?.items) {
        for (const item of marketHouse?.data?.[0]?.items) {
            items.push(arrayToObject(item.items))
        }
    }
    marketHouse.data = {}
    marketHouse.data.chart = items

    yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data'], () => marketHouse))

    return response?.data?.scrapMarketHousing
}

export function* scrapMarketRent(params: { projectId: number }) {
    const { projectId } = params

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapMarketRent(projectId: $projectId) {
                    id
                    title
                    content
                    enabled
                    data {
                        key
                        value
                        items {
                            key
                            type
                            value
                            items {
                                key
                                value
                                type
                                items {
                                    key
                                    value
                                    type
                                    items {
                                        key
                                        value
                                        type
                                    }
                                }
                            }
                        }
                        type
                    }
                }
            }
        `,
        {
            projectId,
        },
    )

    const marketRent = response?.data?.scrapMarketRent
    if (marketRent?.data) {
        marketRent.data = arrayToObject(marketRent.data)
    }
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data'], () => marketRent))

    return marketRent
}

export function* addItem() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data', 'data', 'chart'], (items) => {
            const newItems = items ? [...items, {}] : [{}]
            return newItems
        }),
    )
}

export function* setItems(params: { newItems: Array<{ year: number; value: number }> }) {
    const { newItems } = params
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'marketHouse', 'data', 'data', 'chart'], () => {
            return newItems
        }),
    )
}
