import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import {
    PropertyHistoryChartData,
    ProperyHistoryTableRow,
    ProperyHistoryTableRowYear,
} from '../../../generated/graphql'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { calculateProjectStepStatus } from '../../../utils'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                propertyHistory(projectId: $projectId) {
                    enabled
                    noi
                    propertyClass
                    historicalOccupancyPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    historicalRentPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    perUnitMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                    perSqFtMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    if (response?.data?.propertyHistory?.historicalOccupancyPerformance?.length == 0) {
        response?.data?.propertyHistory?.historicalOccupancyPerformance?.push({})
    }

    if (response?.data?.propertyHistory?.historicalRentPerformance?.length == 0) {
        response?.data?.propertyHistory?.historicalRentPerformance?.push({})
    }

    if (response?.data?.propertyHistory?.perUnitMonthlyRentalRates?.length == 0) {
        response?.data?.propertyHistory?.perUnitMonthlyRentalRates?.push({
            unitsPerYear: [{}],
        })
    }

    if (response?.data?.propertyHistory?.perSqFtMonthlyRentalRates?.length == 0) {
        response?.data?.propertyHistory?.perSqFtMonthlyRentalRates?.push({
            unitsPerYear: [{}],
        })
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'propertyHistory', 'data'], () => {
            return response?.data?.propertyHistory || []
        }),
    )

    return response?.data?.propertyHistory
}

export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'propertyHistory', 'form'], () => {
            return params.fields
        }),
    )
}

export function* addtDataItem(params: { type: string }) {
    yield put(
        // @ts-ignore
        ReduxHelper.updateInAction(['fullProject', 'propertyHistory', 'data', params.type], (items) => {
            const newItems = items ? items : []
            return [...newItems, {}]
        }),
    )
}

export function* setChartData(params: { chartData: PropertyHistoryChartData[]; type: string }) {
    yield put(
        // @ts-ignore
        ReduxHelper.setInAction(['fullProject', 'propertyHistory', 'data', params.type], params.chartData),
    )
}

export function* setTableData(params: { tableRow: ProperyHistoryTableRow[]; type: string }) {
    yield put(
        // @ts-ignore
        ReduxHelper.setInAction(['fullProject', 'propertyHistory', 'data', params.type], params.tableRow),
    )
}

export function* addPerYearItem(params: { type: string; index: number }) {
    yield put(
        ReduxHelper.updateInAction(
            // @ts-ignore
            ['fullProject', 'propertyHistory', 'data', params.type, params.index, 'unitsPerYear'],
            (items) => {
                const newItems = items ? items : []
                return [...newItems, {}]
            },
        ),
    )
}

export function* setPerYearItems(params: { type: string; index: number; items: Array<ProperyHistoryTableRowYear> }) {
    yield put(
        ReduxHelper.setInAction(
            // @ts-ignore
            ['fullProject', 'propertyHistory', 'data', params.type, params.index, 'unitsPerYear'],
            params.items,
        ),
    )
}

export function* setData() {
    const data = yield select((store: ReduxStoreState) => store.fullProject.propertyHistory.form)
    const projectId = data.projectId
    delete data.projectId

    const status = calculateProjectStepStatus(data, {
        enabled: 'value',
        historicalOccupancyPerformance: 'array',
        historicalRentPerformance: 'array',
        perSqFtMonthlyRentalRates: 'array',
        perUnitMonthlyRentalRates: 'array',
        noi: 'value',
        propertyClass: 'value',
    })

    data.noi = +data.noi

    if (data.historicalOccupancyPerformance) {
        for (const item of data.historicalOccupancyPerformance) {
            item.year = +item.year
            item.propertyClassValue = +item.propertyClassValue
            item.projectValue = +item.projectValue
        }
    } else {
        data.historicalOccupancyPerformance = []
    }
    if (data.historicalRentPerformance) {
        for (const item of data.historicalRentPerformance) {
            item.year = +item.year
            item.propertyClassValue = +item.propertyClassValue
            item.projectValue = +item.projectValue
        }
    } else {
        data.historicalRentPerformance = []
    }
    if (data.perUnitMonthlyRentalRates) {
        for (const item of data.perUnitMonthlyRentalRates) {
            item.unitCount = +item.unitCount
            item.unitSize = +item.unitSize

            if (item.unitsPerYear) {
                for (const perYearItem of item.unitsPerYear) {
                    perYearItem.year = +perYearItem.year
                    perYearItem.count = +perYearItem.count
                }
            } else {
                item.unitsPerYear = []
            }
        }
    } else {
        data.perUnitMonthlyRentalRates = []
    }
    if (data.perSqFtMonthlyRentalRates) {
        for (const item of data.perSqFtMonthlyRentalRates) {
            item.unitCount = +item.unitCount
            item.unitSize = +item.unitSize

            if (item.unitsPerYear) {
                for (const perYearItem of item.unitsPerYear) {
                    perYearItem.year = +perYearItem.year
                    perYearItem.count = +perYearItem.count
                }
            } else {
                item.unitsPerYear = []
            }
        }
    } else {
        data.perSqFtMonthlyRentalRates = []
    }

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $data: PropertyHistoryInput!, $status: Int!) {
                savePropertyHistory(projectId: $projectId, data: $data, status: $status) {
                    enabled
                    noi
                    propertyClass
                    historicalOccupancyPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    historicalRentPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    perUnitMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                    perSqFtMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                }
            }
        `,
        {
            projectId,
            data,
            status,
        },
    )

    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.PROPERTY_HISTORY], () => status))

    return response
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'propertyHistory', 'data'], {
            enabled: false,
            historicalOccupancyPerformance: [],
            historicalRentPerformance: [],
            noi: 0,
            perSqFtMonthlyRentalRates: [],
            perUnitMonthlyRentalRates: [],
            propertyClass: '',
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'propertyHistory', 'form'], null))
}
export function* scrapPropertyHistory(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                scrapPropertyHistory(projectId: $projectId) {
                    enabled
                    noi
                    propertyClass
                    historicalOccupancyPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    historicalRentPerformance {
                        year
                        propertyClassValue
                        projectValue
                    }
                    perUnitMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                    perSqFtMonthlyRentalRates {
                        label
                        unitCount
                        unitSize
                        unitsPerYear {
                            count
                            year
                        }
                    }
                }
            }
        `,
        {
            projectId,
        },
    )

    if (response?.data?.scrapPropertyHistory?.historicalOccupancyPerformance?.length == 0) {
        response?.data?.scrapPropertyHistory?.historicalOccupancyPerformance?.push({})
    }

    if (response?.data?.scrapPropertyHistory?.historicalRentPerformance?.length == 0) {
        response?.data?.scrapPropertyHistory?.historicalRentPerformance?.push({})
    }

    if (response?.data?.scrapPropertyHistory?.perUnitMonthlyRentalRates?.length == 0) {
        response?.data?.scrapPropertyHistory?.perUnitMonthlyRentalRates?.push({
            unitsPerYear: [{}],
        })
    }

    if (response?.data?.scrapPropertyHistory?.perSqFtMonthlyRentalRates?.length == 0) {
        response?.data?.scrapPropertyHistory?.perSqFtMonthlyRentalRates?.push({
            unitsPerYear: [{}],
        })
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'propertyHistory', 'data'], () => {
            return response?.data?.scrapPropertyHistory || []
        }),
    )

    return response?.data?.scrapPropertyHistory
}
