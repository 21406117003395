import { graphQLRequest } from '../../../store/graphQLClient'
import gql from 'graphql-tag'
import { QuickAnalysisInputGraphQl } from '@generated/graphql'

export function* loadQA(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                quickAnalysis(projectId: $projectId) {
                    income {
                        grossPotentialRent {
                            abs
                            type
                        }
                        vacancy {
                            abs
                            percentage
                            type
                        }
                        lossToLease {
                            abs
                            percentage
                            type
                        }
                        badDebt {
                            abs
                            percentage
                            type
                        }
                        concession {
                            abs
                            percentage
                            type
                        }
                    }
                    expense {
                        taxes {
                            abs
                            percentage
                            type
                        }
                        otherExpenses {
                            abs
                            percentage
                            type
                        }
                    }
                    sources {
                        loanAmount {
                            percentage
                            abs
                            type
                        }
                    }
                    uses {
                        purchasePrice
                        capEx
                    }
                    property {
                        address
                        yearBuilt
                        buildingClass
                        numberOfUnits
                    }
                    valuation
                    assessedValue
                    completeRenovation
                    capRate
                    capRateComps
                }
            }
        `,
        {
            projectId: p.projectId,
        },
    )
    return response.data.quickAnalysis
}
export function* saveQA(p: { projectId: number; data: QuickAnalysisInputGraphQl }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: QuickAnalysisInputGraphQL!) {
                quickAnalysisSave(projectId: $projectId, data: $data)
            }
        `,
        p,
    )
    return response.data.quickAnalysisSave
}
