import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { EmploymentLocation } from '../../types/fullproject'
import { calculateProjectStepStatus, objectToFormData } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.EMPLOYMENT_LOCATIONS

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        let data = response?.data?.project
        if (data?.employmentlocations?.length === 0) {
            data.employmentlocations.push({
                id: '',
                title: '',
                subtitle: '',
                description: '',
                imgname: '',
                img: '',
            })
        }

        yield put(ReduxHelper.updateInAction(['fullProject', 'employmentLocations', 'data'], () => data))
        return response.data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'employmentLocations', 'form'], () => params))
}

export function* setEmploymentLocations(params: Array<EmploymentLocation>) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'employmentLocations', 'data', 'employmentlocations'], () => params),
    )
}

export function* addEmploymentLocation() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'employmentLocations', 'data', 'employmentlocations'],
            (units: Array<EmploymentLocation>) => {
                return [
                    ...units,
                    {
                        id: '',
                        title: '',
                        subtitle: '',
                        description: '',
                        imgname: '',
                        img: '',
                    },
                ]
            },
        ),
    )
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.employmentLocations.form
    const data = yield select(token)

    let status = calculateProjectStepStatus(data, {
        employmentlocations: 'array',
    })
    data.status = status

    let preparedData = {
        propertyDetails: '',
    }

    let filesCount = 0
    for (let i in data.employmentlocations) {
        let fileField = data?.employmentlocations?.[i]?.emplimg
        if (typeof fileField?.name == 'string') {
            preparedData[`emplimg${filesCount}`] = fileField
            data.employmentlocations[i].emplimg = `emplimg${filesCount}`
            filesCount++
        }
    }
    preparedData.propertyDetails = JSON.stringify(data)
    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.EMPLOYMENT_LOCATIONS], () => status))
    return response.data
}
