import axios, { AxiosInstance } from 'axios'
export function getAxios(): AxiosInstance {
    let baseUrl = ''
    if (typeof window == 'undefined') {
        const { Config } = require('../config')
        baseUrl = Config.PHP_API
    } else {
        baseUrl = ''
    }
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 5 * 60 * 1000,
        withCredentials: true,
        validateStatus: function (status) {
            if (status >= 200 && status < 300) {
                return true
            }
            if (status == 401 || status == 503) {
                localStorage.removeItem('username')
                window.location.href = '/v1'
            }
            return false
        },
    })
    instance.interceptors.response.use(
        function (response) {
            const data = response.data
            // alert(1)
            if (data.code && data.error) {
                return Promise.reject(Error(data.error))
            }
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error)
        },
    )
    return instance
}
