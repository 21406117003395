import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { Construction } from '../../../generated/graphql'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                getConstructionData(projectId: $projectId) {
                    enabled
                    projectedOccupancy
                    location {
                        latitude
                        longitude
                    }
                    planned {
                        name
                        address
                        location {
                            latitude
                            longitude
                        }
                        noUnits
                        subMarket
                        imprRating
                        locRating
                        distance
                        status
                        details
                    }
                    underConstruction {
                        name
                        address
                        location {
                            latitude
                            longitude
                        }
                        noUnits
                        subMarket
                        imprRating
                        locRating
                        distance
                        status
                        details
                    }
                    supplyAndDemand {
                        year
                        occupancy
                        supply
                        demand
                        inventory
                        peoplePerHouseHold
                        population
                    }
                }
            }
        `,
        { projectId: +params.projectId },
    )

    if (response?.data?.getConstructionData?.planned?.length == 0) {
        response?.data?.getConstructionData?.planned?.push({})
    }

    if (response?.data?.getConstructionData?.underConstruction?.length == 0) {
        response?.data?.getConstructionData?.underConstruction?.push({})
    }

    if (response?.data?.getConstructionData?.supplyAndDemand?.length == 0) {
        response?.data?.getConstructionData?.supplyAndDemand?.push({})
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'data'], () => {
            return response?.data?.getConstructionData || []
        }),
    )

    return response?.data?.getConstructionData || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'form'], () => {
            return params.fields
        }),
    )
}

export function* addItem(itemType) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'data', itemType], (items) => {
            const oldItems = items ? items : []
            return [...oldItems, {}]
        }),
    )
}

export function* addSupply() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'data', 'supplyAndDemand'], (items) => {
            const oldItems = items ? items : []
            return [...oldItems, {}]
        }),
    )
}

export function* setItem(itemType, items) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'construction', 'data', itemType], () => items))
}

export function* setSupply(supplies) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'construction', 'data', 'supplyAndDemand'], () => supplies))
}

export function* setData() {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.construction.form)
    const defaultLocation = { latitude: 0, longitude: 0 }
    const status = 100

    if (!formData.supplyAndDemand) {
        formData.supplyAndDemand = []
    }

    if (formData.planned) {
        for (const item of formData.planned) {
            if (!item.location) {
                item.location = defaultLocation
            }

            item.noUnits = +item.noUnits
        }
    }
    if (formData.underConstruction) {
        for (const item of formData.underConstruction) {
            if (!item.location) {
                item.location = defaultLocation
            }
            item.noUnits = +item.noUnits
        }
    }
    if (formData.supplyAndDemand) {
        for (const item of formData.supplyAndDemand) {
            item.year = +item.year
            item.occupancy = +item.occupancy
            item.supply = +item.supply
            item.demand = +item.demand
        }
    }

    const data: Construction = {
        enabled: formData.enabled,
        projectedOccupancy: +formData.projectedOccupancy || 0,
        location: formData.location,
        planned: formData.planned,
        underConstruction: formData.underConstruction,
        supplyAndDemand: formData.supplyAndDemand,
    }

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $data: ConstructionInput!, $status: Int!) {
                saveConstructionData(projectId: $projectId, data: $data, status: $status) {
                    enabled
                }
            }
        `,
        { projectId: formData.projectId, data, status },
    )

    yield put(ReduxHelper.setInAction(['fullProject', 'status', STEP_ID.CONSTRUCTION], status.toString()))

    return response
}

export function* getLocation(address: string) {
    try {
        const response = yield graphQLRequest(
            gql`
                query($address: String!) {
                    getLocationForAddress(address: $address) {
                        latitude
                        longitude
                    }
                }
            `,
            {
                address,
            },
        )

        return response.data.getLocationForAddress
    } catch (e) {
        return { latitude: 0, longitude: 0 }
    }
}

export function* clearData() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'data'], () => {
            return null as Construction | null
        }),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'construction', 'form'], () => null))
}

export function* scrapConstructionData(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                scrapConstructionInfo(projectId: $projectId) {
                    enabled
                    projectedOccupancy
                    location {
                        latitude
                        longitude
                    }
                    planned {
                        name
                        address
                        location {
                            latitude
                            longitude
                        }
                        noUnits
                        subMarket
                        imprRating
                        locRating
                        distance
                        status
                        details
                    }
                    underConstruction {
                        name
                        address
                        location {
                            latitude
                            longitude
                        }
                        noUnits
                        subMarket
                        imprRating
                        locRating
                        distance
                        status
                        details
                    }
                    supplyAndDemand {
                        year
                        occupancy
                        supply
                        demand
                        inventory
                        peoplePerHouseHold
                        population
                    }
                }
            }
        `,
        { projectId },
    )

    if (response?.data?.scrapConstructionInfo?.planned?.length == 0) {
        response?.data?.scrapConstructionInfo?.planned?.push({})
    }

    if (response?.data?.scrapConstructionInfo?.underConstruction?.length == 0) {
        response?.data?.scrapConstructionInfo?.underConstruction?.push({})
    }

    if (response?.data?.scrapConstructionInfo?.supplyAndDemand?.length == 0) {
        response?.data?.scrapConstructionInfo?.supplyAndDemand?.push({})
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'construction', 'data'], () => {
            return response?.data?.scrapConstructionInfo || []
        }),
    )

    return response?.data?.scrapConstructionInfo || []
    /*
    mutation{
 scrapConstructionInfo(projectId:87){
  planned{
    name
  }
}
}
     */
}
