import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { put, select } from '@redux-saga/core/effects'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                managementCompany(projectId: $projectId) {
                    enabled
                    title
                    advantages {
                        title
                        value
                    }
                    description
                    feedbacks {
                        text
                        author
                    }
                    score
                    satisfaction {
                        title
                        value
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    if (response.data.managementCompany.feedbacks.length == 0) {
        response.data.managementCompany.feedbacks.push({})
    }
    if (response.data.managementCompany.satisfaction.length == 0) {
        response.data.managementCompany.satisfaction.push({})
    }
    if (response.data.managementCompany.advantages.length == 0) {
        response.data.managementCompany.advantages.push({})
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'managementCompany', 'data'], () => {
            return response?.data?.managementCompany
        }),
    )

    return response?.data?.managementCompany
}

export function* addItem(params: { type: string }) {
    yield put(
        // @ts-ignore
        ReduxHelper.updateInAction(['fullProject', 'managementCompany', 'data', params.type], (items) => {
            return [...items, {}]
        }),
    )
}

export function* setItems(params: { type: string; newItems: Array<any> }) {
    yield put(
        // @ts-ignore
        ReduxHelper.setInAction(['fullProject', 'managementCompany', 'data', params.type], params.newItems),
    )
}

export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'managementCompany', 'form'], () => {
            return params.fields
        }),
    )
}

export function* setData() {
    const data = yield select((store: ReduxStoreState) => store.fullProject.managementCompany.form)

    const projectId = data.projectId
    const enabled = data.enabled
    const status = 100
    delete data.projectId

    data.score = +data.score

    if (data?.satisfaction) {
        for (const item of data.satisfaction) {
            item.value = +item.value
        }
    } else {
        data.satisfaction = []
    }

    if (!data.feedbacks) data.feedbacks = []
    if (!data.advantages) data.advantages = []

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $status: Int!, $data: ManagementCompanyInput!, $enabled: Boolean!) {
                saveManagementCompany(projectId: $projectId, data: $data, enabled: $enabled, status: $status)
            }
        `,
        {
            projectId,
            status,
            enabled,
            data,
        },
    )

    return response
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'managementCompany', 'data'], {
            advantages: [],
            description: '',
            enabled: false,
            feedbacks: [],
            satisfaction: [],
            score: 0,
            title: '',
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'managementCompany', 'form'], null))
}
