import gql from 'graphql-tag'
import { graphQLRequest } from '../../graphQLClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                financials(projectId: $projectId) {
                    enabled
                }
            }
        `,
        { projectId: +params.projectId },
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'financials', 'data'], () => {
            return response?.data?.financials
        }),
    )

    return response?.data?.financials || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'financials', 'form'], () => {
            return params.fields
        }),
    )
}

export function* setData(projectId: number) {
    const data = yield select((store: ReduxStoreState) => store.fullProject.financials.form)
    const status = 100

    //console.log('data', data)

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: FinancialsInput!, $status: Int!) {
                saveFinancials(projectId: $projectId, data: $data, status: $status) {
                    enabled
                }
            }
        `,
        {
            status,
            projectId,
            data,
        },
    )

    return response.data.saveFinancials
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'financials', 'data'], {
            enabled: true,
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'financials', 'form'], null))
}
