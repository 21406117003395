import gql from 'graphql-tag'
import { put } from 'redux-saga/effects'
import { graphQLRequest } from '../../graphQLClient'
import { LeaseUpGraphQl } from '@generated/graphql'
import { ReduxHelper } from '../../index'

export function* createLeaseUp(params: {
    projectId: number
    availableUnits: number
    allUnitsAvailableDate: Date
    avgProjectedWeeklyMoveIns: number
}) {
    const { projectId, availableUnits, allUnitsAvailableDate, avgProjectedWeeklyMoveIns } = params
    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $availableUnits: Int!
                $allUnitsAvailableDate: DateTime!
                $avgProjectedWeeklyMoveIns: Int!
            ) {
                createLeaseUp(
                    projectId: $projectId
                    availableUnits: $availableUnits
                    allUnitsAvailableDate: $allUnitsAvailableDate
                    avgProjectedWeeklyMoveIns: $avgProjectedWeeklyMoveIns
                ) {
                    id
                    projectId
                    availableUnits
                    allUnitsAvailableDate
                    avgProjectedWeeklyMoveIns
                    stabilizationOccupancyRate
                    defaultStabilizationRate
                    defaultProjectionStartDate
                    projectionCustomDate
                    pricingOptionId
                    showSubmarketInfo
                    projectedMoveIns
                    newUnitsDelivered
                }
            }
        `,
        {
            projectId,
            availableUnits,
            allUnitsAvailableDate,
            avgProjectedWeeklyMoveIns,
        },
    )

    const leaseUp = response?.data?.createLeaseUp

    if (leaseUp) {
        yield put(ReduxHelper.setInAction(['lender', 'leaseUp', 'data'], leaseUp))
    }

    return leaseUp
}

export function* getLeaseUp(params: { projectId: number }) {
    const projectId = params.projectId
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getLeaseUp(projectId: $projectId) {
                    id
                    projectId
                    availableUnits
                    allUnitsAvailableDate
                    avgProjectedWeeklyMoveIns
                    stabilizationOccupancyRate
                    projectionCustomDate
                    pricingOptionId
                    showSubmarketInfo
                    projectedMoveIns
                    newUnitsDelivered
                    defaultStabilizationRate
                    defaultProjectionStartDate
                }
            }
        `,
        {
            projectId,
        },
    )

    yield put(ReduxHelper.setInAction(['lender', 'leaseUp', 'data'], response?.data?.getLeaseUp || null))

    return response?.data?.getLeaseUp || null
}

export function* getFutureRentRoll(params: { projectId: number }) {
    const projectId = params.projectId
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                futureRentRoll(projectId: $projectId) {
                    id
                    leaseStart
                    leaseExpire
                }
            }
        `,
        {
            projectId,
        },
    )

    yield put(ReduxHelper.setInAction(['lender', 'leaseUp', 'futureRentRoll'], response?.data?.futureRentRoll || null))

    return response?.data?.futureRentRoll || null
}

export function* getLeaseUpSubmarketData(params: { projectId: number; yearBuilt: number }) {
    const projectId = params.projectId
    const yearBuilt = params.yearBuilt
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!, $yearBuilt: Int!) {
                getLeaseUpSubmarketData(projectId: $projectId, yearBuilt: $yearBuilt) {
                    vacancy {
                        submarket {
                            mean
                            similarlyAged
                            median
                        }
                        market {
                            mean
                            similarlyAged
                            median
                        }
                    }
                    concessions {
                        submarket {
                            mean
                            similarlyAged
                            median
                        }
                        market {
                            mean
                            similarlyAged
                            median
                        }
                    }
                    submarketName
                }
            }
        `,
        {
            projectId,
            yearBuilt,
        },
    )

    yield put(
        ReduxHelper.setInAction(
            ['lender', 'leaseUp', 'submarketData'],
            response?.data?.getLeaseUpSubmarketData || null,
        ),
    )

    return response?.data?.getLeaseUpSubmarketData || null
}

export function* updateLeaseUp(params: { projectId: number; data: LeaseUpGraphQl }) {
    const { projectId, data } = params
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: LeaseUpInputGraphQL!) {
                updateLeaseUp(projectId: $projectId, data: $data) {
                    id
                }
            }
        `,
        {
            projectId,
            data,
        },
    )

    const leaseUp = response?.data?.updateLeaseUp

    return leaseUp
}

export function* deleteLeaseUp(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                deleteLeaseUp(projectId: $projectId)
            }
        `,
        {
            projectId,
        },
    )

    if (response) {
        yield put(
            ReduxHelper.setInAction(['lender', 'leaseUp'], {
                data: null,
                submarketData: null,
                futureRentRoll: null,
            }),
        )
    }

    return response
}
