import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { myStorage } from '../../storage'

import { FannieQuotes, Pricing } from '@generated/graphql'
const cloneDeep = require('lodash.clonedeep')
const remove = require('lodash/remove')

export function* getData(params: { projectId: number }) {
    const DEBUG = false
    const projectId = params.projectId
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getProjectFannieQuotes(projectId: $projectId) {
                    team {
                        memberName
                        memberTitle
                        phone
                        email
                    }
                    quotes {
                        id
                        name
                        to
                        from
                        date
                        considerations
                        legal
                        footer
                        details {
                            name
                            value
                        }
                    }
                }
            }
        `,
        {
            projectId: projectId,
        },
    )

    if (DEBUG) console.log('Model respnse', response)
    // transform server data to more usefull format

    const reduxDataFannieQuotes: FannieQuotes = response.data.getProjectFannieQuotes
    yield put(ReduxHelper.setInAction(['lender', 'fannieQuotes'], reduxDataFannieQuotes || null))
    yield put(ReduxHelper.setInAction(['lender', 'isFannieQuotesLoaded'], reduxDataFannieQuotes ? true : false))
    return response?.data
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'fannieQuotes'], null))
}

export function* setFannieQuotes(params: { projectId: number; fannieQuotes: any }) {
    if (params && params.fannieQuotes && params.fannieQuotes.quotes?.length > 0) {
        const data = cloneDeep(params.fannieQuotes)
        delete data.team
        const response = yield graphQLRequest(
            gql`
                mutation ($projectId: Int!, $data: FannieQuotesInput!) {
                    setProjectFannieQuotes(projectId: $projectId, data: $data)
                }
            `,
            {
                projectId: params.projectId,
                data: data,
            },
        )

        return response?.data?.setProjectFannieQuotes || false
    }
}
