import { ReduxHelper, ReduxStoreState } from '../../index'
import { put, select } from '@redux-saga/core/effects'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { MarketAreaInfo } from '../../../generated/graphql'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

export function* getData(params: { projectId }) {
    //console.log("areinfo saga stat")
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getMarketAreaInformation(projectId: $projectId) {
                    marketAreas {
                        radius
                        properties
                        totalUnits
                        totalUnitsSQFt
                        householdChildrenVisible
                        occupancyVisible
                        familyHouseHoldsVisible
                        houseHoldStructureVisible
                        incomeVisible
                        householdOwnerShipVisible
                        yearBuiltVisible
                        householdOwnerShip {
                            key
                            value
                        }
                        householdChildren {
                            key
                            value
                        }
                        occupancy {
                            key
                            value
                        }
                        familyHouseHolds {
                            key
                            value
                        }
                        houseHoldStructure {
                            key
                            value
                        }
                        income {
                            key
                            value
                        }
                        yearBuilt {
                            key
                            value
                        }
                    }
                    enabled
                }
            }
        `,
        { projectId: +params.projectId },
    )
    //console.log("areinfo saga mid",response?.data?.getMarketAreaInformation)
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'areaInformation', 'data'], () => {
            return response?.data?.getMarketAreaInformation || {}
        }),
    )

    //console.log("areinfo saga end")
    return response?.data?.getMarketAreaInformation || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'areaInformation', 'form'], () => {
            return params.fields
        }),
    )
}
export function* setData() {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.areaInformation.form)
    const status = 100
    for (const i in formData.areaInformation) {
        const areaInformation: MarketAreaInfo = formData.areaInformation[i]
        for (const k in areaInformation) {
            if (typeof areaInformation[k] === 'string') {
                areaInformation[k] = areaInformation[k] ? +areaInformation[k] : 0
            }
            if (typeof areaInformation[k] === 'object') {
                const fieldSet = areaInformation[k]
                for (const j in fieldSet) {
                    fieldSet[j].value = fieldSet[j].value ? +fieldSet[j].value : 0
                }
            }
        }
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: [MarketAreaInfoInput!]!, $status: Int!, $enabled: Boolean!) {
                saveMarketAreaInformation(projectId: $projectId, data: $data, status: $status, enabled: $enabled)
            }
        `,
        {
            projectId: formData.projectId,
            data: formData.areaInformation,
            enabled: formData.enabled,
            status,
        },
    )

    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.AREA_INFORMATION], () => status))

    return response
}
export function* clearData() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'areaInformation', 'data'], () => {
            return { marketAreas: [] as MarketAreaInfo[], enabled: false }
        }),
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'areaInformation', 'form'], () => null))
}
