import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { DealHighlightsDetail } from '../../types/fullproject'
import { API } from '../../../utils/constants/api'

import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.DEAL_HIGHLIGHTS_PART_I

// eslint-disable-next-line @typescript-eslint/naming-convention
type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        const initialResponse = response.data.project
        const parsedResponse = {
            id: initialResponse.id,
            initialDefault: initialResponse.default,
            initialDetails: initialResponse.details,
            terms: initialResponse.terms,
            cashoncash: initialResponse.cashoncash,
            irr: initialResponse.irr,
            equitymulti: initialResponse.equitymulti,
            extraPageEnabled: initialResponse.extraPageEnabled == '0' ? [] : ['1'],
            details: {
                loan: [] as string[],
                detials: [] as DealHighlightsDetail[],
            },
            content: initialResponse?.content,
        }

        const parsedInitialDefault = initialResponse.default ? JSON.parse(initialResponse.default) : []
        const parsedInitialDetails = initialResponse.details ? JSON.parse(initialResponse.details) : []

        let initialTablesShort: any = null
        let initialTablesLong: any = null

        if (parsedInitialDetails) {
            parsedResponse.details.loan = parsedInitialDetails.loan
                ? parsedInitialDetails.loan
                : parsedInitialDefault.loan
            parsedResponse.details.detials = parsedInitialDetails.details ? parsedInitialDetails.details : []

            initialTablesShort = parsedInitialDetails.tables_short
            initialTablesLong = parsedInitialDetails.tables_long
        } else {
            parsedResponse.details.loan = parsedInitialDefault.loan ? parsedInitialDefault.loan : []
            parsedResponse.details.detials = parsedInitialDefault.details ? parsedInitialDefault.details : []

            initialTablesShort = parsedInitialDefault.tables_short
            initialTablesLong = parsedInitialDefault.tables_long
        }

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'initialDetails'], (item) => {
                return [...parsedResponse.details.detials]
            }),
        )
        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'initialLoans'], (item) => {
                return [...parsedResponse.details.loan]
            }),
        )

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'initialTablesShort'], (item) => {
                return initialTablesShort
            }),
        )

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'initialTablesLong'], (item) => {
                return initialTablesLong
            }),
        )

        if (parsedResponse.details.loan.length == 0) {
            parsedResponse.details.loan.push('')
        }
        if (parsedResponse.details.detials.length == 0) {
            parsedResponse.details.detials.push({
                title: '',
                description: '',
            })
        }

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'data'], (data) => {
                return parsedResponse
            }),
        )
        return response.data
    }
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.dealHighlightsPartOne.form
    const formData = yield select(token)

    const status = calculateProjectStepStatus(formData, {
        cashoncash: 'value',
        details: 'value',
        equitymulti: 'value',
        irr: 'value',
        terms: 'value',
    })

    formData.statusp1 = status
    formData.extraPageEnabled = formData.extraPageEnabled.length > 0 && formData.extraPageEnabled[0] == '1' ? '1' : '0'

    try {
        formData.details.loan =
            formData.details?.loan?.filter((item) => {
                return item !== null && item !== undefined && item !== ''
            }) || []

        formData.details.details =
            formData.details?.details?.filter((item) => {
                let titleValid = false
                let descriptionValid = false

                if (item.title !== '' && item.title !== null && item.title !== undefined) {
                    titleValid = true
                }
                if (item.description !== '' && item.description !== null && item.description !== undefined) {
                    descriptionValid = true
                }
                return titleValid || descriptionValid
            }) || []
    } catch (e) {
        console.log(e.message)
    }

    // if (formData.details.loan.length == 0) {
    //     formData.details.loan = yield select(
    //         (store: ReduxStoreState) => store.fullProject.dealHighlightsPartOne.initialLoans,
    //     )
    // }

    if (formData.details.details.length == 0) {
        formData.details.details = yield select(
            (store: ReduxStoreState) => store.fullProject.dealHighlightsPartOne.initialDetails,
        )
    }

    const initialTablesShort = yield select(
        (store: ReduxStoreState) => store.fullProject.dealHighlightsPartOne.initialTablesShort,
    )
    const initialTablesLong = yield select(
        (store: ReduxStoreState) => store.fullProject.dealHighlightsPartOne.initialTablesLong,
    )

    if (initialTablesShort) formData.details.tables_short = initialTablesShort
    if (initialTablesLong) formData.details.tables_long = initialTablesLong

    if (formData['details']['detials']) {
        formData['details']['details'] = formData['details']['detials']
        delete formData['details']['detials']
    }

    formData.details = JSON.stringify(formData.details)

    const payloadFormData = new FormData()
    payloadFormData.append('propertyDetails', JSON.stringify(formData))

    const response = yield getAxios().post(API_URL, payloadFormData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealOutline', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.DEAL_HIGHLIGHTS_I], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'form'], () => params))
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type setNewLoansParams = {
    loans: Array<string>
}
export function* setNewLoans(params: setNewLoansParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'data', 'details', 'loan'], () => {
            return params.loans
        }),
    )
}

export function* addNewLoan() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'data', 'details', 'loan'], (loans) => {
            const oldLoans = loans ? loans : []
            return [...oldLoans, '']
        }),
    )
}

type SetNewDetailsParams = {
    details: Array<DealHighlightsDetail>
}
export function* setNewDetails(params: SetNewDetailsParams) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealHighlightsPartOne', 'data', 'details', 'detials'], () => {
            return params.details
        }),
    )
}

export function* addNewDetail() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'dealHighlightsPartOne', 'data', 'details', 'detials'],
            (details) => {
                const oldDetails = details ? details : []
                return [
                    ...oldDetails,
                    {
                        title: '',
                        description: '',
                    },
                ]
            },
        ),
    )
}
