export function arrayToObject(arr: any) {
    if (!arr) return null
    if (!Array.isArray(arr)) {
        if (arr.type == 'array') {
            const out = []
            for (const v of arr.items) {
                out.push(arrayToObject(v))
            }
            return out
        } else if (arr.type == 'object') {
            return arrayToObject(arr.items)
        } else if (arr.type == 'number') {
            return +arr.value
        } else {
            return arr.value
        }
    }
    const obj: Record<string, any> = {}
    for (const item of arr) {
        obj[item.key] = arrayToObject(item)
    }
    return Object.keys(obj).length === 0 ? null : obj
}
