import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { ProjectLink } from '../../types/fullproject'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

export function* getData(params: { projectId: any }) {
    if (params.projectId) {
        const response = yield graphQLRequest(
            gql`
                query($projectId: Int!) {
                    getLinksList(projectId: $projectId) {
                        id
                        title
                        link
                        isproforma
                        date
                    }
                }
            `,
            {
                projectId: +params.projectId,
            },
        )
        const links = response?.data?.getLinksList || []
        if (links?.length > 0) {
            yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'data'], () => links))
        } else {
            yield put(
                ReduxHelper.updateInAction(['fullProject', 'links', 'data'], (links: Array<ProjectLink>) => {
                    return [
                        ...links,
                        {
                            title: '',
                            link: '',
                        },
                    ]
                }),
            )
        }
        return links
    }
}

export function* getAccountLinks(params: { projectId: any }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                getLinksForAccount(projectId: $projectId) {
                    title
                    link
                }
            }
        `,
        {
            projectId: +params.projectId,
        },
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'links', 'accountLinks'], () => {
            return response.data.getLinksForAccount
        }),
    )
}

export function* addUsersLinkToProject(params: { link: ProjectLink }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'links', 'data'], (links: Array<ProjectLink>) => {
            const newLinks = [...links]
            newLinks.push(params.link)
            return newLinks
        }),
    )
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'links', 'accountLinks'], (links: Array<ProjectLink>) => {
            return links.filter((link: ProjectLink) => {
                return link.link !== params.link.link
            })
        }),
    )

    const links = yield select((store: ReduxStoreState) => store.fullProject.links.accountLinks)
    return links
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'form'], () => params))
}

export function* setData() {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.links.form)

    for (const i in formData.links) {
        const link = formData.links[i]
        link.isproforma = !!link.isproforma
        const dateArray = link.date.split('-')
        link.date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
    }

    const status = formData?.links?.length > 0 ? 100 : 0

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $links: [LinkInput!]!, $deleteLinkIds: [Int!]!, $status: Int!) {
                updateLinks(projectId: $projectId, links: $links, deleteLinkIds: $deleteLinkIds, status: $status) {
                    id
                    title
                    link
                    isproforma
                    date
                }
            }
        `,
        {
            projectId: +formData.projectId,
            links: formData.links || [],
            deleteLinkIds: formData.deleteLinkIds || [],
            status,
        },
    )

    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.LINKS], () => status))
    yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'data'], () => []))

    return response.data.updateLinks
}

export function* setLinks(params: { links: Array<ProjectLink> }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'links', 'data'], () => {
            return params.links
        }),
    )
}

export function* addLink() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'links', 'data'], (links: Array<ProjectLink>) => {
            return [
                ...links,
                {
                    title: '',
                    link: '',
                },
            ]
        }),
    )
}

export function* clearLinksData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'data'], () => []))
    yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'form'], () => null))
    yield put(ReduxHelper.updateInAction(['fullProject', 'links', 'accountLinks'], () => []))
}
