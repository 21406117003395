import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { calculateProjectStepStatus, makeRandomId, objectToFormData } from '../../../utils'
import { GridLocation } from '../../types/fullproject'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.GRID_LOCATIONS

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        let data = response?.data?.project

        if (data?.gridlocations.length === 0) {
            data.gridlocations.push({
                title: '',
                description: '',
                data_val: '',
                economictitle: '',
                economicdesc: '',
                data_title: '',
                imgname: '',
                img: '',
            })
        }

        yield put(ReduxHelper.updateInAction(['fullProject', 'gridLocations', 'data'], () => data))
        return response.data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'gridLocations', 'form'], () => params))
}

export function* setGridLocations(params: Array<GridLocation>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'gridLocations', 'data', 'gridlocations'], () => params))
}

export function* addGridLocation() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'gridLocations', 'data', 'gridlocations'],
            (units: Array<GridLocation>) => {
                return [
                    ...units,
                    {
                        title: '',
                        description: '',
                        data_val: '',
                        economictitle: '',
                        economicdesc: '',
                        data_title: '',
                        imgname: '',
                        img: '',
                    },
                ]
            },
        ),
    )
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.gridLocations.form
    const data = yield select(token)

    let status = calculateProjectStepStatus(data, {
        gridlocations: 'array',
    })

    data.status = status

    let preparedData = {
        propertyDetails: '',
    }

    for (let i in data.gridlocations) {
        let fileField = data?.gridlocations?.[i]?.gridimg
        if (typeof fileField?.name == 'string') {
            const randomId = makeRandomId()
            // @ts-ignore
            preparedData[randomId] = fileField
            data.gridlocations[i].gridimg = randomId
        }
    }

    preparedData.propertyDetails = JSON.stringify(data)
    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.GRID_LOCATIONS], () => status))
    return response.data
}
