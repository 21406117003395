export const parseJsonToAgGrid = (data: string) => {
    let agGridData = {
        cols: [
            {
                headerName: '',
                field: 'title',
                editable: true,
            },
            {
                headerName: '',
                field: 'control',
                cellRenderer: 'AgGridControlCell',
            },
        ],
        rows: [],
    }

    if (data) {
        try {
            let dataJson = JSON.parse(data)
            //setup cols
            let firstRow = []
            for (let key in dataJson.columns) {
                if (dataJson.columns.hasOwnProperty(key)) {
                    let item = {
                        headerName: dataJson.columns[key],
                        field: key.replace('col', 'val'),
                        editable: true,
                    }
                    agGridData.cols.splice(agGridData.cols.length - 1, 0, item)
                    firstRow[key.replace('col', 'val')] = dataJson.columns[key]
                }
            }
            agGridData.rows.push(firstRow)

            //setup rows
            for (let key in dataJson.rows) {
                if (dataJson.rows.hasOwnProperty(key)) {
                    let item = dataJson.rows[key]
                    agGridData.rows.push(item)
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    return agGridData
}

export const getRowData = (gridApi) => {
    let rowData = []
    gridApi.forEachNode(function (node) {
        rowData.push(node.data)
    })
    return rowData
}

export function parseAdGridToJson(data: Array<any>) {
    let parsedData = {
        custom: 1,
        columns: {},
        rows: [],
    }

    for (let i = 0; i < data.length; i++) {
        let item = data[i]
        if (i === 0) {
            let firstItem = {}
            for (let key in item) {
                if (item.hasOwnProperty(key)) {
                    firstItem[key.replace('val', 'col')] = item[key]
                }
            }
            parsedData.columns = firstItem
        } else {
            parsedData.rows.push(item)
        }
    }

    return parsedData
}

export function parseJsonToAgGridDealHighlights(data) {
    let parsedData = {
        cols: [
            {
                headerName: '',
                field: 'control',
                cellRenderer: 'DealHighlightsPartTwoAgGridControlCell',
            },
        ],
        rows: [],
    }

    //setup cols
    let firstRow = {}
    for (let key in data.head) {
        let item = {
            headerName: data.head[key],
            field: `col${+key + 1}`,
            editable: true,
        }
        // @ts-ignore
        parsedData.cols.splice(parsedData.cols.length - 1, 0, item)

        firstRow[`col${+key + 1}`] = data.head[key]
    }
    parsedData.rows.push(firstRow)

    //setup rows
    for (let key in data.data) {
        let itemObj = {}
        for (let i in data.data[key]) {
            let item = data.data[key][i]
            itemObj[`col${i}`] = item
        }
        parsedData.rows.push(itemObj)
    }

    return parsedData
}

export function parseAgGridToJson(data) {
    let parsedData = {
        tables_short: [],
        tables_long: [],
    }

    for (let table of data) {
        let parsedTable = {
            head: [],
            data: [],
        }

        for (let i in table.gridData) {
            if (+i == 0) {
                for (let k in table.gridData[i]) {
                    parsedTable.head.push(table.gridData[i][k])
                }
            } else {
                for (let k in table.gridData[i]) {
                    if (!parsedTable.data[+i - 1]) {
                        parsedTable.data[+i - 1] = []
                    }
                    parsedTable.data[+i - 1].push(table.gridData[i][k])
                }
            }
        }

        switch (table.tableType) {
            case 'shortTable':
                parsedData.tables_short.push(parsedTable)
                break
            case 'longTable':
                parsedData.tables_long.push(parsedTable)
                break
        }
    }

    return parsedData
}
