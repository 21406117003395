import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { put } from 'redux-saga/effects'
import { ReduxHelper } from '../index'

export function* loadRentRollAnalysis(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                rentRollAnalysis(projectId: $projectId) {
                    averageLeaseTerm
                    termAnalysisPerUnit {
                        unitname
                        bedrooms
                        baths
                        units
                        averageTermLength
                        unitType
                    }
                    leaseExpirationSchedule {
                        bedrooms
                        units
                        month
                        unitname
                        unitType
                    }
                    newLeasesByMonth {
                        month
                        units
                    }
                    renovationStatus {
                        status
                        percent
                    }
                    leaseByTerm {
                        type
                        percent
                    }
                    marketRent
                    inPlaceRent
                    turnoverRateYoY
                    affordableHousingUnitsRatio
                    averageActualTenancyLength
                    leaseExpireNext6MonthsPercent
                    leaseExpireNext6Months
                    totalLeaseRent
                    totalSubsidyRent
                    subsidyRentRatio
                    renovationPremium {
                        classicPerSF
                        renovatedPerSF
                        rentByUnitType {
                            bedrooms
                            total
                            status
                            unitType
                        }
                    }
                }
            }
        `,
        { projectId: p.projectId },
    )
    yield put(ReduxHelper.setInAction(['operatingAssumptions', 'rentRollAnalysis'], response.data.rentRollAnalysis))
    return response.data.rentRollAnalysis
}

export function* loadRentRoll(p: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                rentRoll(projectId: $projectId) {
                    id
                    floorPlan
                    unitId
                    unitType
                    bedrooms
                    baths
                    size
                    leaseStart
                    leaseExpire
                    rentPrice
                    originalRentPrice
                    marketRent
                    subsidyRent
                    originalSubsidyRent
                    tenantPaidRent
                    overhang
                    status
                    moveIn
                    moveOut
                    occupied
                    tenant
                    vacancy
                    concession
                    originalConcession
                    rentPremium
                    originalRentPremium
                    rentModel
                    rentEmployee
                    tenantPaidRent
                    overhang
                }
            }
        `,
        { projectId: p.projectId },
    )
    yield put(ReduxHelper.setInAction(['operatingAssumptions', 'rentRoll'], response.data.rentRoll))
    return response.data.rentRoll
}
