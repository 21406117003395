import * as statistics from './sagas/statistics'
import * as projects from './sagas/projects'
import * as lenderProjects from './sagas/lenderProjects'
import * as project from './sagas/project'
import * as login from './sagas/login'
import * as account from './sagas/account'
import * as accounts from './sagas/accounts'
import * as fullProjectDealOutline from './sagas/fullProject/dealOutline'
import * as fullProjectMarketOverview from './sagas/fullProject/marketOverview'
import * as fullProjectSponsorCompanyInfo from './sagas/fullProject/sponsorCompanyInfo'
import * as fullProjectProformaUserInput from './sagas/fullProject/proformaUserInput'
import * as fullProjectZoning from './sagas/fullProject/zoning'
import * as fullProjectAreaInformation from './sagas/fullProject/areaInformation'
import * as fullProjectConstruction from './sagas/fullProject/construction'
import * as fullProjectPastTransactions from './sagas/fullProject/pastTransactions'
import * as fullProjectMortgageRecords from './sagas/fullProject/mortgageRecords'
import * as fullProjectDealHighlightsPartOne from './sagas/fullProject/dealHighlightsPartOne'
import * as fullProjectTheProperty from './sagas/fullProject/theProperty'
import * as fullProjectGridLocations from './sagas/fullProject/gridLocations'
import * as fullProjectPastPortfolio from './sagas/fullProject/pastPortfolio'
import * as fullProjectFileUpload from './sagas/fullProject/fileUpload'
import * as fullProjectLinks from './sagas/fullProject/links'
import * as fullProjectComparables from './sagas/fullProject/comparables'
import * as fullProjectMarketHouse from './sagas/fullProject/marketHouse'
import * as fullProjectEmploymentLocations from './sagas/fullProject/employmentLocations'
import * as fullProjectEmploymentCompanies from './sagas/fullProject/employmentCompanies'
import * as fullProjectPublish from './sagas/fullProject/publish'
import * as fullProjectSponsors from './sagas/fullProject/sponsors'
import * as fullProjectShortProforma from './sagas/fullProject/shortProforma'
import * as fullProjectDealHighlightsPartTwo from './sagas/fullProject/dealHighlightsPartTwo'
import * as fullProjectScrapSponsorInfo from './sagas/fullProject/scrapSponsorInfo'
import * as fullProjectPropertyHistory from './sagas/fullProject/propertyHistory'
import * as fullProjectRentComparables from './sagas/fullProject/rentComparables'
import * as fullProjectTransportation from './sagas/fullProject/transportation'
import * as fullProjectFinancials from './sagas/fullProject/financials'
import * as fullProjectSubMarketInsightsOM from './sagas/fullProject/subMarketInsights'
import * as fullProjectRenovationPlan from './sagas/fullProject/renovationPlan'
import * as fullProjectMixUseRetail from './sagas/fullProject/mixUseRetail'
import * as fullProjectManagementCompany from './sagas/fullProject/managementCompany'
import * as fullProjectSellerBackground from './sagas/fullProject/sellerBackground'
import * as fullProjectTaxRecord from './sagas/fullProject/taxRecord'
import * as fullProjectScrapStatus from './sagas/fullProject/scrapStatus'
import * as status from './sagas/fullProject/status'
import * as UI from './sagas/UI'
import * as dashboardSettings from './sagas/lender/dashboardSettings'
import * as lenderDashboard from './sagas/lender/lenderDashboard'
import * as lenderProjectSettings from './sagas/lender/lenderProjectSettings'
import * as lenderOriginationModel from './sagas/lender/originationModel'
import * as lenderLeaseUp from './sagas/lender/leaseUp'
import * as quote from './sagas/quote'
import * as adjustments from './sagas/adjustments'
import * as rentRoll from './sagas/rentRoll'
import * as quickAnalysis from '../components/pages/quick-analysis/quickAnalysisSaga'
import * as submarketInsights from '../components/pages/operating-assumptions/submarket-insights/submarketInsightsSaga'
import * as quickAnalysisBorrower from '../components/pages/quick-analysis-borrower/quickAnalysisBorrowerSaga'
import * as projectWizard from '../components/pages/project-wizard/projectWizardSaga'
import * as lenderGeneralInputs from './sagas/lender/generalInputs'
import * as lenderPricing from './sagas/lender/pricing'
import * as lenderFannieQuotes from './sagas/lender/fannieQuotes'
import { createRootSaga, createSagaHelper } from 'obrigado-redux-utils'
import { sagaMiddleware, store } from './index'
import * as notification from './sagas/notification'
const sagaModules = {
    statistics,
    projects,
    lenderProjects,
    project,
    login,
    account,
    accounts,
    fullProjectDealOutline,
    fullProjectMarketOverview,
    fullProjectSponsorCompanyInfo,
    fullProjectProformaUserInput,
    fullProjectZoning,
    fullProjectAreaInformation,
    fullProjectConstruction,
    fullProjectPastTransactions,
    fullProjectMortgageRecords,
    fullProjectDealHighlightsPartOne,
    fullProjectTheProperty,
    fullProjectGridLocations,
    fullProjectPastPortfolio,
    fullProjectFileUpload,
    fullProjectLinks,
    fullProjectComparables,
    fullProjectMarketHouse,
    fullProjectEmploymentLocations,
    fullProjectEmploymentCompanies,
    fullProjectPublish,
    fullProjectSponsors,
    fullProjectShortProforma,
    fullProjectDealHighlightsPartTwo,
    fullProjectScrapSponsorInfo,
    fullProjectPropertyHistory,
    fullProjectRentComparables,
    fullProjectTransportation,
    fullProjectFinancials,
    fullProjectSubMarketInsightsOM,
    fullProjectRenovationPlan,
    fullProjectMixUseRetail,
    fullProjectManagementCompany,
    fullProjectSellerBackground,
    fullProjectTaxRecord,
    fullProjectScrapStatus,
    status,
    notification,
    UI,
    dashboardSettings,
    lenderDashboard,
    lenderProjectSettings,
    lenderOriginationModel,
    quote,
    adjustments,
    rentRoll,
    quickAnalysis,
    submarketInsights,
    quickAnalysisBorrower,
    projectWizard,
    lenderGeneralInputs,
    lenderPricing,
    lenderFannieQuotes,
    lenderLeaseUp
}

const SagaHelperTmp = createSagaHelper(sagaModules, store)

//to switch back to old style, just move this line back to index.ts
//@ts-ignore
sagaMiddleware.run(createRootSaga(sagaModules))

export const SagaHelper = SagaHelperTmp
