import gql from 'graphql-tag'
import { graphQLRequest } from '../../graphQLClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { toBase64 } from '../../../utils'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                renovationPlanOM(projectId: $projectId) {
                    enabled
                    enabledBudget
                    abovePictureContent
                    belowPictureContent
                    imgs {
                        title
                        before
                        after
                    }
                }
            }
        `,
        { projectId: +params.projectId },
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'renovationPlan', 'data'], () => {
            return response?.data?.renovationPlanOM
        }),
    )
    return response?.data?.renovatonPlan || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'renovationPlan', 'form'], () => {
            return params.fields
        }),
    )
}

export function* setData(projectId: number) {
    const formdata = yield select((store: ReduxStoreState) => store.fullProject.renovationPlan.form)
    const status = 100
    //console.log('saveRenovationPlan formdata', formdata)
    const data = {
        enabled: formdata.enabled,
        enabledBudget: formdata.enabledBudget,
        abovePictureContent: formdata.abovePictureContent,
        belowPictureContent: formdata.belowPictureContent,
        imgs: [],
    }
    for (const img of formdata.imgs) {
        const t = img.title
        let nb = ''
        let na = ''
        let bb = null
        let ba = null
        let sb = true
        let sa = true
        if (img.before) {
            if (img.before.name) {
                bb = yield toBase64(img.before)
                nb = img.before.name
                sb = false
            } else if (typeof img.before === 'string' || img.before instanceof String) {
                nb = img.before
            }
        }

        if (img.after) {
            if (img.after.name) {
                ba = yield toBase64(img.after)
                na = img.after.name
                sa = false
            } else if (typeof img.after === 'string' || img.after instanceof String) {
                na = img.after
            }
        }
        data.imgs.push({
            title: t,
            before: {
                file_name: nb,
                body: bb,
                skip: sb,
            },
            after: {
                file_name: na,
                body: ba,
                skip: sa,
            },
        })
    }

    //console.log('formdata', formdata)
    //console.log('data', data)
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: RenovationPlanOMInput!, $status: Int!) {
                saveRenovationPlanOM(projectId: $projectId, data: $data, status: $status) {
                    enabled
                    abovePictureContent
                    belowPictureContent
                }
            }
        `,
        {
            status,
            projectId,
            data,
        },
    )

    return response.data.saveRenovationPlanOM
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'renovationPlan', 'data'], {
            enabled: true,
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'renovationPlan', 'form'], null))
}
