import { graphQLRequest } from '../../../store/graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../../store'

export function* matchFile(params: { projectId: number; fileId: number; data: any }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $data: [ExcelParserMatchingTableInputGraphQL!]!) {
                matchFile(projectId: $projectId, fileId: $fileId, data: $data) {
                    id
                    parentId
                    projectId
                    isParsed
                    fileName
                    filePath
                    type
                    year
                    isHistorical
                    t12AnnualizedType
                    fieldMapping {
                        sheets {
                            name
                            index
                            rowCount
                            columnCount
                        }
                        warnings
                        fields {
                            row
                            column
                            columnName
                            title
                            id
                            previews
                            extractBedroomsBathrooms
                            rentPriceHasTotal
                            rentPriceIsTenantPaidRent
                            extractRenovationStatus
                            dataIsGrouped
                            isTotal
                            isSubrow
                            useLeaseStartAsMoveIn
                            useLeaseExpireAsMoveOut
                            useMoveInAsLeaseStart
                            useMoveOutAsLeaseExpire
                            ignore
                        }
                        columns {
                            column
                            id
                            title
                        }
                        fillRequiredWithZeros
                        range {
                            sheet
                            top
                            left
                            right
                            bottom
                            titleIsDouble
                        }
                    }
                }
            }
        `,
        params,
    )

    yield put(ReduxHelper.setInAction(['lenderProjects', 'project', 'files'], response?.data?.matchFile))
    return response.data.matchFile
}
export function* changeFileType(p: { fileId: number; type: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($fileId: Int!, $type: String!) {
                changeFileType(fileId: $fileId, type: $type) {
                    range {
                        top
                        left
                        right
                        bottom
                        sheet
                    }
                    sheets {
                        index
                        name
                        rowCount
                        columnCount
                    }
                    fields {
                        title
                        column
                        columnName
                        row
                        id
                        previews
                        extractBedroomsBathrooms
                        extractRenovationStatus
                        dataIsGrouped
                        isTotal
                        isSubrow
                    }
                }
            }
        `,
        p,
    )
    const files = yield select((state: ReduxStoreState) => state.lenderProjects?.project?.files)
    const ind = files.findIndex((f) => f.id == p.fileId)
    yield put(
        ReduxHelper.setInAction(
            ['lenderProjects', 'project', 'files', ind, 'fieldMapping'],
            response?.data?.changeFileType,
        ),
    )
    return response?.data?.changeFileType
}
export function* getPdfPreview(p: { fileId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($fileId: Int!) {
                pdfPreview(fileId: $fileId) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.pdfPreview
}
export function* getMixedExcelTabs(p: { fileId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($fileId: Int!) {
                getMixedExcelTabs(fileId: $fileId) {
                    tabs {
                        name
                        type
                        isHistorical
                        year
                        label
                        index
                    }
                }
            }
        `,
        p,
    )
    return response.data.getMixedExcelTabs
}

export function* matchExcelTabs(params: { projectId: number; fileId: number; data: any }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $data: ExcelParserTabsInputGraphQL!) {
                matchExcelTabs(projectId: $projectId, fileId: $fileId, data: $data)
            }
        `,
        params,
    )
    return response.data.matchExcelTabs
}

export function* updatePDFBody(p: {
    projectId: number
    fileId: number
    tableIdx: number
    rowIdx: number
    colIdx: number
    value: string
}) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $tableIdx: Int!, $rowIdx: Int!, $colIdx: Int!, $value: String!) {
                updatePDFBody(
                    projectId: $projectId
                    fileId: $fileId
                    tableIdx: $tableIdx
                    rowIdx: $rowIdx
                    colIdx: $colIdx
                    value: $value
                ) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.updatePDFBody
}

export function* splitPDFTable(p: { projectId: number; fileId: number; destTableInd: number; row: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $destTableInd: Int!, $row: Int!) {
                splitPDFTable(projectId: $projectId, fileId: $fileId, destTableInd: $destTableInd, row: $row) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.splitPDFTable
}
export function* deletePDFRow(p: { projectId: number; fileId: number; destTableInd: number; row: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $destTableInd: Int!, $row: Int!) {
                deletePDFRow(projectId: $projectId, fileId: $fileId, destTableInd: $destTableInd, row: $row) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.deletePDFRow
}
export function* deletePDFCol(p: { projectId: number; fileId: number; destTableInd: number; col: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $destTableInd: Int!, $col: Int!) {
                deletePDFCol(projectId: $projectId, fileId: $fileId, destTableInd: $destTableInd, col: $col) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.deletePDFCol
}
export function* mergePdfTables(p: {
    projectId: number
    fileId: number
    destTableInd: number
    mergedTableInd: number
    remove1stLine: boolean
}) {
    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $fileId: Int!
                $destTableInd: Int!
                $mergedTableInd: Int!
                $remove1stLine: Boolean!
            ) {
                mergePdfTables(
                    projectId: $projectId
                    fileId: $fileId
                    destTableInd: $destTableInd
                    mergedTableInd: $mergedTableInd
                    remove1stLine: $remove1stLine
                ) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.mergePdfTables
}

export function* concatPdfTables(p: {
    projectId: number
    fileId: number
    destTableInd: number
    mergedTableInd: number
}) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $destTableInd: Int!, $mergedTableInd: Int!) {
                concatPdfTables(
                    projectId: $projectId
                    fileId: $fileId
                    destTableInd: $destTableInd
                    mergedTableInd: $mergedTableInd
                ) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.concatPdfTables
}

export function* moveTableDown(p: { projectId: number; fileId: number; tableInd: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $tableInd: Int!) {
                moveTableDown(projectId: $projectId, fileId: $fileId, tableInd: $tableInd) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.moveTableDown
}

export function* moveTableUp(p: { projectId: number; fileId: number; tableInd: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $tableInd: Int!) {
                moveTableUp(projectId: $projectId, fileId: $fileId, tableInd: $tableInd) {
                    columnCount
                    rowCount
                    body
                    page
                    originalPage
                }
            }
        `,
        p,
    )
    return response.data.moveTableUp
}
