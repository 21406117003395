import { put } from 'redux-saga/effects'
import { ReduxHelper } from '../index'
import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'

export function* loadFundingData(params: { projectId?: string | number; accountId?: number }) {
    //dashboardStatsProjectFunding
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsProjectFunding(projectId: $projectId, accountId: $accountId) {
                    fundingRequested
                    fundingSecured
                    types
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )
    yield put(ReduxHelper.mergeInAction(['statistics', 'funding'], response.data.dashboardStatsProjectFunding))
    return response.data
}
type VisitDataParams = {
    type: '24h' | 'month' | 'week' | 'forever'
    projectId?: string | number
    accountId?: number
}
export function* loadVisitData(params: VisitDataParams) {
    yield put(
        ReduxHelper.mergeInAction(['statistics', 'visitsCount'], {
            chartType: params.type,
            chartDataAll: [],
            chartDataProject: [],
            totalVisits: 0,
        }),
    )
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int, $type: String!) {
                dashboardStatsGetVisitsCount(projectId: $projectId, type: $type, accountId: $accountId) {
                    all {
                        date
                        sessionDuration
                        sessions
                    }
                    project {
                        date
                        sessionDuration
                        sessions
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
            type: params.type,
            accountId: +params.accountId,
        },
    )
    const data = response.data.dashboardStatsGetVisitsCount
    let totalVisits = 0
    for (const row of data.all) {
        totalVisits += parseInt(row.sessions)
    }
    yield put(
        ReduxHelper.mergeInAction(['statistics', 'visitsCount'], {
            chartType: params.type,
            chartDataAll: data.all,
            chartDataProject: data.project,
            totalVisits,
        }),
    )

    return data
}

type ViewingStatsParams = {
    projectId?: string | number
    accountId?: number
}
export function* loadViewingStats(params: ViewingStatsParams) {
    yield put(
        ReduxHelper.setInAction(['statistics', 'viewingStats'], {
            sessionDuration: 0,
            sectionsStats: [],
        }),
    )
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsViewingStatsData(projectId: $projectId, accountId: $accountId) {
                    sessionDuration
                    sectionStats {
                        name
                        value
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )

    const data = response.data.dashboardStatsViewingStatsData
    //console.log('data', data)
    yield put(
        ReduxHelper.setInAction(['statistics', 'viewingStats'], {
            sessionDuration: data.sessionDuration || 0,
            sectionsStats: data.sectionStats.filter((data) => data.value > 0) || [],
        }),
    )
    return data
}

export function* loadInvitationsStats(params: ViewingStatsParams) {
    yield put(
        ReduxHelper.setInAction(['statistics', 'invitations'], {
            total: 0,
            opened: 0,
        }),
    )
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsInvitations(projectId: $projectId, accountId: $accountId) {
                    opened
                    total
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )

    const data = response.data.dashboardStatsInvitations
    yield put(
        ReduxHelper.mergeInAction(['statistics', 'invitations'], {
            total: data.total || 0,
            opened: data.opened || 0,
        }),
    )
    return data
}
export function* loadInvestorAnswersStats(params: ViewingStatsParams) {
    yield put(
        ReduxHelper.setInAction(['statistics', 'investorQuestions'], {
            waiting: 0,
            asked: 0,
        }),
    )

    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsInvestorQuestions(projectId: $projectId, accountId: $accountId) {
                    asked
                    waiting
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )
    const data = response.data.dashboardStatsInvestorQuestions
    yield put(
        ReduxHelper.mergeInAction(['statistics', 'investorQuestions'], {
            ...data,
        }),
    )
    return data
}
export function* loadInvestorCommentsList(params: ViewingStatsParams) {
    yield put(ReduxHelper.setInAction(['statistics', 'investorCommentsList'], []))
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsInvestorComments(projectId: $projectId, accountId: $accountId) {
                    id
                    createdAt
                    text
                    userName
                    originalId
                    parentOriginalId
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )

    yield put(
        ReduxHelper.mergeInAction(['statistics', 'investorCommentsList'], response.data.dashboardStatsInvestorComments),
    )
    return response.data.dashboardStatsInvestorComments
}

export function* loadInvestorEngagement(params: ViewingStatsParams) {
    yield put(ReduxHelper.setInAction(['statistics', 'investorEngagement'], []))
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String, $accountId: Int) {
                dashboardStatsInvestorEngagement(projectId: $projectId, accountId: $accountId) {
                    name
                    sessionDuration
                    sessions
                }
            }
        `,
        {
            projectId: params.projectId,
            accountId: +params.accountId,
        },
    )
    const data = response.data.dashboardStatsInvestorEngagement || []
    yield put(ReduxHelper.mergeInAction(['statistics', 'investorEngagement'], data))
    return data
}
export function* loadProjects(params: { accountId: string | number | null }) {
    //localhost:8080/api/v1/list.json?accountid=1&_=1584532511984

    const response = yield graphQLRequest(
        gql`
            query ($accountId: Int) {
                dashboardProjectList(accountId: $accountId) {
                    id
                    isMulti
                    name
                }
            }
        `,
        {
            accountId: +params.accountId,
        },
    )
    yield put(ReduxHelper.setInAction(['statistics', 'projects'], response.data.dashboardProjectList))
    return response.data
}
