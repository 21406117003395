import { MixedSchema, Schema, TestContext, ValidationError } from 'yup'
import * as Yup from 'yup'
import { REG_EXP } from './constants/regExps'
export function yupValidateObject(schema: Schema<any>, object: any): Record<string, any> | null {
    try {
        schema.validateSync(object, { abortEarly: false })
    } catch (e) {
        if (e instanceof ValidationError) {
            // console.log('V E', e.inner)
            const errors: Record<string, any> = {}
            e.inner.forEach((err) => {
                putValueToPath(errors, err.path, err.message)
            })
            return errors
        } else {
            throw e
        }
    }
    return null
}
export function yupValidateObjectAsArray(schema: Schema<any>, object: any): ValidationError[] {
    const out = []
    try {
        schema.validateSync(object, { abortEarly: false })
    } catch (e) {
        if (e instanceof ValidationError) {
            e.inner.forEach((err) => {
                out.push(err)
            })
            return out
        } else {
            throw e
        }
    }
    return out
}
function putValueToPath(obj: Record<string, any>, path: string, value: any) {
    let pathPart = ''
    let remainingPath = ''
    if (path.includes('.')) {
        const matches = /([^.]*)\.(.*)/.exec(path)
        pathPart = matches[1]
        remainingPath = matches[2]
    } else {
        pathPart = path
    }
    if (pathPart.includes('[')) {
        const matches = /(.*)\[(\d+)\]/.exec(pathPart)
        if (!obj[matches[1]]) {
            obj[matches[1]] = []
            for (let i = 0; i < parseInt(matches[2]) + 1; i++) {
                obj[matches[1]].push(null)
            }
        } else if (obj[matches[1]].length < parseInt(matches[2])) {
            for (let i = 0; i < obj[matches[1]].length - parseInt(matches[2]) + 1; i++) {
                obj[matches[1]].push(null)
            }
        }
        if (!obj[matches[1]][parseInt(matches[2])]) obj[matches[1]][parseInt(matches[2])] = {}
        if (remainingPath) {
            putValueToPath(obj[matches[1]][parseInt(matches[2])], remainingPath, value)
        } else {
            obj[matches[1]][parseInt(matches[2])] = value
        }

        return
    }
    if (remainingPath) {
        obj[pathPart] = {}
        putValueToPath(obj[pathPart], remainingPath, value)
    } else {
        obj[pathPart] = value
    }
}

Yup.addMethod<MixedSchema<any>>(Yup.mixed, 'fileSize', function (args) {
    const message = `maximum allowed filesize is ${args} MB!`
    // @ts-ignore
    return this.test('fileSize', message, function (this: TestContext, file) {
        //  console.log('check', this, file, args)
        //  const { path, createError } = this
        const maxSize = args || 30
        // [value] - value of the property being teste
        // d
        // [path]  - property name,
        // ...
        if (!file || typeof file === 'string') return true
        if (file.size > maxSize * 1024 * 1024) {
            return false
        }
        return true
    })
})
Yup.addMethod<MixedSchema<any>>(Yup.mixed, 'fileExtCheck', function (args: Array<string> | 'file') {
    let allowedExtensions = ['jpeg', 'jpg', 'png', 'bmp', 'jfif', 'gif']
    if (Array.isArray(args)) {
        allowedExtensions.concat(args)
    }
    if (args == 'file') {
        allowedExtensions = [
            'jpg',
            'jpeg',
            'gif',
            'png',
            'pdf',
            'doc',
            'xls',
            'ppt',
            'pptx',
            'docx',
            'xlsx',
            'jfif',
            'xlsm',
        ]
    }

    const message = `You can't upload files of this type!`
    // @ts-ignore
    return this.test('fileSize', message, function (this: TestContext, file) {
        if (!file || typeof file === 'string') return true
        const regExp = new RegExp(`\.(${allowedExtensions.join('|')})$`)
        if (!regExp.exec(file.name?.toLowerCase())) {
            return false
        }
        return true
    })
})
Yup.addMethod<MixedSchema<any>>(Yup.mixed, 'isNumber', function () {
    return this.test('isNumber', 'Should be a Number', function (this: TestContext, value) {
        if (!value) return true
        return !isNaN(value)
    })
})
Yup.addMethod<MixedSchema<any>>(Yup.mixed, 'isDate', function () {
    return this.test('isDate', 'Wrong date format', function (this: TestContext, value) {
        if (!value) return true
        return REG_EXP.DATE.test(value)
    })
})
