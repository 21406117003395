import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import {
    Comparable,
    ComparableImage,
    ComparableRoundDataInputGraphQl,
    ComparableRoundRentCompsDataInputGraphQl,
    ComparableRoundSaleCompsDataInputGraphQl,
} from '../../../generated/graphql'
import { toBase64 } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { getAxios } from '../../axiosClient'

export function* getData(params: { projectId: number }) {
    if (params.projectId) {
        const response = yield graphQLRequest(
            gql`
                query ($projectId: Int!) {
                    getComparablesList(projectId: $projectId) {
                        id
                        projectid
                        name
                        address
                        imgname
                        fileName
                        yearlastsale
                        unitsaleprice
                        size
                        numofunits
                        unitrent
                        link
                        imgs {
                            id
                            img
                            width
                            title
                            fileName
                            footer
                        }
                        data {
                            mortgages {
                                date
                                maturityDate
                                documentType
                                mortgageType
                                refinance
                                constructionLoan
                                documentNumber
                                interestRate
                                interestType
                                term
                                sellerCarryBack
                                crossedDebtParcels
                                amount
                            }
                            taxes {
                                year
                                taxAmount
                                YoYChange
                                assessedLandValue
                                assessedValueTotal
                                assessedImprovementValue
                                appliedTaxRate
                                landMarketValue
                                improvementsMarketValue
                                totalMarketValue
                            }
                            historyVacancy {
                                year
                                value
                            }
                            historyRent {
                                year
                                value
                            }
                            unitMix {
                                bedrooms
                                bathrooms
                                units
                                avgAskingRent
                                currentAskingRent
                                avgUnitSizeSqft
                            }
                            building {
                                yearBuilt
                                yearRenovated
                                totalUnits
                                numberOfBuildings
                                buildingClass
                            }
                            lot {
                                lotAreaSF
                                lotAreaAcres
                            }
                            owner {
                                name
                                address
                                link
                                phone
                            }
                            location {
                                lat
                                lng
                            }
                        }
                        estimatedCapRate
                        isSaleComp
                        isRentComp
                    }
                }
            `,
            {
                projectId: +params.projectId,
            },
        )

        yield put(
            ReduxHelper.updateInAction(['fullProject', 'comparables', 'data'], () => response.data.getComparablesList),
        )
    }
}

export function* setFormData(params: any) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'comparables', 'form', 'comparables'], (data) => {
            return params
        }),
    )
}

export function* addComparable() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'comparables', 'data'], (comparables: Comparable[]) => {
            return [...comparables, {}]
        }),
    )
}

export function* addComparableImage(comparableIndex: number) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'comparables', 'data', comparableIndex, 'imgs'],
            (comparableImages) => {
                const newComparableImages = comparableImages ? comparableImages : []
                return [...newComparableImages, {}]
            },
        ),
    )
}

export function* setComparableImages(params: { comparableIndex: number; newComparableImgs: ComparableImage[] }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'comparables', 'data', params.comparableIndex, 'imgs'], () => {
            return params.newComparableImgs
        }),
    )
}

export function* setComparables(params: { newComparables: Comparable[] }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'comparables', 'data'], () => {
            return params.newComparables
        }),
    )
}

export function* setData() {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.comparables.form)

    for (const i in formData.comparables) {
        const comparable: Comparable = formData.comparables[i]
        formData.comparables[i].size = +comparable.size
        formData.comparables[i].numofunits = +comparable.numofunits
        formData.comparables[i].unitsaleprice = +comparable.unitsaleprice

        if (comparable.imgname && typeof comparable.imgname == 'object') {
            yield toBase64(comparable.imgname).then((response) => {
                // @ts-ignore
                comparable.newImg = {
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    file_name: comparable.imgname.name,
                    body: response,
                    skip: false,
                }
            })
            comparable.imgname = null
        }
        if (comparable.imgs) {
            for (const i in comparable.imgs) {
                const image: ComparableImage = comparable.imgs[i]
                image.width = +image.width
                if (image.img && typeof image.img == 'object') {
                    yield toBase64(image.img).then((response) => {
                        // @ts-ignore
                        image.newImg = {
                            // @ts-ignore
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            file_name: image.img.name,
                            body: response,
                            skip: false,
                        }
                    })
                    image.img = null
                }
            }
        }
    }

    const status = formData?.comparables?.length > 0 ? 100 : 0

    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $comparables: [ComparableInput!]!
                $deleteComparablesIds: [Int!]!
                $deleteComparableImagesIds: [Int!]!
                $status: Int!
            ) {
                setComparablesList(
                    projectId: $projectId
                    comparables: $comparables
                    deleteComparablesIds: $deleteComparablesIds
                    deleteComparableImagesIds: $deleteComparableImagesIds
                    status: $status
                ) {
                    id
                    projectid
                    name
                    address
                    imgname
                    yearlastsale
                    unitsaleprice
                    imgname
                    size
                    numofunits
                    unitrent
                    link
                    imgs {
                        id
                        img
                        width
                        title
                        footer
                    }
                }
            }
        `,
        {
            projectId: formData.projectId,
            comparables: formData.comparables,
            deleteComparablesIds: formData.deleteComparablesIds,
            deleteComparableImagesIds: formData.deleteComparableImagesIds,
            status,
        },
    )

    yield put(ReduxHelper.setInAction(['fullProject', 'status', STEP_ID.COMPARABLES], status.toString()))

    return response
}

export function* clearData() {
    yield put(ReduxHelper.updateInAction(['fullProject', 'comparables', 'data'], () => []))
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'comparables', 'form'], () => ({
            projectId: null,
            deleteComparablesIds: [],
            deleteComparableImagesIds: [],
            comparables: [],
        })),
    )
}
export function* scrapComparables(projectId: number) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapComparables(projectId: $projectId)
            }
        `,
        { projectId },
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], true))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapingStarted'], new Date()))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapProgress'], 0))
    const user = yield select((state: ReduxStoreState) => state.user)
    gtag('event', 'click_load_comps', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'dashboard',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: 'Click Load Comps',
        account_id: user.accountid,
        user_id: user.id,
        user_name: user.username,
        project_id: projectId,
    })
    return true
}
export function* cleanAndScrapComparables(projectId: number) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                cleanAndScrapComparables(projectId: $projectId)
            }
        `,
        { projectId },
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], true))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapingStarted'], new Date()))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapProgress'], 0))
    const user = yield select((state: ReduxStoreState) => state.user)
    gtag('event', 'click_clean_load_comps', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'dashboard',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: 'Click CLean and  Load Comps',
        account_id: user.accountid,
        user_id: user.id,
        user_name: user.username,
        project_id: projectId,
    })
    return true
}
export function* scrapComparablesV2(projectId: number) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapComparablesV2(projectId: $projectId)
            }
        `,
        { projectId },
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], true))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapingStarted'], new Date()))
    yield put(ReduxHelper.setInAction(['fullProject', 'scrapProgress'], 0))
    const user = yield select((state: ReduxStoreState) => state.user)
    gtag('event', 'click_load_comps', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'dashboard',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: 'Click Load Comps',
        account_id: user.accountid,
        user_id: user.id,
        user_name: user.username,
        project_id: projectId,
    })
    return true
}
export function* cancelComparableScraping(projectId: number) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                cancelScrapComparables(projectId: $projectId)
            }
        `,
        { projectId },
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], false))
}

export function* addDataItem(params: { comparableIndex: number; fieldName: string }) {
    const { comparableIndex, fieldName } = params
    yield put(
        ReduxHelper.updateInAction(
            // @ts-ignore
            ['fullProject', 'comparables', 'data', comparableIndex, 'data', fieldName],
            (items) => {
                return items ? [...items, {}] : [{}]
            },
        ),
    )
}

export function* setDataItems(params: { comparableIndex: number; fieldName: string; newItems: Array<any> }) {
    const { comparableIndex, fieldName, newItems } = params
    yield put(
        ReduxHelper.updateInAction(
            // @ts-ignore
            ['fullProject', 'comparables', 'data', comparableIndex, 'data', fieldName],
            () => {
                return newItems
            },
        ),
    )
}

export function* saveComparable(params: { comparableId: number; comparable: Comparable; projectId: number }) {
    const { comparableId, comparable, projectId } = params

    comparable.unitsaleprice = +comparable.unitsaleprice
    comparable.size = +comparable.size
    comparable.numofunits = +comparable.numofunits
    comparable.estimatedCapRate = comparable?.estimatedCapRate ? +comparable?.estimatedCapRate : 0

    // @ts-ignore
    if (comparable.newImg && comparable?.newImg?.name) {
        // @ts-ignore
        const fileName = comparable?.newImg?.name
        // @ts-ignore
        const body = yield toBase64(comparable?.newImg)
        // @ts-ignore
        comparable.newImg = {
            file_name: fileName,
            body,
            skip: false,
        }
    } else {
        // @ts-ignore
        delete comparable.newImg
    }

    if (comparable?.data) {
        if (comparable.data.building) {
            comparable.data.building.numberOfBuildings = +comparable.data.building.numberOfBuildings
            comparable.data.building.totalUnits = +comparable.data.building.totalUnits
            comparable.data.building.yearRenovated = +comparable.data.building.yearRenovated
            comparable.data.building.yearBuilt = +comparable.data.building.yearBuilt
        }
        if (comparable.data.historyRent && comparable.data.historyRent.length > 0) {
            comparable.data.historyRent.map((item) => {
                item.year = +item.year
                item.value = +item.value
            })
        }
        if (comparable.data.historyVacancy && comparable.data.historyVacancy.length > 0) {
            comparable.data.historyVacancy.map((item) => {
                item.year = +item.year
                item.value = +item.value
            })
        }

        if (comparable.data.location) {
            comparable.data.location.lat = +comparable.data.location.lat
            comparable.data.location.lng = +comparable.data.location.lng
        }

        if (comparable.data.lot) {
            comparable.data.lot.lotAreaSF = +comparable.data.lot.lotAreaSF
            comparable.data.lot.lotAreaAcres = +comparable.data.lot.lotAreaAcres
        }

        if (comparable.data.mortgages && comparable.data.mortgages.length > 0) {
            comparable.data.mortgages.map((item) => {
                item.interestRate = +item.interestRate
                item.term = +item.term
                item.crossedDebtParcels = +item.crossedDebtParcels
                item.amount = +item.amount
            })
        }

        if (comparable.data.taxes && comparable.data.taxes.length > 0) {
            comparable.data.taxes.map((item) => {
                item.year = +item.year
                item.taxAmount = +item.taxAmount
                item.YoYChange = +item.YoYChange
                item.assessedLandValue = +item.assessedLandValue
                item.assessedValueTotal = +item.assessedValueTotal
                item.assessedImprovementValue = +item.assessedImprovementValue
                item.appliedTaxRate = +item.appliedTaxRate
                item.landMarketValue = +item.landMarketValue
                item.improvementsMarketValue = +item.improvementsMarketValue
                item.totalMarketValue = +item.totalMarketValue
            })
        }

        if (comparable.data.unitMix && comparable.data.unitMix.length > 0) {
            comparable.data.unitMix.map((item) => {
                item.bedrooms = +item.bedrooms
                item.bathrooms = +item.bathrooms
                item.units = +item.units
                item.avgAskingRent = +item.avgAskingRent
                item.currentAskingRent = +item.currentAskingRent
            })
        }
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($comparableId: Int, $comparable: ComparableInput!, $projectId: Int) {
                saveComparable(comparableId: $comparableId, comparable: $comparable, projectId: $projectId)
            }
        `,
        {
            comparableId,
            comparable,
            projectId,
        },
    )

    return response?.data?.saveComparable
}

export function* deleteComparable(params: { comparableId: number }) {
    const { comparableId } = params

    if (comparableId) {
        const response = yield graphQLRequest(
            gql`
                mutation ($comparableId: Int!) {
                    deleteComparable(comparableId: $comparableId)
                }
            `,
            {
                comparableId,
            },
        )

        return response?.data?.deleteComparable
    }

    return false
}
export function* fillComparableData(param: { projectId: number }) {
    return yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                fillMissingComparableData(projectId: $projectId)
            }
        `,
        { projectId: param.projectId },
    )
}

export function* saveRoundsData(params: {
    projectId: number
    rentComps: ComparableRoundRentCompsDataInputGraphQl[]
    saleComps: ComparableRoundSaleCompsDataInputGraphQl[]
}) {
    const { projectId, rentComps, saleComps } = params

    if (rentComps)
        rentComps.map((item) => {
            item.distance = +item.distance
            item.rentRange = +item.rentRange
            item.sizeRange = +item.sizeRange
            item.unitRange = +item.unitRange
        })
    if (saleComps)
        saleComps.map((item) => {
            item.distance = +item.distance
            item.capRateLow = +item.capRateLow
            item.capRateHigh = +item.capRateHigh
            item.unitMix = +item.unitMix
            item.rentRange = +item.rentRange
            item.unitSalePriceRange = +item.unitSalePriceRange
        })

    const rounds = {
        rentComps,
        saleComps,
    }
    //console.log(rounds, 'rounds')

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $rounds: ComparableRoundDataInputGraphQL!) {
                saveRoundData(projectId: $projectId, rounds: $rounds)
            }
        `,
        {
            projectId: projectId,
            rounds: rounds,
        },
    )
    return response.data.saveRoundData
}

export function* getRoundData(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getRoundData(projectId: $projectId) {
                    rentComps {
                        distance
                        sizeRange
                        rentRange
                        unitRange
                        ignoreSubmarket
                        ignoreUnitMix
                    }
                    saleComps {
                        distance
                        capRateLow
                        capRateHigh
                        unitMix
                        rentRange
                        unitSalePriceRange
                        ignoreEstimatedGoingInCap
                        minSaleYear
                    }
                }
            }
        `,
        { projectId },
    )
    return response.data.getRoundData
}
