import { graphQLRequest } from '../../../../store/graphQLClient'
import gql from 'graphql-tag'
import { put } from 'redux-saga/effects'
import { ReduxHelper } from '../../../../store'
import { SagaHelper } from '../../../../store/sagaHelper'

export function* loadSubmarketInsights(p: { projectId: number }) {
    const submarketInsights = yield graphQLRequest(
        gql`
            fragment submarketDistribution on AskingRentDistributionSubmarketGraphQL {
                distribution {
                    value
                    low
                    high
                }
                low
                high
                mean
                median
                p25
                p75
            }
            fragment compsDistribution on DistributionCompsGraphQL {
                compsAverage
                property
                comps {
                    address
                    name
                    value
                }
            }
            fragment highlight on Highlight {
                quarter
                year
                value
                full
            }
            fragment insightDataSet on InsightDataSet {
                quarter
                year
                value
            }
            fragment unitMixItem on UnitMixItem {
                averageRent
                averageSize
                averageRentPerSf
            }
            fragment performanceByPropertyAgeItem on PerformanceByPropertyAgeItem {
                mean
                similarlyAged
                median
            }
            fragment performanceGrowthItem on PerformanceGrowthItem {
                year
                quarter
                title
                askingRent
                vacancyRate
                inventory
            }
            fragment absorptionDataSet on AbsorptionDataSet {
                startQuarter
                endQuarter
                startYear
                endYear
                value
            }
            query ($projectId: Int!) {
                subMarketInsights(propjectId: $projectId) {
                    submarketName
                    submarketInfo {
                        additionsNumber
                        additionsStart
                        additionsPct
                        inventoryPct
                        inventoryUnits
                        description
                        zipGeom {
                            lat
                            lng
                        }
                        propertyLocation {
                            lat
                            lng
                        }
                        comparables {
                            data {
                                location {
                                    lat
                                    lng
                                }
                            }
                            isRentComp
                            isSaleComp
                        }
                    }
                    comparison {
                        submarketName
                        trends {
                            year
                            propertyRent
                            propertyVacancy
                            submarketRent
                            submarketVacancy
                            compsRent
                            compsVacancy
                        }
                        askingRentDistributionSubmarket {
                            ...submarketDistribution
                        }
                        askingRentDistributionComps {
                            ...compsDistribution
                        }
                        vacancyDistributionSubmarket {
                            ...submarketDistribution
                        }
                        vacancyDistributionComps {
                            ...compsDistribution
                        }
                    }
                    performance {
                        year
                        quater
                        askingRent
                        askingRentChange
                        vacancyRate
                        vacancyRateChange
                        effectiveRent
                        effectiveRentChange
                    }
                    economic {
                        stories {
                            title
                            description
                        }
                        highlights {
                            avgVacancyRate {
                                ...highlight
                            }
                            effectiveRentPerUnit {
                                ...highlight
                            }
                            householdIncomeGrowth {
                                ...highlight
                            }
                            jobGrowth {
                                ...highlight
                            }
                        }
                    }
                    multiFamilyInsights {
                        effectiveRent {
                            ...insightDataSet
                        }
                        askingRent {
                            ...insightDataSet
                            trend
                        }
                        vacancy {
                            ...insightDataSet
                        }
                        totalInventory {
                            ...insightDataSet
                        }
                        avgAnnualAbsorptionRate {
                            ...absorptionDataSet
                        }
                        annualNetAbsorption {
                            ...absorptionDataSet
                        }
                        stories {
                            title
                            description
                        }
                    }
                    unitMixDetails {
                        subject {
                            oneBedroom {
                                ...unitMixItem
                            }
                            twoBedroom {
                                ...unitMixItem
                            }
                            threeBedroom {
                                ...unitMixItem
                            }
                            studio {
                                ...unitMixItem
                            }
                        }
                        market {
                            oneBedroom {
                                ...unitMixItem
                            }
                            twoBedroom {
                                ...unitMixItem
                            }
                            threeBedroom {
                                ...unitMixItem
                            }
                            studio {
                                ...unitMixItem
                            }
                        }
                        rentComps {
                            oneBedroom {
                                ...unitMixItem
                            }
                            twoBedroom {
                                ...unitMixItem
                            }
                            threeBedroom {
                                ...unitMixItem
                            }
                            studio {
                                ...unitMixItem
                            }
                        }
                    }
                    
                    performanceByPropertyAge {
                        market {
                            askingRent {
                                ...performanceByPropertyAgeItem
                            }
                            vacancyRent {
                                ...performanceByPropertyAgeItem
                            }
                            inventory {
                                ...performanceByPropertyAgeItem
                            }
                        }
                        submarket {
                            askingRent {
                                ...performanceByPropertyAgeItem
                            }
                            vacancyRent {
                                ...performanceByPropertyAgeItem
                            }
                            inventory {
                                ...performanceByPropertyAgeItem
                            }
                        }
                    }
                    performanceGrowth {
                        market {
                            quarterly {
                                ...performanceGrowthItem
                            }
                            annualized {
                                ...performanceGrowthItem
                            }
                            yearly {
                                ...performanceGrowthItem
                            }
                        }
                        submarket {
                            quarterly {
                                ...performanceGrowthItem
                            }
                            annualized {
                                ...performanceGrowthItem
                            }
                            yearly {
                                ...performanceGrowthItem
                            }
                        }
                    }
                }
            }
        `,
        p,
    )

    SagaHelper.run(['submarketInsights', 'loadSubmarketPopulation'], { projectId: p.projectId }).catch((e) => {
        console.log('error loadSubmarketPopulation', e)
    })

    if (!!submarketInsights?.data?.subMarketInsights) {
        yield put(
            ReduxHelper.updateInAction(
                ['operatingAssumptions', 'submarketInsights'],
                () => submarketInsights.data.subMarketInsights,
            ),
        )
    }

    return submarketInsights?.data?.subMarketInsights
}

export function* loadSubmarketPopulation(p: { projectId: number }) {
    const populationInsights = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                subMarketInsights(propjectId: $projectId) {
                    economic {
                        populationGrowth {
                            year
                            value
                        }
                    }
                }
            }
        `,
        p,
    )

    if (!!populationInsights?.data?.subMarketInsights) {
        yield put(
            ReduxHelper.updateInAction(
                ['operatingAssumptions', 'submarketInsights', 'economic', 'populationGrowth'],
                () => populationInsights?.data?.subMarketInsights?.economic?.populationGrowth || null,
            ),
        )
    }

    return populationInsights?.data?.subMarketInsights?.economic?.populationGrowth
}
