import { getAxios } from '../axiosClient'
import { API } from '../../utils/constants/api'
import { ROUTE } from '../../utils/constants/routes'
import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { ReduxHelper } from '../index'
import { response } from 'express'
import { put } from '@redux-saga/core/effects'

export type LoginParams = {
    username: string
    password: string
    smscode: string
}

export function* login(params: LoginParams) {
    const out: any = {
        message: '',
        type: '',
        flow: '',
    }
    try {
        const response = yield getAxios().post(API.LOGIN, params)
        //console.log('in response', response)
        if (response.data.code == 200) {
            localStorage.setItem('username', params.username)
            localStorage.setItem('accountId', response.data.accountid)
            localStorage.setItem('userId', response.data.id)
            localStorage.setItem('accountLogoUrl', response.data.accountLogoUrl)
            localStorage.setItem('accountName', response.data.accountName)
            localStorage.setItem('flow', response.data.flow)
            localStorage.setItem('accountType', response.data.accountType)

            gtag('event', 'dashboard_login', {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_category: 'dashboard',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_label: 'User login',
                // eslint-disable-next-line @typescript-eslint/camelcase
                user_id: response.data.id,
                // eslint-disable-next-line @typescript-eslint/camelcase
                user_name: params.username,
                // eslint-disable-next-line @typescript-eslint/camelcase
                non_interaction: true,
                // eslint-disable-next-line @typescript-eslint/camelcase
                transport_type: 'beacon',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_callback: function (e) {
                    // console.log(e)
                },
            })
            out.type = 'info'
            out.message = `Howdy, ${params.username}!`
            out.flow = response?.data?.flow
            out.sendMFACode = response?.data?.sendMFACode
            // @ts-ignore
        } else {
            out.type = 'error'
            out.message = `Somehing went wrong!`
        }
    } catch (e) {
        out.type = 'error'
        out.message = e.message
    }

    return out
}
export function* loadProfile() {
    const response = yield graphQLRequest(
        gql`
            query {
                myProfile {
                    id
                    username
                    accountid
                    isAdmin
                    flow
                    allowDealNarrative
                }
            }
        `,
        {},
    )
    const user = response.data.myProfile
    localStorage.setItem('username', user.username)
    localStorage.setItem('accountId', user.accountid)
    localStorage.setItem('userId', user.id)
    yield put(ReduxHelper.setInAction(['user'], response.data.myProfile))
}
export function* guestLogin(params: { email: string; password: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($email: String!, $password: String!) {
                guestLogin(email: $email, password: $password)
            }
        `,
        params,
    )
    return response.data.guestLogin
}

export function* sendMFASMS() {
    let message: string
    //let type: string
    let code: number
    try {
        const postParams = {
            action: 'sendcode',
            accountId: localStorage.getItem('username'),
            userId: localStorage.getItem('userId'),
        }
        //  const response = yield getAxios().post(API.SMSMFA, postParams)
        //console.log('mfa response', response.data)

        //code = response.data.status
        //message = response.data.message
    } catch (e) {
        code = 500
        message = e.message
    }

    return {
        code,
        message,
    }
}
