import { ReduxBuilder } from 'obrigado-redux-utils'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createRootSaga } from 'obrigado-redux-utils'
import { InvestorComment, InvestorEngagement, Project } from './types'
import {
    DealOutlineLoan,
    PastTransaction,
    SponsorCompany,
    MortgageRecord,
    DealHighlightsDetail,
    ThePropertyUnit,
    ThePropertyAmenity,
    GridLocation,
    PastPortfolioPortfolio,
    PastPortfolioPrincipals,
    PastPortfolioCompany,
    UploadedFile,
    EmploymentLocation,
    EmploymentCompany,
    Sponsor,
    ProjectLink,
} from './types/fullproject'

import { STEP_ID } from '../utils/constants/projectStepIds'
import {
    CashFlowGraphQl,
    Comparable,
    Construction,
    LenderDashboardSetting,
    LenderProjectSettings,
    ManagementCompany,
    MarketAreaInfo,
    ProjectGraphQl,
    ProjectT12HistoryGraphQl,
    PropertyHistory,
    QuoteGraphQl,
    RentComparablePropertyItem,
    SellersBackground,
    TaxRecord,
    Transportation,
    Financials,
    RenovationPlanOm,
    MixUseRetail,
    UnitMix,
    RentRollStatsGraphQl,
    SubmarketInsightsGraphQl,
    RetailUnit,
    UserSettings,
    RentRollRecord,
    SubMarketInsightsOm,
    GeneralInputs,
    Pricing,
    FannieQuotes,
    LeaseUpGraphQl,
    LeaseUpSubmarketDataGraphQl
} from '../generated/graphql'
import { OriginationModelRedux } from './types/OriginationModelRedux'
import { Notification } from './types/notification'

const data = {
    env: {},
    minisite: {
        sideMenu: [] as any[],
        account: {} as any,
        propertyEnrichments: {} as any,
        proforma: {} as any,
        dealOutline: {} as any,
        property: {} as any,
        comparables: {} as any,
        taxRecords: [] as any[],
        projectId: 0,
    },
    statistics: {
        selectedAccountId: 0,
        selectedProjectId: '' as number | string,
        projects: [] as Array<any>,
        funding: {
            fundingRequested: 0,
            fundingSecured: 0,
            types: [],
        },
        visitsCount: {
            chartDataAll: [],
            chartDataProject: [],
            totalVisits: 0,
            chartType: 'week',
        },
        viewingStats: {
            sessionDuration: 0,
            sectionsStats: [],
        },
        invitations: {
            opened: 0,
            total: 0,
        },
        investorQuestions: {
            waiting: 0,
            asked: 0,
        },
        investorCommentsList: [] as InvestorComment[],
        investorEngagement: [] as InvestorEngagement[],
    },
    projects: {
        list: [] as Array<Project>,
    },
    lenderProjects: {
        list: [] as Array<ProjectGraphQl>,
        project: null as ProjectGraphQl | null,
        fieldMapping: {
            rentRoll: [],
            cashFlow: [],
            proforma: [],
            cashFlowColumns: [],
            proformaColumns: [],
        },
        hasGeneralInputChanged: {
            property: false,
            deal: false,
            loan: false,
        },
        hasT12Rescan: false,
    },
    projectWizard: {
        userAgreement: false,
        showGenerateQAPreloader: false,
        showGenerateOMPreloader: false,
        uploadFileStep: 'list' as 'list' | 'upload',
        showPdfDetailsModal: false,
        showMixedExcelDetailsModal: false,
        fileIsUploaded: false,
        pdfExtracted: 0,
        t12rematchedRows: [],
    },
    project: {
        code: 0,
        projectId: '',
        status: '',
    },
    fullProject: {
        id: 0,
        name: '',
        isBeingScraped: false,
        isCompsOnly: false,
        scrapingStarted: null as Date,
        scrapProgress: 0,
        status: {
            [`${STEP_ID.DEAL_OUTLINE}`]: '0',
            [`${STEP_ID.THE_PROPERTY}`]: '0',
            [`${STEP_ID.MARKET_OVERVIEW}`]: '0',
            [`${STEP_ID.SPONSORS}`]: '0',
            [`${STEP_ID.PAST_PROTFOLIO}`]: '0',
            [`${STEP_ID.SPONSOR_COMPANY_INFO}`]: '0',
            [`${STEP_ID.PRO_FORMA_USER_INPUT}`]: '0',
            [`${STEP_ID.SHORT_PROFORMA}`]: '0',
            [`${STEP_ID.DEAL_HIGHLIGHTS_I}`]: '0',
            [`${STEP_ID.DEAL_HIGHLIGHTS_II}`]: '0',
            [`${STEP_ID.FILES_UPLOAD}`]: '0',
            [`${STEP_ID.LINKS}`]: '0',
            [`${STEP_ID.MARKET_HOUSE}`]: '0',
            [`${STEP_ID.COMPARABLES}`]: '0',
            [`${STEP_ID.TRANSPORTATION}`]: '0',
            [`${STEP_ID.FINANCIALS}`]: '0',
            [`${STEP_ID.RENOVATION_PLAN}`]: '0',
            [`${STEP_ID.MANAGEMENT_COMPANY}`]: '0',
            [`${STEP_ID.SELLER_BACKGROUND}`]: '0',
            [`${STEP_ID.ZONING}`]: '0',
            [`${STEP_ID.AREA_INFORMATION}`]: '0',
            [`${STEP_ID.CONSTRUCTION}`]: '0',
            [`${STEP_ID.PROPERTY_HISTORY}`]: '0',
            [`${STEP_ID.RENT_COMPARABLES}`]: '0',
            [`${STEP_ID.PAST_TRANSACTIONS}`]: '0',
            [`${STEP_ID.MORTGAGE_RECORDS}`]: '0',
            [`${STEP_ID.GRID_LOCATIONS}`]: '0',
            [`${STEP_ID.EMPLOYMENT_LOCATIONS}`]: '0',
            [`${STEP_ID.EMPLOYMENT_COMPANIES}`]: '0',
        },
        dealOutline: {
            data: {
                address: '',
                status_pct: '',
                blurb: '',
                loans: [] as DealOutlineLoan[],
                financingRequirementsChartVisible: '0',
                financingRequirementsChartType: 'pie',
            },
            form: {
                address: '',
                status_pct: '',
                blurb: '',
                loans: [] as DealOutlineLoan[],
            },
        },
        marketOverview: {
            data: {
                blurb: '',
                explanation: '',
                mapimg: null,
                locationname: '',
                description: '',
            },
            form: null,
        },
        sponsorsCompanyInfo: {
            data: {
                companies: [] as SponsorCompany[],
            },
            form: null,
        },
        proformaUserInput: {
            data: {
                gross_revenue: '',
                avg_rent_sqft: '',
                avg_cost_sqft: '',
                totalcost: '',
                avg_sale_sqft: '',
                totalsale: '',
                dont_calc_avg_values: '0',
                displayImagesAtMinisite: '0',
                estimation_title: '',
            },
            form: null,
        },
        zoning: {
            data: {
                current: '',
                isopertunityzone: '',
                usespermited: '',
                minlotsize: '',
                frontsize: '',
                interiorsize: '',
                rearsize: '',
                maxheight: '',
                parkingreq: '',
                parking: '',
            },
            form: null,
        },
        areaInformation: {
            data: { marketAreas: [] as MarketAreaInfo[], enabled: false },
            form: null,
        },
        taxRecords: {
            data: [] as TaxRecord[],
            taxDataVisible: false,
        },
        pastTransactions: {
            data: {
                transactions: [] as PastTransaction[],
                pastTransactionsChartVisible: '0',
                pastTransactionsChartType: 'linear',
            },
            form: null,
        },
        mortgageRecords: {
            data: {
                mortgagerecords: [] as MortgageRecord[],
                mortgageRecordsChartVisible: '0',
                mortgageRecordsChartType: 'linear',
            },
            form: null,
        },
        dealHighlightsPartOne: {
            initialDetails: null,
            initialLoans: null,
            initialTablesShort: null,
            initialTablesLong: null,
            data: {
                id: '',
                default: '',
                terms: '',
                cashoncash: '',
                irr: null,
                equitymulti: null,
                extraPageEnabled: '0',
                details: {
                    loan: [] as string[],
                    detials: [] as DealHighlightsDetail[],
                },
                content: '',
            },
            form: null,
        },
        dealHighlightsPartTwo: {
            initialDetails: null,
            initialLoans: null,
            data: {
                details: '',
                default: '',
            },
            shortTables: [],
            longTables: [],
            defaultShortTables: [],
            defaultLongTables: [],
            initialShortTables: [],
            initialLongTables: [],
            form: [],
            projectId: 0,
            isDefault: false,
        },
        theProperty: {
            data: {
                id: '',
                description: '',
                floorplanImgName: '',
                floorplanImgUrl: '',
                floorplanzoomimg: '',
                unitmixs: [] as ThePropertyUnit[],
                externalImgs: [],
                amenties: [] as ThePropertyAmenity[],
                pictures: [],
                unitMixChartVisible: '0',
                unitMixChartType: 'pie',
            },
            form: null,
        },
        gridLocations: {
            data: {
                id: '',
                gridlocations: [] as GridLocation[],
            },
            form: null,
        },
        pastPortfolio: {
            data: {
                id: '',
                portfolios: [] as PastPortfolioPortfolio[],
                companies: [] as PastPortfolioCompany[],
                principals: [] as PastPortfolioPrincipals[],
            },
            form: {
                portfolios: [] as PastPortfolioPortfolio[],
                deleteprotfolios: [],
            },
        },
        filesUpload: {
            data: {
                files: [] as UploadedFile[],
            },
            form: null,
        },
        links: {
            data: [] as ProjectLink[],
            accountLinks: [] as ProjectLink[],
            form: null,
        },
        marketHouse: {
            data: null,
            form: null,
        },
        construction: {
            data: null as Construction | null,
            form: null,
        },
        propertyHistory: {
            data: {
                enabled: false,
                historicalOccupancyPerformance: [],
                historicalRentPerformance: [],
                noi: 0,
                perSqFtMonthlyRentalRates: [],
                perUnitMonthlyRentalRates: [],
                propertyClass: '',
            } as PropertyHistory,
            form: null,
        },
        rentComparables: {
            data: {
                effectiveRent: 0,
                effectiveRentPerSqFt: 0,
                enabled: true,
                floorPlanComparables: [],
                marketRent: 0,
                marketRentPerSqFt: 0,
                marketTrend: 0,
                propertyTrend: 0,
                propertyComparables: [] as RentComparablePropertyItem[],
            },
            form: null,
        },
        comparables: {
            data: [] as Comparable[] | null,
            form: {
                projectId: null as number,
                deleteComparablesIds: [] as any[],
                deleteComparableImagesIds: [] as any[],
                comparables: [] as any[],
            },
        },
        transportation: {
            data: {
                bikeScore: 0,
                enabled: true,
                locations: {
                    banks: [],
                    coffee: [],
                    fitness: [],
                    groceries: [],
                    restaurants: [],
                    schools: [],
                    shops: [],
                },
                soundScore: {
                    airport: '',
                    business: '',
                    points: 0,
                    traffic: '',
                },
                transitScore: 0,
                walkScore: 0,
            } as Transportation,
            form: null,
        },
        financials: {
            data: {
                enabled: false,
            } as Financials,
            form: null,
        },
        subMarketInsights: {
            data: {
                enabled: false,
            } as SubMarketInsightsOm,
            form: null,
        },
        renovationPlan: {
            data: {
                enabled: true,
            } as RenovationPlanOm,
            form: null,
            imgs: [],
        },
        mixUseRetail: {
            data: {
                enabled: true,
            } as MixUseRetail,
            form: null,
            imgs: [],
        },
        managementCompany: {
            data: {
                advantages: [],
                description: '',
                enabled: false,
                feedbacks: [],
                satisfaction: [],
                score: 0,
                title: '',
            } as ManagementCompany,
            form: null,
        },
        sellerBackground: {
            data: {
                enabled: false,
                address: '',
                content: '',
                email: '',
                link: '',
                phone: '',
                title: '',
            } as SellersBackground,
            form: null,
        },
        employmentLocations: {
            data: {
                employmentlocations: [] as EmploymentLocation[],
            },
            form: null,
        },
        employmentCompanies: {
            data: {
                employmentCompanies: [] as EmploymentCompany[],
            },
            form: null,
        },
        publish: {
            projectId: '',
        },
        sponsors: {
            data: {
                companies: [] as Sponsor[],
            },
            form: null,
            sponsorsList: [] as Sponsor[],
        },
        shortProforma: {
            isDefault: false,
            data: {
                proforma: '',
                default: '',
            },
            form: null,
        },
    },
    UI: {
        displayProgress: false,
    },
    notification: {
        show: false,
        message: '',
        type: 'info',
        lastNotification: null as Notification | null,
    },
    user: {
        id: 0,
        isAdmin: false,
        username: '',
        accountid: 0,
        flow: '',
        accountType: null as any,
        allowDealNarrative: false,
    },
    accounts: {
        list: [],
    },
    account: {
        user: {
            settings: null as UserSettings,
        },
    },
    lender: {
        alternameCashFlows: {} as any,
        settings: null as LenderDashboardSetting,
        projectSettings: null as LenderProjectSettings,
        originationModel: null as OriginationModelRedux,
        proforma: [] as CashFlowGraphQl[],
        originationModelDefault: null as OriginationModelRedux,
        quoteData: null as QuoteGraphQl[],
        unitMix: [] as UnitMix[],
        unitMixRetail: [] as RetailUnit[],
        exitAssumption: {} as any,
        isModelChanged: false,
        t12History: [] as ProjectT12HistoryGraphQl[],
        loanSizer: { valuationCapRate: 0 },
        borrowerDebt: {
            show: false,
            sources: {} as any,
            debtAssumptionSenior: {} as any,
            debtAssumptionMez: {} as any,
            valuation: 0,
            noi: 0,
        },
        generalInputs: null as GeneralInputs,
        pricing: null as Pricing,
        fannieQuotes: null as FannieQuotes,
        isPricingLoaded: false,
        isFannieQuotesLoaded: false,
        leaseUp: {
            data: null as LeaseUpGraphQl,
            submarketData: null as LeaseUpSubmarketDataGraphQl,
            futureRentRoll: null as any
        }
    },

    lenderDashboard: {
        expensesTab: {
            activeYear: { label: '1-2', years: [1, 2] },
        },
        lenderCashFlowTab: {
            columnsSelected: ['underwriting', 't12'],
            rowsExpanded: {} as Record<string, boolean>,
            rowIdHover: null as string,
            selectedRowIds: [],
            unitTitle: '$/Unit/Month',
            percentTitle: '%GPR',
            showPercentage: false,
            showComments: false,
            showScrollbar: false,
        },
    },
    operatingAssumptions: {
        saveCashFlow: false,
        saveUnitMix: false,
        saveRentRoll: false,
        adjustments: {
            showComments: false,
            showPCT: false,
            columnsSelected: ['underwriting', 't12'],
            useCollectionsAsNRI: false,
            useCollectionsAsNRIEconomicValue: 0,
            useCollectionsAsNRISelectedValue: '',
            useCollectionsAsNRIType: 'manual',
            collections: {
                expandT12Internal: false,
                showT12WithSmooth: false,
            },
        },
        historicalTab: {
            t12analysis: {
                showComments: false,
                showMarking: false,
                resetAdjustments: false,
                t12Annualized: 0,
                comments: {},
            },
            income: {
                tableColumnsSelected: ['t12'],
                chartPeriodSelected: { value: 't12', type: 'month' },
            },
            expenses: {
                expensesTrends: [],
                expensesEnabled: [],
                expansesTableSort: {
                    maxVal: 0,
                    count: 0,
                },
            },
            collection: {
                showSmoothAanalysis: false,
                showComments: false,
                columnsSelected: ['t12'],
                comments: {},
            },
        },
        rentRollAnalysis: null as RentRollStatsGraphQl,
        rentRoll: null as RentRollRecord[],
        rentRollTab: {
            rentRollSettings: {
                showComments: false,
            },
            comments: {},
        },
        taxTab: {
            settings: {
                showComments: false,
                columnsSelected: [],
                showTaxAbatementScenario: false,
                showLeadingQuestions: false,
            },
            deletedRecordsIds: [],
            comments: {
                text: '',
            },
        },
        submarketInsights: null as SubmarketInsightsGraphQl,
    },
}

export type ReduxStoreState = typeof data

export const builder = new ReduxBuilder(data)
const generatedReducers = builder.createReducers()
// @ts-ignore
const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})
export const sagaMiddleware = createSagaMiddleware()
const rootReducer = combineReducers({ ...generatedReducers })

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

export const ReduxHelper = builder.createReduxHelper(store)
