import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { SellersBackgroundFile } from '../../../generated/graphql'
import { toBase64 } from '../../../utils'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                sellersBackground(projectId: $projectId) {
                    enabled
                    title
                    content
                    address
                    email
                    phone
                    link
                    files {
                        filename
                        path
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    if (!response?.data?.sellersBackground?.files || response?.data?.sellersBackground?.files?.length == 0) {
        response.data.sellersBackground.files = [{}]
    }

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'sellerBackground', 'data'], () => {
            return response?.data?.sellersBackground
        }),
    )

    return response?.data?.sellersBackground
}

export function* addFile() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'sellerBackground', 'data', 'files'], (files) => {
            return [...files, {}]
        }),
    )
}

export function* setFiles(params: { files: Array<SellersBackgroundFile> }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'sellerBackground', 'data', 'files'], () => {
            return params.files
        }),
    )
}

export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'sellerBackground', 'form'], () => {
            return params.fields
        }),
    )
}
export function* setData() {
    const data = yield select((store: ReduxStoreState) => store.fullProject.sellerBackground.form)
    const projectId = data.projectId
    const enabled = data.enabled
    const status = 100
    delete data.projectId

    if (data.files) {
        for (const file of data.files) {
            if (file?.path?.name) {
                file.filename = file.path.name
                file.path = yield toBase64(file.path)
            }
        }
    } else {
        data.files = []
    }

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $status: Int!, $data: SellersBackgroundInput!, $enabled: Boolean!) {
                saveSellersBackground(projectId: $projectId, data: $data, enabled: $enabled, status: $status)
            }
        `,
        {
            projectId,
            status,
            enabled,
            data,
        },
    )

    return response
}
export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'sellerBackground', 'data'], {
            enabled: false,
            address: '',
            content: '',
            email: '',
            link: '',
            phone: '',
            title: '',
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'sellerBackground', 'form'], null))
}
