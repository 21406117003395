class Storage {
    data = null

    constructor() {
        this.data = new Map()
    }

    key(n) {
        return [...this.data.keys()][n]
    }
    getItem(key) {
        return this.data.get(key)
    }
    get length() {
        return this.data.size
    }

    setItem(key, value) {
        this.data.set(key, value)
    }
    removeItem(key) {
        this.data.delete(key)
    }
    clear() {
        this.data = new Map()
    }
}

let myStorage = (globalThis.myStorage = globalThis.myStorage ?? new Storage())

export { Storage, myStorage }
