import gql from 'graphql-tag'
import { graphQLRequest } from '../../graphQLClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                transportation(projectId: $projectId) {
                    enabled
                    walkScore
                    transitScore
                    bikeScore
                    aboveMapContent
                    belowMapContent
                    soundScore {
                        points
                        airport
                        traffic
                        business
                    }
                    locations {
                        schools {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        restaurants {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        groceries {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        coffee {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        banks {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        shops {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        fitness {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                    }
                }
            }
        `,
        { projectId: +params.projectId },
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'transportation', 'data'], () => {
            return response?.data?.transportation
        }),
    )

    return response?.data?.getConstructionData || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'transportation', 'form'], () => {
            return params.fields
        }),
    )
}

export function* setData(projectId: number) {
    const data = yield select((store: ReduxStoreState) => store.fullProject.transportation.form)
    const status = 100

    //console.log('data', data)

    data.walkScore = +data.walkScore
    data.transitScore = +data.transitScore
    data.bikeScore = +data.bikeScore
    data.soundScore.points = +data.soundScore.points

    data.locations = {
        schools: [],
        restaurants: [],
        groceries: [],
        coffee: [],
        banks: [],
        shops: [],
        fitness: [],
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: TransportationInput!, $status: Int!) {
                saveTransportation(projectId: $projectId, data: $data, status: $status) {
                    enabled
                    walkScore
                    transitScore
                    bikeScore
                    aboveMapContent
                    belowMapContent
                    soundScore {
                        points
                        airport
                        traffic
                        business
                    }
                    locations {
                        schools {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        restaurants {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        groceries {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        coffee {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        banks {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        shops {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        fitness {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                    }
                }
            }
        `,
        {
            status,
            projectId,
            data,
        },
    )

    return response.data.saveTransportation
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'transportation', 'data'], {
            bikeScore: 0,
            enabled: true,
            locations: {
                banks: [],
                coffee: [],
                fitness: [],
                groceries: [],
                restaurants: [],
                schools: [],
                shops: [],
            },
            soundScore: {
                airport: '',
                business: '',
                points: 0,
                traffic: '',
            },
            transitScore: 0,
            walkScore: 0,
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'transportation', 'form'], null))
}
export function* scrapTransportation(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapTransportation(projectId: $projectId) {
                    enabled
                    walkScore
                    transitScore
                    bikeScore
                    soundScore {
                        points
                        airport
                        traffic
                        business
                    }
                    locations {
                        schools {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        restaurants {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        groceries {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        coffee {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        banks {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        shops {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                        fitness {
                            title
                            location {
                                lat
                                lng
                            }
                        }
                    }
                }
            }
        `,
        { projectId: +params.projectId },
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'transportation', 'data'], () => {
            return response?.data?.scrapTransportation
        }),
    )

    return response?.data?.scrapTransportation || []
}
