import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { calculateProjectStepStatus, makeRandomId, objectToFormData } from '../../../utils'
import { PastPortfolioPortfolio } from '../../types/fullproject'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.PAST_PORTFOLIO

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        let portfolios: Array<PastPortfolioPortfolio> = []
        let companies: Array<any> = []
        let principals: Array<any> = []
        if (response?.data?.project?.companies) {
            for (let i in response?.data?.project.companies) {
                portfolios = [...portfolios, ...response?.data?.project?.companies?.[i]?.protfolio]
                companies.push({
                    label: response?.data?.project.companies[i].name,
                    value: response?.data?.project.companies[i].id,
                })

                let currentPrincipals = response?.data?.project.companies[i].principals
                if (currentPrincipals) {
                    for (let k in currentPrincipals) {
                        principals.push({
                            label: currentPrincipals[k].name,
                            value: currentPrincipals[k].id,
                            company_id: response?.data?.project.companies[i].id,
                        })
                    }
                }
            }

            if (portfolios?.length === 0) {
                portfolios.push({
                    id: '',
                    name: '',
                    location: '',
                    link: '',
                    description: '',
                    unitprice: '',
                    numofunits: '',
                    purchaseprice: '',
                    developedby: '',
                    connectedtocopany: '',
                    connectedtoprincipal: '',
                    imgname: '',
                    img: '',
                })
            }

            yield put(
                ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'data', 'portfolios'], () => {
                    return []
                }),
            )
            yield put(
                ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'data', 'portfolios'], () => portfolios),
            )
            yield put(
                ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'data', 'principals'], () => principals),
            )
            yield put(
                ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'data', 'companies'], () => companies),
            )
        }
        return response.data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'form'], () => params))
}

export function* addPastPortfolio() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'pastPortfolio', 'data', 'portfolios'],
            (units: Array<PastPortfolioPortfolio>) => {
                return [
                    ...units,
                    {
                        id: '',
                        name: '',
                        location: '',
                        link: '',
                        description: '',
                        unitprice: '',
                        numofunits: '',
                        purchaseprice: '',
                        developedby: '',
                        connectedtocopany: '',
                        connectedtoprincipal: '',
                        imgname: '',
                        img: '',
                    },
                ]
            },
        ),
    )
}

export function* setPastPortfolios(params: Array<PastPortfolioPortfolio>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'pastPortfolio', 'data', 'portfolios'], () => params))
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.pastPortfolio.form
    const data = yield select(token)

    let status = calculateProjectStepStatus(data, {
        protfolios: 'array',
    })

    data.status = status

    const preparedData: any = {
        propertyDetails: {},
    }

    for (const i in data.protfolios) {
        const fileField = data?.protfolios?.[i]?.protfolioimg
        if (typeof fileField?.name == 'string') {
            const randomId = makeRandomId()
            // @ts-ignore
            preparedData[randomId] = fileField
            data.protfolios[i].protfolioimg = randomId
        }

        data.protfolios[i].connectedtocopany = 'null'
    }
    data.deleteprotfolios = data.deleteprotfolios.join(',')
    preparedData.propertyDetails = JSON.stringify(data)
    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.PAST_PROTFOLIO], () => status))
    return response.data
}
