import { getAxios } from '../axiosClient'
import { put, select } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../index'
import { Project } from '../types'
import { showErrorNotification } from '../../components/pages/fullproject/notifications'
import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'

type CreateProjectParams = {
    projectName: string
    accountId: string | null
    image: File | null
    type: string
}

export function* createProject(params: CreateProjectParams) {
    const formData = new FormData()

    formData.append('projectName', params.projectName)
    formData.append('accountId', params.accountId)
    if (params.image) {
        formData.append('projectType', params.type)
    }
    formData.append('projectName', params.projectName)
    const url = '/api/v1/new.project.json'
    let error = null
    const response = yield getAxios()
        .post(url, formData)
        .catch((e) => {
            error = e.message
        })

    if (error) {
        console.log(error)
        yield showErrorNotification(error)
        return null
    }
    yield put(ReduxHelper.mergeInAction(['project'], response.data))
    const user = yield select((state: ReduxStoreState) => state.user)
    if (user.flow != 'comps_only') {
        gtag('event', 'project_created', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_category: 'dashboard',
            event_label: 'Project created',
            account_id: user.accountid,
            user_id: user.id,
            user_name: user.username,
            project_id: response.data.projectId,
        })
    } else {
        gtag('event', 'project_comps_only_created', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_category: 'dashboard',
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_label: 'Created comps only project',
            account_id: user.accountid,
            user_id: user.id,
            user_name: user.username,
            project_id: response.data.projectId,
        })
    }
    return response.data
}

type RenameProjectParams = {
    name: string
    projectId: number
    image: File
    action: string
}

export function* renameProject(params: RenameProjectParams) {
    const url = '/api/v1/project.json'
    const formData = new FormData()

    formData.append('name', params.name)
    formData.append('projectId', `${params.projectId}`)
    formData.append('action', params.action)
    if (params.image) {
        formData.append('image', params.image)
    }
    const response = yield getAxios().post(url, formData)

    if (response.data.code == 200) {
        yield put(
            ReduxHelper.updateInAction(['projects', 'list'], (projects: Array<Project>) => {
                return projects.map((project: Project) => {
                    if (params.projectId === project.id) {
                        project.name = params.name
                        project.img_url = response.data.img_url
                    }
                    return { ...project }
                })
            }),
        )
    }

    return response
}

export function* getProjectStatus(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Float!) {
                projectStatus(projectId: $projectId)
            }
        `,
        {
            projectId: params.projectId,
        },
    )
    yield put(ReduxHelper.setInAction(['project', 'status'], response?.data?.projectStatus || ''))
    return response?.data?.projectStatus
}

export function* clearProjectStatus() {
    yield put(ReduxHelper.setInAction(['project', 'status'], ''))
}

export function* recalcCashFlowSaga(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                recalcCashFlow(projectId: $projectId)
            }
        `,
        {
            projectId: params.projectId,
        },
    )
    return response?.data?.recalcCashFlow
}
