import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { calculateProjectStepStatus, makeRandomId, objectToFormData } from '../../../utils'
import { UploadedFile } from '../../types/fullproject'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.FILES_UPLOAD

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        yield put(ReduxHelper.mergeInAction(['fullProject', 'filesUpload', 'data'], response?.data?.files))
        return response.data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'filesUpload', 'form'], () => params))
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.filesUpload.form
    const data = yield select(token)

    let status = calculateProjectStepStatus(data, {
        files: 'array',
    })

    for (let i in data.files) {
        let file = data.files[i].file
        if (typeof file?.name === 'string') {
            const randomId = makeRandomId()
            const today = new Date()
            let fileObject = {
                projectId: data.projectId,
                status,
                files: [
                    {
                        title: data.files[i].title,
                        description: data.files[i].description,
                        fileformparamname: randomId,
                        date: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
                    },
                ],
            }

            let fileRequest = {
                files: JSON.stringify(fileObject),
            }
            // @ts-ignore
            fileRequest[randomId] = file
            getAxios().post(API_URL, objectToFormData(fileRequest))
        }
    }

    const deleteObject = {
        files: JSON.stringify({
            projectId: data.projectId,
            delete: data.delete,
        }),
    }

    const response = yield getAxios().post(API_URL, objectToFormData(deleteObject))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.FILES_UPLOAD], () => status))
    return response.data
}

export function* addFile(params: any) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'filesUpload', 'data', 'files'], (files: Array<UploadedFile>) => {
            return [
                ...files,
                {
                    id: '',
                    tagline: '',
                    description: '',
                    filename: params || '',
                    filedate: '',
                    fileurl: '',
                },
            ]
        }),
    )
}

export function* setFiles(params: Array<UploadedFile>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'filesUpload', 'data', 'files'], () => params))
}
