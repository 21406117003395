import { graphQLRequest } from '../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../index'
import { toBase64 } from '../../utils'
import {
    LendersProjectFilter,
    ExcelParserRangeInputGraphQl,
    UnitMix,
    ExcelParserFieldGraphQl,
    UnitMixInput,
    RentRollRecordInput,
} from '@generated/graphql'

export function* loadProjects(params: { accountId: number; filters?: LendersProjectFilter; saveToRedux?: boolean }) {
    const { filters, accountId, saveToRedux } = params
    if (!accountId) return []
    const response = yield graphQLRequest(
        gql`
            query ($accountId: Int!, $filters: ProjectListFilterInputGraphQL) {
                getProjects(accountId: $accountId, filters: $filters) {
                    id
                    accountid
                    name
                    address
                    filePath
                    fileName
                    status
                    isProjectDataFilled
                    accountType
                    notesUrl
                    hasQuickAnalysis
                    isPublic
                    type
                    defaultNarrativeId
                    useFanniePricing
                    originalProjectId
                    lastModified
                    generalInputs {
                        loanDetails {
                            requestedLoanAmount
                            acquisitionOrRefinance
                            ARMType
                            supplemental
                        }
                        team {
                            producer
                            analyst
                            productionManager
                        }
                    }
                }
            }
        `,
        {
            accountId,
            filters,
        },
    )

    if (saveToRedux) {
        yield put(ReduxHelper.setInAction(['lenderProjects', 'list'], response?.data?.getProjects || []))
    }

    return response?.data?.getProjects || []
}

export function* createProject(params: { accountId: number; name: string; type: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($accountId: Int!, $name: String!, $type: String!) {
                createProject(accountId: $accountId, name: $name, type: $type)
            }
        `,
        {
            accountId: params.accountId,
            name: params.name,
            type: params.type,
        },
    )

    return response?.data?.createProject
}

export function* getProjectInfo(params: { projectId: number }) {
    if (params.projectId === undefined || params.projectId == null) {
        return { files: [] }
    }
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                project(projectId: $projectId) {
                    id
                    name
                    createDate
                    type
                    address
                    fileName
                    filePath
                    status
                    hashId
                    accountType
                    accountName
                    notesUrl
                    hasUnitMix
                    hasGeneralInputs
                    hasOriginationModel
                    extraFileTypes
                    useFanniePricing
                    defaultNarrativeId
                    originalProjectId
                    files {
                        id
                        parentId
                        projectId
                        isParsed
                        fileName
                        filePath
                        type
                        year
                        t12AnnualizedType
                        isHistorical
                        label
                        address
                        fieldMapping {
                            sheets {
                                name
                                index
                                rowCount
                                columnCount
                            }
                            warnings
                            fields {
                                row
                                column
                                columnName
                                title
                                id
                                previews
                                rawData
                                extractBedroomsBathrooms
                                rentPriceHasTotal
                                rentPriceIsTenantPaidRent
                                extractRenovationStatus
                                dataIsGrouped
                                isTotal
                                isSubrow
                                autoMatchSubRow
                                numOFSubRows
                                section
                                useLeaseStartAsMoveIn
                                useLeaseExpireAsMoveOut
                                useMoveInAsLeaseStart
                                useMoveOutAsLeaseExpire
                                ignore
                            }
                            columns {
                                column
                                id
                                title
                            }
                            fillRequiredWithZeros
                            hasFullT12Data
                            range {
                                sheet
                                top
                                left
                                right
                                bottom
                                titleIsDouble
                                titleInMultiCols
                            }
                        }
                    }
                    webReviews {
                        url
                        name
                        vote
                        score
                    }
                    isDataScraped
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    const data = response?.data?.project

    if (!data?.files) {
        data.files = []
    }

    yield put(ReduxHelper.setInAction(['lenderProjects', 'project'], data))

    return data
}

export function* clearProjectInfo() {
    yield put(ReduxHelper.setInAction(['lenderProjects', 'project'], null))
}

export function* setProjectAddress(params: { address: string; projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $address: String!) {
                setProjectAddress(projectId: $projectId, address: $address)
            }
        `,
        {
            ...params,
        },
    )
    return response?.data?.setProjectAddress || false
}

export function* lenderProjectSaveFile(params: {
    file: File
    fileType: string
    projectId: number
    isHistorical: boolean
    year: string | number
    t12AnnualizedType: number
    address: string
    label?: string
}) {
    const { file, fileType, projectId, isHistorical, year, t12AnnualizedType, address, label } = params
    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $file: Upload!
                $isHistorical: Boolean
                $year: Int
                $t12AnnualizedType: Int
                $type: String!
                $address: String!
                $label: String!
            ) {
                addProjectFile(
                    projectId: $projectId
                    file: $file
                    isHistorical: $isHistorical
                    year: $year
                    t12AnnualizedType: $t12AnnualizedType
                    type: $type
                    address: $address
                    label: $label
                )
            }
        `,
        {
            projectId: projectId,
            isHistorical,
            year: +year,
            t12AnnualizedType: t12AnnualizedType,
            file,
            type: fileType,
            address: address,
            label: label,
        },
    )

    return response.data.setProjectFile
}

export function* tryDifferentPDFParserAlg(params: { projectId: number; fileId: number; attempt: number }) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $fileId: Int!, $attempt: Int!) {
                tryDifferentPDFParserAlg(projectId: $projectId, fileId: $fileId, attempt: $attempt)
            }
        `,
        {
            projectId: params.projectId,
            fileId: params.fileId,
            attempt: params.attempt,
        },
    )
}

export function* lenderProjectDeleteFile(params: { projectId: number; deleteFilesIds: number[] }) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $deleteFilesIds: [Int!]!) {
                deleteProjectFile(projectId: $projectId, deleteFilesIds: $deleteFilesIds)
            }
        `,
        {
            projectId: params.projectId,
            deleteFilesIds: params.deleteFilesIds,
        },
    )
}

export function* addFile() {
    yield put(
        ReduxHelper.updateInAction(['lenderProjects', 'project', 'files'], (files: Array<any>) => {
            return [...files, {}]
        }),
    )
}

export function* setFiles(params: { newFiles: Array<any> }) {
    yield put(ReduxHelper.updateInAction(['lenderProjects', 'project', 'files'], () => params.newFiles))
}

export function* deleteProject(params: { id: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                deleteLenderProject(projectId: $projectId)
            }
        `,
        {
            projectId: params.id,
        },
    )

    yield put(
        ReduxHelper.updateInAction(['lenderProjects', 'list'], (projects) => {
            return projects?.filter((item) => item.id !== params.id)
        }),
    )

    return !!response?.data?.deleteLenderProject
}

export function* renameProject(params: { id: number; name: string; image?: any; isPublic: boolean }) {
    const data = {
        projectId: params.id,
        name: params.name,
        image: null,
        isPublic: params.isPublic,
    }

    if (params?.image && params?.image?.name) {
        data.image = {
            file_name: params.image.name,
            body: yield toBase64(params.image),
            skip: false,
        }
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $name: String!, $image: FileInput, $isPublic: Boolean!) {
                setProjectName(projectId: $projectId, name: $name, image: $image, isPublic: $isPublic)
            }
        `,
        data,
    )

    yield put(
        ReduxHelper.updateInAction(['lenderProjects', 'list'], (projects) => {
            return projects.map((project) => {
                if (params.id === project.id) {
                    project.name = params.name
                    project.isPublic = params.isPublic
                    project.filePath = response?.data?.setProjectName
                }
                return { ...project }
            })
        }),
    )

    return response?.data?.setProjectName
}

export function* changeProjectStatus(projectId: number | string, status: string) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $status: String!) {
                changeProjectStatus(projectId: $projectId, status: $status)
            }
        `,
        { projectId: parseInt(projectId as string), status },
    )

    const projects = yield select((state: ReduxStoreState) => state.lenderProjects.list)
    let pos
    for (const ind in projects) {
        if (projects[ind].id == projectId) {
            pos = ind
            break
        }
    }
    yield put(
        ReduxHelper.updateInAction(['lenderProjects', 'list', pos], (project) => {
            project.status = response?.data?.changeProjectStatus || ''
            return { ...project }
        }),
    )
}

export function* parseExcelFile(params: { fileId: number; range: ExcelParserRangeInputGraphQl }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($fileId: Int!, $range: ExcelParserRangeInputGraphQL) {
                parseExcelFile(fileId: $fileId, range: $range) {
                    range {
                        top
                        left
                        right
                        bottom
                        sheet
                        titleInMultiCols
                    }
                    sheets {
                        name
                        rowCount
                        columnCount
                        index
                    }
                    columns {
                        id
                        title
                        column
                        rawData
                        previews
                    }
                    fields {
                        row
                        column
                        columnName
                        title
                        id
                        previews
                        rawData
                        extractBedroomsBathrooms
                        rentPriceHasTotal
                        rentPriceIsTenantPaidRent
                        extractRenovationStatus
                        dataIsGrouped
                        isTotal
                        isSubrow
                        autoMatchSubRow
                        numOFSubRows
                        section
                        useLeaseStartAsMoveIn
                        useLeaseExpireAsMoveOut
                        useMoveInAsLeaseStart
                        useMoveOutAsLeaseExpire
                        ignore
                    }
                }
            }
        `,
        params,
    )
    return response.data.parseExcelFile
}

export function* saveFieldMapping(params: { fileId: number; fieldMapping: any }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($fileId: Int!, $fieldMapping: ExcelParserFieldMappingInputGraphQL!) {
                saveFieldMapping(fileId: $fileId, fieldMapping: $fieldMapping)
            }
        `,
        params,
    )
    return response.data.saveFieldMapping
}

export function* getDefaultNarrativeId(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query getDefaultNarrativeId($projectId: Int!) {
                getDefaultNarrativeId(projectId: $projectId)
            }
        `,
        params,
    )
    return response.data.getDefaultNarrativeId
}

export function* getTeamMembersForProject(params: { projectId: number; accountId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!, $accountId: Int!) {
                getTeamMembersForProject(projectId: $projectId, accountId: $accountId) {
                    userId
                    name
                    isOwner
                    hasProjectPermission
                    isAccountAdmin
                }
            }
        `,
        params,
    )
    return response?.data?.getTeamMembersForProject || []
}

export function* projectSetAllowedUsers(params: { projectId: number; allowedUsers: number[] }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $allowedUsers: [Int!]!) {
                projectSetAllowedUsers(projectId: $projectId, allowedUsers: $allowedUsers)
            }
        `,
        params,
    )
    return response?.data?.projectSetAllowedUsers || false
}

export function* projectSetOwner(params: { projectId: number; newOwner: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $newOwner: Int!) {
                projectSetOwner(projectId: $projectId, newOwner: $newOwner)
            }
        `,
        params,
    )
    return response?.data?.projectSetOwner || false
}

export function* saveProjectVersion(params: { projectId: number; versionName: string; versionDescription: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $versionName: String!, $versionDescription: String!) {
                saveVersion(projectId: $projectId, versionName: $versionName, versionDescription: $versionDescription)
            }
        `,
        params,
    )
    return response?.data?.saveVersion || false
}

export function* copyProject(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                copyProject(projectId: $projectId)
            }
        `,
        params,
    )
    return response?.data?.copyProject
}

export function* getProjectVersions(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getProjectVersions(projectId: $projectId) {
                    versionId
                    isMainVersion
                    name
                    description
                    date
                    userName
                    isCopy
                }
            }
        `,
        params,
    )
    return response?.data?.getProjectVersions || []
}

export function* loadVersion(params: {
    currentProjectId: number
    projectIdToLoad: number
    nameForCopy: string
    descriptionForCopy: string
    versionDate: string
}) {
    const response = yield graphQLRequest(
        gql`
            mutation (
                $currentProjectId: Int!
                $projectIdToLoad: Int!
                $nameForCopy: String!
                $descriptionForCopy: String!
                $versionDate: String!
            ) {
                loadVersion(
                    currentProjectId: $currentProjectId
                    projectIdToLoad: $projectIdToLoad
                    nameForCopy: $nameForCopy
                    descriptionForCopy: $descriptionForCopy
                    versionDate: $versionDate
                )
            }
        `,
        params,
    )
    return response?.data?.loadVersion || false
}

export function* saveNarrativeVersion(params: {
    projectId: number
    narrativeId: number
    versionName: string
    versionDescription: string
}) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $narrativeId: Int!, $versionName: String!, $versionDescription: String!) {
                saveNarrativeVersion(
                    projectId: $projectId
                    narrativeId: $narrativeId
                    versionName: $versionName
                    versionDescription: $versionDescription
                )
            }
        `,
        params,
    )
    return response?.data?.saveNarrativeVersion || null
}

export function* getNarrativeVersions(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getNarrativeVersions(projectId: $projectId) {
                    versionId
                    isMainVersion
                    name
                    description
                    date
                    userName
                }
            }
        `,
        params,
    )
    return response?.data?.getNarrativeVersions || []
}

export function* loadNarrativeVersion(params: {
    projectId: number
    currentNarrativeId: number
    narrativeIdToLoad: number
    nameForCopy: string
    descriptionForCopy: string
    dateForCopy: string
    defaultNarrativeId: number
}) {
    const response = yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $currentNarrativeId: Int!
                $narrativeIdToLoad: Int!
                $nameForCopy: String!
                $descriptionForCopy: String!
                $dateForCopy: String!
                $defaultNarrativeId: Int!
            ) {
                loadNarrativeVersion(
                    projectId: $projectId
                    currentNarrativeId: $currentNarrativeId
                    narrativeIdToLoad: $narrativeIdToLoad
                    nameForCopy: $nameForCopy
                    descriptionForCopy: $descriptionForCopy
                    dateForCopy: $dateForCopy
                    defaultNarrativeId: $defaultNarrativeId
                )
            }
        `,
        params,
    )
    return response?.data?.loadNarrativeVersion || false
}

export function* generateModel(params: {
    projectId: number
    useProformaAsBaseLine: boolean
    importUnitMix: boolean
    importRentRoll: boolean
    regenerateModel: boolean
    overModelwithGeneralInputsProperty: boolean
    overModelwithGeneralInputsLoan: boolean
    overModelwithGeneralInputsDeal: boolean
    resetAdjustments: boolean
    t12rematchedRows?: string[]
    t12Annualized?: number
}) {
    if (params.useProformaAsBaseLine === undefined) {
        params.useProformaAsBaseLine = false
    }
    if (params.regenerateModel === undefined) {
        params.regenerateModel = false
    }
    if (params.overModelwithGeneralInputsProperty === undefined) {
        params.overModelwithGeneralInputsProperty = false
    }
    if (params.overModelwithGeneralInputsDeal === undefined) {
        params.overModelwithGeneralInputsDeal = false
    }
    if (params.overModelwithGeneralInputsLoan === undefined) {
        params.overModelwithGeneralInputsLoan = false
    }
    if (params.resetAdjustments === undefined) {
        params.resetAdjustments = false
    }
    if (params.t12rematchedRows === undefined) {
        params.t12rematchedRows = []
    }

    yield graphQLRequest(
        gql`
            mutation (
                $projectId: Int!
                $useProformaAsBaseLine: Boolean!
                $importUnitMix: Boolean
                $importRentRoll: Boolean
                $regenerateModel: Boolean
                $overModelwithGeneralInputsProperty: Boolean
                $overModelwithGeneralInputsLoan: Boolean
                $overModelwithGeneralInputsDeal: Boolean
                $resetAdjustments: Boolean
                $t12rematchedRows: [String!]
                $t12Annualized: Int
            ) {
                generateOriginationModel(
                    projectId: $projectId
                    useProformaAsBaseLine: $useProformaAsBaseLine
                    importUnitMix: $importUnitMix
                    importRentRoll: $importRentRoll
                    regenerateModel: $regenerateModel
                    overModelwithGeneralInputsProperty: $overModelwithGeneralInputsProperty
                    overModelwithGeneralInputsLoan: $overModelwithGeneralInputsLoan
                    overModelwithGeneralInputsDeal: $overModelwithGeneralInputsDeal
                    resetAdjustments: $resetAdjustments
                    t12rematchedRows: $t12rematchedRows
                    t12Annualized: $t12Annualized
                )
            }
        `,
        params,
    )
}

export function* loadFileMappingOptions(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                fieldMappingReference(projectId: $projectId) {
                    type
                    options {
                        id
                        label
                        required
                        unique
                        mergeRows
                        group
                        isCategory
                        parent
                        section
                        defaultChild
                    }
                    columnOptions {
                        id
                        label
                        required
                        unique
                    }
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )
    const data = response.data.fieldMappingReference.reduce((acc, item) => {
        acc[item.type] = item.options
        acc[item.type + 'Columns'] = item.columnOptions
        return acc
    }, {})
    yield put(ReduxHelper.setInAction(['lenderProjects', 'fieldMapping'], data))
}

export function* getUnitMix(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getUnitMixList(projectId: $projectId) {
                    id
                    projectid
                    numofunits
                    floorPlan
                    bedrooms
                    rentprice
                    baths
                    unitsize
                    status
                    unitsToRenovate
                    stabilizedRentPrice
                    originalrentprice
                    unitType
                    overhang
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    const unitMix = response?.data?.getUnitMixList || []
    yield put(ReduxHelper.setInAction(['lender', 'unitMix'], unitMix))
    return unitMix
}

export function* setUnitMix(params: { projectId: number; unitMix: Array<UnitMixInput> }) {
    if (params?.unitMix) {
        for (const unit of params?.unitMix) {
            if (unit?.unitsToRenovate > unit?.numofunits) {
                unit.unitsToRenovate = unit?.numofunits
            }
        }
    }

    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $unitMix: [UnitMixInput!]!) {
                setUnitMixList(projectId: $projectId, unitMix: $unitMix)
            }
        `,
        {
            projectId: params.projectId,
            unitMix: params.unitMix,
        },
    )
    return !!response?.data?.setUnitMixList
}
export function* setRentRoll(params: { projectId: number; rentRoll: Array<RentRollRecordInput> }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $rentRoll: [RentRollRecordInput!]!) {
                setRentRollList(projectId: $projectId, rentRoll: $rentRoll)
            }
        `,
        {
            projectId: params.projectId,
            rentRoll: params.rentRoll,
        },
    )
    return !!response?.data?.setUnitMixList
}

export function* getUnitMixRetail(params: { projectId: number }) {
    //console.log('getUnitMixRetail', params)
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String!) {
                getUnitMixRetailList(projectId: $projectId) {
                    unitMixRetail {
                        id
                        unitType
                        floorPlan
                        tenant
                        unitsize
                        rentprice
                        originalrentprice
                        stabilizedRentPrice
                        leaseStart
                        leaseEnd
                        leaseTerm
                        rentStart
                        status
                        recordType
                    }
                }
            }
        `,
        {
            projectId: '' + params.projectId,
        },
    )
    const unitMix = response?.data?.getUnitMixRetailList || []
    yield put(ReduxHelper.setInAction(['lender', 'unitMixRetail'], unitMix))
    return unitMix
}
export function* getExitAssumption(params: { projectId: number }) {
    //console.log('getUnitMixRetail', params)
    const response = yield graphQLRequest(
        gql`
            query ($projectId: String!) {
                getExitAssumption(projectId: $projectId) {
                    startAnalysisDate
                    saleYearAnalysisPeriod
                    saleDate
                }
            }
        `,
        {
            projectId: '' + params.projectId,
        },
    )
    const exitAssumption = response?.data?.getExitAssumption || {}
    yield put(ReduxHelper.setInAction(['lender', 'exitAssumption'], exitAssumption))
    return exitAssumption
}

export function* scrapAllData(params: { projectId: number; additionalData: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $additionalData: String!) {
                scrapProjectDataManually(projectId: $projectId, additionalData: $additionalData)
            }
        `,
        {
            projectId: params.projectId,
            additionalData: params.additionalData,
        },
    )

    return response?.data?.scrapProjectDataManually
}

export function* updateOMProforma(params: { projectId: number; additionalData: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $additionalData: String!) {
                updateProjectScrappedData(projectId: $projectId, additionalData: $additionalData)
            }
        `,
        {
            projectId: params.projectId,
            additionalData: params.additionalData,
        },
    )

    return response?.data?.updateProjectScrappedData
}
export function* updateNarrativeTables(params: { projectId: number; narrativeId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $narrativeId: ID!) {
                updateNarrativeTables(projectId: $projectId, narrativeId: $narrativeId)
            }
        `,
        {
            projectId: params.projectId,
            narrativeId: params.narrativeId,
        },
    )

    return response?.data?.updateNarrativeTables
}

export function* setProjectNotesUrl(params: { projectId: number; notesUrl: string }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $notesUrl: String!) {
                setProjectNotesUrl(projectId: $projectId, notesUrl: $notesUrl)
            }
        `,
        {
            ...params,
        },
    )
    return response?.data?.setProjectNotesUrl || false
}
export function* performQuickAnalysis(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                performQuickAnalysis(projectId: $projectId)
            }
        `,
        {
            ...params,
        },
    )
    return response?.data?.performQuickValuationReis || false
}
