import gql from 'graphql-tag'
import { graphQLRequest } from '../../graphQLClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { toBase64 } from '../../../utils'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                mixUseRetail(projectId: $projectId) {
                    enabled
                    content
                    imgs {
                        title
                        file
                    }
                    tenants {
                        name
                        file
                        description
                        size
                        type
                    }
                }
            }
        `,
        { projectId: +params.projectId },
    )

    yield put(
        ReduxHelper.updateInAction(['fullProject', 'mixUseRetail', 'data'], () => {
            return response?.data?.mixUseRetail
        }),
    )
    return response?.data?.mixUseRetail || []
}
export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'mixUseRetail', 'form'], () => {
            return params.fields
        }),
    )
}

export function* setData(projectId: number) {
    const formdata = yield select((store: ReduxStoreState) => store.fullProject.mixUseRetail.form)
    const status = 100
    const data = {
        enabled: formdata.enabled,
        content: formdata.content,
        imgs: [],
        tenants: [],
    }
    for (const img of formdata.imgs) {
        const t = img.title
        let nb = ''
        let bb = null
        let sb = true
        if (img.file) {
            if (img.file.name) {
                bb = yield toBase64(img.file)
                nb = img.file.name
                sb = false
            } else if (typeof img.file === 'string' || img.file instanceof String) {
                nb = img.file
            }
        }

        data.imgs.push({
            title: t,
            file: {
                file_name: nb,
                body: bb,
                skip: sb,
            },
        })
    }
    for (const tenant of formdata.tenants) {
        const n = tenant.name
        const d = tenant.description
        const s = tenant.size
        const t = tenant.type
        let nb = ''
        let bb = null
        let sb = true
        if (tenant.file) {
            if (tenant.file.name) {
                bb = yield toBase64(tenant.file)
                nb = tenant.file.name
                sb = false
            } else if (typeof tenant.file === 'string' || tenant.file instanceof String) {
                nb = tenant.file
            }
        }

        data.tenants.push({
            name: n,
            description: d,
            type: t,
            size: s,
            file: {
                file_name: nb,
                body: bb,
                skip: sb,
            },
        })
    }
    //console.log('data', data)
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: MixUseRetailInput!, $status: Int!) {
                saveMixUseRetail(projectId: $projectId, data: $data, status: $status) {
                    enabled
                    content
                }
            }
        `,
        {
            status,
            projectId,
            data,
        },
    )

    return response.data.saveMixUseRetail
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'mixUseRetail', 'data'], {
            enabled: true,
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'mixUseRetail', 'form'], null))
}
