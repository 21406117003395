import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'

export function* getCompanies(companyName: string) {
    const data = yield graphQLRequest(
        gql`
            query ($companyName: String!) {
                getOpenCorporatesCompanyList(companyName: $companyName) {
                    link
                    name
                    jurisdiction
                    startedAt
                }
            }
        `,
        {
            companyName,
        },
    )
    return data.data.getOpenCorporatesCompanyList
}
export function* scrapCompanyInfoFromOpenCorporates(link: string) {
    const data = yield graphQLRequest(
        gql`
            query ($link: String!) {
                scrapCompanyInfoFromOpenCorporates(link: $link) {
                    number
                    type
                    jurisdiction
                    address
                    agentaddress
                    agentname
                    datefield
                }
            }
        `,
        {
            link,
        },
    )
    //console.log('data', data)
    return data.data.scrapCompanyInfoFromOpenCorporates
}
export function* saveCompanyInfo(id: any, data: any) {
    yield graphQLRequest(
        gql`
            mutation ($companyId: Int!, $data: OpenCorporateCompanyInput!) {
                saveOpenCorporatesCompanyInfo(companyId: $companyId, data: $data)
            }
        `,
        {
            companyId: +id,
            data,
        },
    )
    return true
}
export function* getCorporationWikiCompanyList(companyName: string) {
    const data = yield graphQLRequest(
        gql`
            query ($companyName: String!) {
                getCorporationWikiCompanyList(companyName: $companyName) {
                    link
                    name
                    jurisdiction
                }
            }
        `,
        {
            companyName,
        },
    )
    return data.data.getCorporationWikiCompanyList
}
export function* scrapCompanyInfoFromCorporationWiki(link: string) {
    const data = yield graphQLRequest(
        gql`
            query ($link: String!) {
                scrapCompanyInfoFromCorporationWiki(link: $link) {
                    companyNetwork
                    id
                }
            }
        `,
        {
            link,
        },
    )
    //console.log('data', data)
    return data.data.scrapCompanyInfoFromCorporationWiki
}
export function* saveCorporateNetworkImages(projectId: any, companyId: any, imagesIds: any[]) {
    // saveCorporateNetworkImages
    const data = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $companyId: Int!, $imagesIds: [String!]!) {
                saveCorporateNetworkImages(projectId: $projectId, companyId: $companyId, imagesIds: $imagesIds)
            }
        `,
        {
            projectId: parseInt(projectId),
            companyId: parseInt(companyId),
            imagesIds,
        },
    )
    return data.data.saveCorporateNetworkImages
}
export function* scrapCompanyInfoFromLinkedIn(link: string) {
    const data = yield graphQLRequest(
        gql`
            query ($link: String!) {
                scrapCompanyInfoFromLinkedIn(link: $link) {
                    description
                    logoUrl
                    website
                    people {
                        name
                        position
                        profileImageUrl
                    }
                }
            }
        `,
        {
            link,
        },
    )
    //console.log('data', data)
    return data.data.scrapCompanyInfoFromLinkedIn
}
export function* saveLinkkedInInfo(projectId: any, companyId: any, data: any) {
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $companyId: Int!, $data: LinkedInDataInput!) {
                saveLinkedInData(projectId: $projectId, companyId: $companyId, data: $data)
            }
        `,
        {
            companyId: +companyId,
            projectId: +projectId,
            data,
        },
    )
    return true
}
