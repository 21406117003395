import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put, select } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'

export function* loadSensitivityData(projectId: number) {
    const sensitivityData = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                sensitivityCOCvsIRR(projectId: $projectId) {
                    rowTitles
                    rowValues
                    columnTitles
                    columnValues
                    xAxisName
                    yAxisName
                }
                sensitivityNOIvsFCFRent(projectId: $projectId) {
                    rowTitles
                    rowValues
                    columnTitles
                    columnValues
                    xAxisName
                    yAxisName
                }
                sensitivityNOIvsFCFVacancyMortgage(projectId: $projectId) {
                    rowTitles
                    rowValues
                    columnTitles
                    columnValues
                    xAxisName
                    yAxisName
                }
            }
        `,
        { projectId },
    )
    return sensitivityData.data
}
export function* loadBorrowerNetWorth(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                borrowerNetWorthAndLiquidity(projectId: $projectId) {
                    proforma
                    html
                }
            }
        `,
        { projectId },
    )
    return res.data.borrowerNetWorthAndLiquidity
}
export function* operatingExpenses(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                operatingExpenses(projectId: $projectId) {
                    value
                    warning
                }
            }
        `,
        { projectId },
    )
    return res.data.operatingExpenses
}
export function* dscrRatio(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                dscrRatio(projectId: $projectId) {
                    dscr
                    treshold
                    noi
                }
            }
        `,
        { projectId },
    )
    return res.data.dscrRatio
}

export function* capRateVsMarketCapRate(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                capRateVsMarketCapRate(projectId: $projectId) {
                    threshold
                }
            }
        `,
        { projectId },
    )
    return res.data.capRateVsMarketCapRate
}
export function* fcfAfterDebtVsThreshold(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                fcfAfterDebtVsThreshold(projectId: $projectId) {
                    threshold
                }
            }
        `,
        { projectId },
    )
    return res.data.fcfAfterDebtVsThreshold
}

export function* fcfBeforeDebtVsThreshold(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                fcfBeforeDebtVsThreshold(projectId: $projectId) {
                    threshold
                }
            }
        `,
        { projectId },
    )
    return res.data.fcfBeforeDebtVsThreshold
}
export function* debtYieldVsThreshold(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                debtYieldVsThreshold(projectId: $projectId) {
                    threshold
                }
            }
        `,
        { projectId },
    )
    return res.data.debtYieldVsThreshold
}
export function* keyPrincipalsInDefault(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                keyPrincipalsInDefault(projectId: $projectId) {
                    keyPrincipalsInDefault
                    keyPrincipalsInDefaultWarning
                }
            }
        `,
        { projectId },
    )
    return res.data.keyPrincipalsInDefault
}
export function* lenderCashFlowToggleSelectedRow(p: { rowId: string }) {
    const ids: string[] = yield select(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.selectedRowIds,
    )
    if (ids.includes(p.rowId)) {
        const ind = ids.indexOf(p.rowId)
        ids.splice(ind, 1)
    } else {
        ids.push(p.rowId)
    }
    yield put(ReduxHelper.setInAction(['lenderDashboard', 'lenderCashFlowTab', 'selectedRowIds'], [...ids]))
    return true
}
