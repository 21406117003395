const API_PREFIX = '/api/v1'

export const API = Object.freeze({
    FULL_PROJECT: {
        STATUS: `${API_PREFIX}/project.status.json`,
        DEAL_OUTLINE: `${API_PREFIX}/deal.outline.json`,
        THE_PROPERTY: `${API_PREFIX}/the.property.json`,
        MARKET_OVERVIEW: `${API_PREFIX}/market.json`,
        SPONSORS: `${API_PREFIX}/sponsors.json`,
        PAST_PORTFOLIO: `${API_PREFIX}/protfolio.json`,
        SPONSOR_COMPANY_INFO: `${API_PREFIX}/companyinfo.json`,
        PRO_FORMA_USER_INPUT: `${API_PREFIX}/proforma.json`,
        SHORT_PROFORMA: `${API_PREFIX}/shortproforma.json`,
        DEAL_HIGHLIGHTS_PART_I: `${API_PREFIX}/deal.highlights.json`,
        DEAL_HIGHLIGHTS_PART_II: `${API_PREFIX}/deal.highlights.json`,
        FILES_UPLOAD: `${API_PREFIX}/files.json`,
        ZONING: `${API_PREFIX}/zoning.json`,
        PAST_TRANSACTIONS: `${API_PREFIX}/past.transactions.json`,
        MORTGAGE_RECORDS: `${API_PREFIX}/mortgage.records.json`,
        PROPERTY_ENRICHMENTS: `${API_PREFIX}/property.enrichments.json`,
        GRID_LOCATIONS: `${API_PREFIX}/gridlocations.json`,
        EMPLOYMENT_LOCATIONS: `${API_PREFIX}/emplocations.json`,
        EMPLOYMENT_COMPANIES: `${API_PREFIX}/empcompanies.json`,
        EMPLOYMENT: `${API_PREFIX}/employment.json`,
        COMPARABLES: `${API_PREFIX}/comparables.json`,
        PUBLISH: `${API_PREFIX}/publish.json`,
    },
    LOGIN: `${API_PREFIX}/login.json`,
    PROXY: `/internal/proxy.php`,
    DATA_USA: 'https://datausa.io/api/data',
})
