import { getAxios } from '../../axiosClient'
import { API } from '../../../utils/constants/api'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { put, select, delay } from '@redux-saga/core/effects'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import moment from 'moment'

const API_URL = API.FULL_PROJECT.PUBLISH

export function* setProjectId(params: { projectId: number }) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'publish', 'projectId'], () => params.projectId))
}

export function* publish() {
    const token = (store: ReduxStoreState) => store.fullProject.publish.projectId
    const projectId = yield select(token)
    if (projectId) {
        const response = yield getAxios().post(API_URL, { projectId })
        const user = yield select((state: ReduxStoreState) => state.user)
        if (user.flow != 'comps_only') {
            gtag('event', 'project_published', {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_category: 'dashboard',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_label: 'Project published',
                // eslint-disable-next-line @typescript-eslint/camelcase
                account_id: user.accountid,
                user_id: user.id,
                user_name: user.username,
                project_id: projectId,
                // eslint-disable-next-line @typescript-eslint/camelcase
                transport_type: 'beacon',
            })
        } else {
            gtag('event', 'click_calculate_comps', {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_category: 'dashboard',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_label: 'Click Calculate Comps',
                // eslint-disable-next-line @typescript-eslint/camelcase
                account_id: user.accountid,
                user_id: user.id,
                user_name: user.username,
                // eslint-disable-next-line @typescript-eslint/camelcase
                project_id: projectId,
                transport_type: 'beacon',
            })
        }

        return response.data
    }
}

export function* waitForScrappingFinish(params: { projectId: number }) {
    const { projectId } = params
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], true))
    let res
    do {
        const response = yield graphQLRequest(
            gql`
                query($projectId: Float!) {
                    projectScrapStatus(projectId: $projectId) {
                        isInProgress
                        started
                    }
                }
            `,
            {
                projectId,
            },
        )
        res = response?.data?.projectScrapStatus
        if (res.isInProgress) {
            yield delay(1000)
        }
    } while (res.isInProgress)
    yield put(ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], false))
    return true
}
export function* checkProjectIsBeingScraped(params: { projectId: number }) {
    const { projectId } = params

    const response = yield graphQLRequest(
        gql`
            query($projectId: Float!) {
                projectScrapStatus(projectId: $projectId) {
                    isInProgress
                    started
                    isCompsOnly
                    progress
                }
            }
        `,
        {
            projectId,
        },
    )

    if (response?.data?.projectScrapStatus.isInProgress) {
        yield put(
            ReduxHelper.setInAction(
                ['fullProject', 'scrapingStarted'],
                moment(response?.data?.projectScrapStatus.started).toDate(),
            ),
        )
        yield put(
            ReduxHelper.setInAction(['fullProject', 'scrapProgress'], response?.data?.projectScrapStatus.progress),
        )
    }

    yield put(
        ReduxHelper.setInAction(['fullProject', 'isBeingScraped'], response?.data?.projectScrapStatus.isInProgress),
    )

    yield delay(100)
    return response?.data?.projectScrapStatus
}
