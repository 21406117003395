import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { TaxRecord } from '../../../generated/graphql'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper } from '../../index'

export function* loadTaxRecords(projectId: number, onlyResponse?: boolean): Generator<any, TaxRecord[], any> {
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                taxRecords(projectId: $projectId) {
                    taxDataVisible
                    taxRecords {
                        id
                        year
                        taxAmount
                        assessedImprovementValue
                        assessedLandValue
                        totalAssessedValue
                        improvementMarketValue
                        landMarketValue
                        totalMarketValue
                        parcelId
                        address
                        taxingJurisdiction
                        fixedCharges
                        ismanual
                        owner
                    }
                }
            }
        `,
        {
            projectId,
        },
    )
    const data = response.data.taxRecords.taxRecords.length > 0 ? response.data.taxRecords.taxRecords : [{}]

    if (!onlyResponse) {
        yield put(ReduxHelper.setInAction(['fullProject', 'taxRecords', 'data'], data))
        yield put(
            ReduxHelper.setInAction(
                ['fullProject', 'taxRecords', 'taxDataVisible'],
                !!response?.data?.taxRecords?.taxDataVisible,
            ),
        )
    }

    return data
}
export function* updateTaxRecords(projectId: number, data: any) {
    //console.log('data', data)
    yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: [TaxRecordInput!]!, $deleteIds: [Int!]!, $taxDataVisible: Boolean!) {
                updateTaxRecords(
                    projectId: $projectId
                    data: $data
                    deleteIds: $deleteIds
                    taxDataVisible: $taxDataVisible
                )
            }
        `,
        {
            projectId,
            data: data.data,
            deleteIds: data.deleteIds || [],
            taxDataVisible: !!data.taxDataVisible,
        },
    )
    return true
}
export function* searchRecords(projectId: number, geometricSearch: boolean) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $geometricSearch: Boolean!) {
                searchRecords(projectId: $projectId, geometricSearch: $geometricSearch)
            }
        `,
        { projectId, geometricSearch },
    )
    return response.data.searchRecords
}
export function* scrapTaxRecords(projectId: number) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!) {
                scrapTaxRecords(projectId: $projectId) {
                    id
                    year
                    taxAmount
                    assessedImprovementValue
                    assessedLandValue
                    totalAssessedValue
                    improvementMarketValue
                    landMarketValue
                    totalMarketValue
                }
            }
        `,
        { projectId },
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'taxRecords', 'data'], response.data?.scrapTaxRecords || []))
    return response.data.scrapTaxRecords || []
}

export function* scrapTaxRecordsByParcelID(projectId: number, ids: string[]) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $ids: [String!]!) {
                scrapTaxRecordsByParcelID(projectId: $projectId, ids: $ids) {
                    id
                }
            }
        `,
        { projectId, ids },
    )
    return response?.data?.scrapTaxRecordsByParcelID || []
}

export function* createTaxRecord(projectId: number, parcelId: string, year: number) {
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $parcelId: String!, $year: Int!) {
                createTaxRecord(projectId: $projectId, parcelId: $parcelId, year: $year)
            }
        `,
        { projectId, parcelId, year },
    )
    return response.data.createTaxRecord
}
