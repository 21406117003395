import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus, toBase64 } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'

const API_URL = API.FULL_PROJECT.MARKET_OVERVIEW

type getMarketOverviewParams = {
    projectId: string | any
}

export function* getMarketOverview(params: getMarketOverviewParams) {
    if (params.projectId) {
        const response = yield graphQLRequest(
            gql`
                query ($projectId: Int!) {
                    getMarketOverview(projectId: $projectId) {
                        locationname
                        explanation
                        description
                        blurb
                        mapimg
                    }
                }
            `,
            {
                projectId: +params.projectId,
            },
        )
        yield put(
            ReduxHelper.mergeInAction(['fullProject', 'marketOverview', 'data'], response?.data?.getMarketOverview),
        )
        return response.data
    }
}

export function* setMarketOverview(params: { projectId: number; data: any }) {
    const formData = yield select((store: ReduxStoreState) => store.fullProject.marketOverview.form)
    //console.log('FORM DATA', params)
    let status = calculateProjectStepStatus(
        { ...formData },
        {
            description: 'value',
            name: 'value',
            blurb: 'value',
            explanation: 'value',
        },
    )
    formData.status = status
    const data = params.data
    if (data.mapimg && data.mapimg.name) {
        const body = yield toBase64(data.mapimg)
        data.mapimg = {
            file_name: data.mapimg.name,
            body,
            skip: false,
        }
    } else {
        data.mapimg = null
    }
    const response = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: MarketOverviewInputGraphQL!) {
                saveMarketOverView(projectId: $projectId, data: $data)
            }
        `,
        {
            projectId: +params.projectId,
            data: data,
        },
    )
    //const response = yield getAxios().post(API_URL, formData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketOverview', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.MARKET_OVERVIEW], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'marketOverview', 'form'], () => params))
}
