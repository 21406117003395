import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.DEAL_OUTLINE

// eslint-disable-next-line @typescript-eslint/naming-convention
type getDealOutlineParams = {
    projectId: string | any
}

export function* getFullProjectDealOutline(params: getDealOutlineParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        const data = response?.data?.project
        if (data?.loans?.length === 0) {
            data.loans.push({
                type: '',
                amount: '',
                intrest: '',
            })
        }

        data.financingRequirementsChartVisible = data.financingRequirementsChartVisible == '1' ? ['1'] : []

        yield put(ReduxHelper.mergeInAction(['fullProject', 'dealOutline', 'data'], data))
        return response.data
    }
}

export function* setFullProjectDealOutline(formData: any) {
    const status = calculateProjectStepStatus(formData, {
        state: 'value',
        city: 'value',
        neighborhood: 'value',
        street: 'value',
        number: 'value',
        zip: 'value',
        loans: 'array',
        blurb: 'value',
    })

    formData.status = status
    formData.financingRequirementsChartVisible =
        formData?.financingRequirementsChartVisible?.length > 0 && formData?.financingRequirementsChartVisible[0] == '1'
            ? '1'
            : '0'

    if (formData?.loans) {
        for (const i in formData.loans) {
            const loan = formData.loans[i]
            loan.showOnSummaryPage = loan.showOnSummaryPage ? '1' : '0'
            loan.showOnDealHighlightsPage = loan.showOnDealHighlightsPage ? '1' : '0'
            loan.showOnProformaPage = loan.showOnProformaPage ? '1' : '0'
        }
    }

    const response = yield getAxios().post(API_URL, formData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealOutline', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.DEAL_OUTLINE], () => status))
    return response.data
}

export function* addFullProjectLoan() {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'dealOutline', 'data', 'loans'], (loans) => {
            return [
                ...loans,
                {
                    type: '',
                    amount: '',
                    intrest: '',
                },
            ]
        }),
    )
}

export function* setFullProjectLoans(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealOutline', 'data', 'loans'], () => params))
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'dealOutline', 'form'], () => params))
}
