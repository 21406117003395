import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { RentComparableFloorPlanFields, RentComparablePropertyItemUnits } from '../../../generated/graphql'

export function* getData(params: { projectId }) {
    const response = yield graphQLRequest(
        gql`
            query($projectId: Int!) {
                rentComparable(projectId: $projectId) {
                    enabled
                    effectiveRent
                    effectiveRentPerSqFt
                    marketRent
                    marketRentPerSqFt
                    marketTrend
                    propertyTrend
                }
            }
        `,
        {
            projectId: params.projectId,
        },
    )

    if (response?.data?.rentComparable?.floorPlanComparables?.length == 0) {
        response.data.rentComparable.floorPlanComparables.push({
            properties: [{}],
        })
    }
    if (response?.data?.rentComparable?.propertyComparables?.length == 0) {
        response.data.rentComparable.propertyComparables.push({
            units: [{}],
        })
    }

    yield put(ReduxHelper.setInAction(['fullProject', 'rentComparables', 'data'], response.data.rentComparable))

    return response.data.rentComparable
}

export function* setFormData(params: { fields }) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'rentComparables', 'form'], () => {
            return params.fields
        }),
    )
}

export function* addItem(params: { type: string }) {
    yield put(
        // @ts-ignore
        ReduxHelper.updateInAction(['fullProject', 'rentComparables', 'data', params.type], (items) => {
            const newItems = items ? items : []
            return [...newItems, {}]
        }),
    )
}

export function* addFloorPlanProperty(params: { index: number }) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'rentComparables', 'data', 'floorPlanComparables', params.index, 'properties'],
            (items) => {
                const newItems = items ? items : []
                return [...newItems, {}]
            },
        ),
    )
}

export function* addComparableUnit(params: { index: number }) {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'rentComparables', 'data', 'propertyComparables', params.index, 'units'],
            (items) => {
                const newItems = items ? items : []
                return [...newItems, {}]
            },
        ),
    )
}

export function* setItems(params: { type: string; newItems: Array<any> }) {
    yield put(
        // @ts-ignore
        ReduxHelper.setInAction(['fullProject', 'rentComparables', 'data', params.type], params.newItems),
    )
}

export function* setFloorPlanProperties(params: { index: number; newItems: Array<RentComparableFloorPlanFields> }) {
    yield put(
        ReduxHelper.setInAction(
            ['fullProject', 'rentComparables', 'data', 'floorPlanComparables', params.index, 'properties'],
            params.newItems,
        ),
    )
}

export function* setComparableUnits(params: { index: number; newItems: Array<RentComparablePropertyItemUnits> }) {
    yield put(
        ReduxHelper.setInAction(
            ['fullProject', 'rentComparables', 'data', 'propertyComparables', params.index, 'units'],
            params.newItems,
        ),
    )
}

export function* setData(p: { projectId: number; data: any }) {
    const projectId = p.projectId
    const enabled = p.data.enabled
    const data = p.data
    delete p.data.enabled

    data.effectiveRentPerSqFt = +data.effectiveRentPerSqFt
    data.marketRent = +data.marketRent
    data.marketRentPerSqFt = +data.marketRentPerSqFt
    data.effectiveRent = +data.effectiveRent
    data.marketTrend = +data.marketTrend
    data.propertyTrend = +data.propertyTrend

    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!, $data: RentComparableInput!, $status: Int!, $enabled: Boolean!) {
                saveRentComparable(projectId: $projectId, data: $data, enabled: $enabled, status: $status)
            }
        `,
        {
            projectId,
            data,
            status: 100,
            enabled,
        },
    )

    return response?.data?.saveRentComparable
}

export function* clearData() {
    yield put(
        ReduxHelper.setInAction(['fullProject', 'rentComparables', 'data'], {
            effectiveRent: 0,
            effectiveRentPerSqFt: 0,
            enabled: true,
            floorPlanComparables: [],
            marketRent: 0,
            marketRentPerSqFt: 0,
            propertyComparables: [],
        }),
    )
    yield put(ReduxHelper.setInAction(['fullProject', 'rentComparables', 'form'], null))
}
