import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.ZONING

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        yield put(ReduxHelper.mergeInAction(['fullProject', 'zoning', 'data'], response?.data?.project?.zoning))
        return response.data
    }
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.zoning.form
    const formData = yield select(token)

    let status = calculateProjectStepStatus(formData, {
        current: 'value',
        frontsize: 'value',
        interiorsize: 'value',
        maxheight: 'value',
        minlotsize: 'value',
        parking: 'value',
        parkingreq: 'value',
        rearsize: 'value',
        usespermited: 'value',
    })

    formData.status = status

    const response = yield getAxios().post(API_URL, formData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'zoning', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.ZONING], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'zoning', 'form'], () => params))
}
