import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'
import { put } from '@redux-saga/core/effects'
import { ReduxHelper } from '../../index'
import { LenderProjectSettingsInput } from '../../../generated/graphql'
import data from 'devextreme'

export function* getData(params: { projectId: number }) {
    const { projectId } = params
    if (!projectId) return false
    const response = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                getLenderProjectSettings(projectId: $projectId) {
                    #                    grossPotentialRent
                    #                    anotherIncome
                    #                    operationalExpenses
                    #                    capitalExpenses
                    #                    anotherExpenses
                    #                    mortgageAmount
                    #                    mortgageRate
                    #                    mortgageTermYears
                    #                    freeCashFlowBeforeDebt
                    #                    freeCashFlowAfterDebt
                    #                    netRent
                    #                    dscr
                    #                    noi
                    #                    capRate
                    #                    marketCapRate
                    #                    debtYield
                    blockCFChanges
                    blockFilesUploading
                    blockRecalculationOfAssumptions
                    useNCFnotNOI
                    #                    proForma {
                    #                        label
                    #                        value
                    #                    }
                }
            }
        `,
        {
            projectId,
        },
    )
    yield put(ReduxHelper.setInAction(['lender', 'projectSettings'], response?.data?.getLenderProjectSettings))
    return response?.data?.getLenderProjectSettings
}

export function* setData(params: { projectId: number; data: LenderProjectSettingsInput }) {
    // for (const key in params.data) {
    //     if (params.data.hasOwnProperty(key) && typeof params.data[key] == 'string') {
    //         params.data[key] = +params.data[key]
    //     }
    // }
    //
    // const requiredFields = [
    //     'netRent',
    //     'freeCashFlowAfterDebt',
    //     'freeCashFlowBeforeDebt',
    //     'mortgageRate',
    //     'mortgageAmount',
    //     'anotherExpenses',
    //     'capitalExpenses',
    //     'operationalExpenses',
    //     'anotherIncome',
    //     'grossPotentialRent',
    // ]
    //
    // for (const field of requiredFields) {
    //     if (params.data[field] == null) {
    //         params.data[field] = 0
    //     }
    // }

    return yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $data: LenderProjectSettingsInput!) {
                setLenderProjectSettings(projectId: $projectId, data: $data)
            }
        `,
        {
            ...params,
        },
    )
}

export function* clearData() {
    yield put(ReduxHelper.setInAction(['lender', 'projectSettings'], null))
}
