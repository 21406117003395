import { getAxios } from '../../axiosClient'
import { put } from 'redux-saga/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { select } from 'redux-saga/effects'
import { API } from '../../../utils/constants/api'
import { calculateProjectStepStatus } from '../../../utils'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.PRO_FORMA_USER_INPUT

type GetDataParams = {
    projectId: string | any
}

export function* getData(params: GetDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })
        const data = response?.data?.project
        data.dont_calc_avg_values = data.dont_calc_avg_values == '1' ? ['1'] : []
        data.displayImagesAtMinisite = data.displayImagesAtMinisite == '1' ? ['1'] : []
        yield put(ReduxHelper.mergeInAction(['fullProject', 'proformaUserInput', 'data'], data))
        return response.data
    }
}

export function* setData() {
    const token = (store: ReduxStoreState) => store.fullProject.proformaUserInput.form
    const formData = yield select(token)
    const status = calculateProjectStepStatus(formData, {
        avg_cost_sqft: 'value',
        avg_rent_sqft: 'value',
        avg_sale_sqft: 'value',
        gross_revenue: 'value',
        totalcost: 'value',
        totalsale: 'value',
    })

    formData.dont_calc_avg_values =
        formData.dont_calc_avg_values.length > 0 && formData.dont_calc_avg_values[0] == '1' ? '1' : '0'

    formData.displayImagesAtMinisite =
        formData.displayImagesAtMinisite.length > 0 && formData.displayImagesAtMinisite[0] == '1' ? '1' : '0'

    formData.status = status

    const response = yield getAxios().post(API_URL, formData)
    yield put(ReduxHelper.updateInAction(['fullProject', 'proformaUserInput', 'data'], () => formData))
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.PRO_FORMA_USER_INPUT], () => status))
    return response.data
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'proformaUserInput', 'form'], () => params))
}

export function* scrapData(params: { projectId: number }) {
    const response = yield graphQLRequest(
        gql`
            mutation($projectId: Int!) {
                scrapProForma(projectId: $projectId) {
                    projectid
                }
            }
        `,
        {
            ...params,
        },
    )

    return response?.data?.scrapProForma || false
}
