import * as Sentry from '@sentry/browser'

import getConfig from 'next/config'

const DISABLE = false
const SEND_COOKIE = false
if (!DISABLE) {
    const options: any = {
        dsn: 'https://b7fb3efe6310477181fe3b28b61c9b26@o1407225.ingest.sentry.io/6741742',
    }
    let init = true
    try {
        const { Config } = require('../config')
        options.environment = Config.ENV
        if (Config?.ENV && ['development', 'dv', 'qa'].includes(Config.ENV.toLowerCase())) init = false
    } catch (e) {}

    try {
        const currentUrl = '' + window.location
        if (
            currentUrl.indexOf('127.0.0.1') > -1 ||
            currentUrl.indexOf('localhost') > -1 ||
            currentUrl.indexOf('qa.dashboard.seecares.com') > -1
        )
            init = false
        //console.log('init', init)
    } catch (e) {}
    if (init) Sentry.init(options)
}

export const getErrorReportingConfig = () => {
    const { publicRuntimeConfig } = getConfig()
    // console.log('getErrorReportingConfig', 'process.env', process.env.NODE_ENV)
    //console.log('getErrorReportingConfig', 'publicRuntimeConfig', publicRuntimeConfig)
}
export const reportError = async (env, error, requestDataStr: string = null, errorDaraStr: string = null) => {
    // console.log('reportError', env, error)
    try {
        const { publicRuntimeConfig } = getConfig()
        if (['development', 'dev', 'qa'].includes(publicRuntimeConfig?.env?.toLowerCase())) {
            console.log('reportError dev return')
            return
        }
    } catch (e) {}
    if (env && ['development', 'dev', 'qa'].includes(env.toLowerCase())) return
    if (!env || env == '') env = 'unknown'
    Sentry.setContext('env', {
        env: env,
    })
    Sentry.configureScope((scope) =>
        scope.addEventProcessor((event) => ({
            ...event,
            environment: env,
        })),
    )
    // Add an attachment
    Sentry.configureScope((scope) => {
        if (requestDataStr)
            scope.addAttachment({
                filename: 'request.json',
                data: requestDataStr,
            })
        if (errorDaraStr) {
            scope.addAttachment({
                filename: 'error.json',
                data: errorDaraStr,
            })
        }
        if (SEND_COOKIE && document?.cookie) {
            scope.addAttachment({
                filename: 'data.json',
                data: document?.cookie,
            })
        }
    })
    Sentry.captureException(error)
    // Clear attachments
    Sentry.configureScope((scope) => {
        scope.clearAttachments()
    })
}

// Sentry.captureMessage('saved good')
