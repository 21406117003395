import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { ThePropertyAmenity, ThePropertyUnit } from '../../types/fullproject'
import { objectToFormData, makeRandomId, calculateProjectStepStatus } from '../../../utils'
import { API } from '../../../utils/constants/api'
import { STEP_ID } from '../../../utils/constants/projectStepIds'
import { graphQLRequest } from '../../graphQLClient'
import gql from 'graphql-tag'

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_URL = API.FULL_PROJECT.THE_PROPERTY

// eslint-disable-next-line @typescript-eslint/naming-convention
type getDataParams = {
    projectId: string | any
}
export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        const data = response?.data?.project
        if (data) {
            response.data.project.unitMixChartVisible = response?.data?.project?.unitMixChartVisible == '1' ? ['1'] : []

            if (data.amenties?.length === 0) {
                data.amenties.push({
                    id: null,
                    name: null,
                    description: null,
                    imgname: null,
                    imgurl: null,
                    thumburl: null,
                })
            }
        }

        const response2 = yield graphQLRequest(
            gql`
                query ($projectId: Int!) {
                    getUnitMixList(projectId: $projectId) {
                        id
                        projectid
                        numofunits
                        floorPlan
                        bedrooms
                        rentprice
                        baths
                        unitsize
                        status
                        unitsToRenovate
                        stabilizedRentPrice
                        originalrentprice
                        overhang
                    }
                }
            `,
            {
                projectId: +params.projectId,
            },
        )
        if (data) {
            data.unitmixs = response2?.data?.getUnitMixList || []

            if (data.unitmixs?.length === 0) {
                data.unitmixs.push({
                    bedrooms: '',
                    baths: '',
                    unitsize: '',
                    rentprice: '',
                    numofunits: '',
                })
            }
        }
        yield put(ReduxHelper.mergeInAction(['fullProject', 'theProperty', 'data'], data))
        return data
    }
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'theProperty', 'form'], () => params))
}

export function* setData(data: any) {
    const status = calculateProjectStepStatus(data, {
        amenties: 'array',
        unitmixs: 'array',
        description: 'value',
        floorplan: 'value',
    })

    data.status = status

    data.unitMixChartVisible = data.unitMixChartVisible.length > 0 && data.unitMixChartVisible[0] == '1' ? '1' : '0'

    const preparedData: Record<string, any> = {
        propertyDetails: '',
    }

    for (const i in data.amenties) {
        const fileField = data?.amenties?.[i]?.[`formname`]
        if (fileField && typeof fileField.name == 'string') {
            const randomId = makeRandomId()
            if (!fileField.data64) {
                // @ts-ignore
                preparedData[randomId] = fileField
            } else {
                const res = yield fetch(fileField.data64)
                preparedData[randomId] = yield res.blob()
            }
            data.amenties[i].formname = randomId
        }
    }
    if (data.floorplan && data.floorplan.name) {
        preparedData.floorplan = data.floorplan
        data.floorplan = 'floorplan'
    }
    preparedData.propertyDetails = JSON.stringify(data)

    let filesCount = 1
    for (const i in data.externalImgs) {
        const item = data?.externalImgs?.[i]
        if (typeof item?.name === 'string' && typeof item?.type === 'string') {
            preparedData[`externalImg${filesCount}`] = item
            filesCount++
        }
    }
    // floor plan

    const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
    // save unitMix
    const responseUnits = yield graphQLRequest(
        gql`
            mutation ($projectId: Int!, $unitMix: [UnitMixInput!]!) {
                setUnitMixList(projectId: $projectId, unitMix: $unitMix)
            }
        `,
        {
            projectId: +data.projectId,
            unitMix: data.unitmixs.map((unit) => {
                const numberFields = [
                    'id',
                    'projectid',
                    'numofunits',
                    'bedrooms',
                    'rentprice',
                    'baths',
                    'unitsize',
                    'unitsToRenovate',
                    'stabilizedRentPrice',
                    'originalrentprice',
                ]
                for (const fld in unit) {
                    if (typeof unit[fld] == 'string' && numberFields.includes(fld)) {
                        unit[fld] = +unit[fld]
                    }
                }
                return unit
            }),
        },
    )
    yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.THE_PROPERTY], () => status))
    yield put(ReduxHelper.setInAction(['fullProject', 'theProperty', 'data', 'pictures'], []))
    return response.data

    // yield put(ReduxHelper.updateInAction(['fullProject', 'theProperty', 'data'], () => data.mortgagerecords))
}

export function* addAmenity() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'theProperty', 'data', 'amenties'],
            (amenties: Array<ThePropertyAmenity>) => {
                return [
                    ...amenties,
                    {
                        id: null,
                        name: null,
                        description: null,
                        imgname: null,
                        imgurl: null,
                        thumburl: null,
                    },
                ]
            },
        ),
    )
}

export function* setAmenities(params: Array<ThePropertyAmenity>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'theProperty', 'data', 'amenties'], () => params))
}

export function* addUnit() {
    yield put(
        ReduxHelper.updateInAction(
            ['fullProject', 'theProperty', 'data', 'unitmixs'],
            (units: Array<ThePropertyUnit>) => {
                return [
                    ...units,
                    {
                        bedrooms: '',
                        baths: '',
                        unitsize: '',
                        rentprice: '',
                        numofunits: '',
                    },
                ]
            },
        ),
    )
}

export function* setUnits(params: Array<ThePropertyUnit>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'theProperty', 'data', 'unitmixs'], () => params))
}

export function* addFile(params: any) {
    yield put(
        ReduxHelper.updateInAction(['fullProject', 'theProperty', 'data', 'externalImgs'], (files: Array<any>) => {
            return [
                ...files,
                {
                    name: params || '',
                    description: '',
                    url: '',
                    thumburl: '',
                },
            ]
        }),
    )
}

export function* setFiles(params: Array<any>) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'theProperty', 'data', 'externalImgs'], () => params))
}
export function* scrapAmenities(projectId: number) {
    const res = yield graphQLRequest(
        gql`
            query ($projectId: Int!) {
                scrapProjectAmenities(projectId: $projectId) {
                    names
                    pictures
                }
            }
        `,
        {
            projectId,
        },
    )
    if (res.data.scrapProjectAmenities.names.length > 0) {
        yield put(
            ReduxHelper.updateInAction(
                ['fullProject', 'theProperty', 'data', 'amenties'],
                (amenties: Array<ThePropertyAmenity>) => {
                    let out = []
                    out = out.concat(amenties)
                    for (const a of res.data.scrapProjectAmenities.names) {
                        if (
                            out.findIndex((item) => {
                                return item.description == a
                            }) == -1
                        )
                            out.push({
                                id: null,
                                name: null,
                                description: a,
                                imgname: null,
                                imgurl: null,
                                thumburl: null,
                            })
                    }
                    return out
                },
            ),
        )
        yield put(
            ReduxHelper.setInAction(
                ['fullProject', 'theProperty', 'data', 'pictures'],
                res.data.scrapProjectAmenities.pictures,
            ),
        )
    } else throw new Error('No data found')
    return res.data.scrapProjectAmenities
    //scrapProjectAmenities
}
