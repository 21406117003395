import { API } from '../../../utils/constants/api'
import { getAxios } from '../../axiosClient'
import { put, select } from '@redux-saga/core/effects'
import { ReduxHelper, ReduxStoreState } from '../../index'
import { objectToFormData } from '../../../utils'

import { STEP_ID } from '../../../utils/constants/projectStepIds'

const API_URL = API.FULL_PROJECT.SHORT_PROFORMA

type getDataParams = {
    projectId: string | any
}

export function* getData(params: getDataParams) {
    if (params.projectId) {
        const response = yield getAxios().get(API_URL, {
            params: {
                projectid: params.projectId,
            },
        })

        if (!response?.data?.project?.proforma) {
            yield put(ReduxHelper.updateInAction(['fullProject', 'shortProforma', 'isDefault'], () => true))
        }

        yield put(ReduxHelper.mergeInAction(['fullProject', 'shortProforma', 'data'], response?.data?.project))
        return response.data
    }
}

export function* clearData() {
    yield put(
        ReduxHelper.mergeInAction(['fullProject', 'shortProforma', 'data'], {
            proforma: '',
            default: '',
        }),
    )
}

export function* setFormData(params: any) {
    yield put(ReduxHelper.updateInAction(['fullProject', 'shortProforma', 'form'], () => params))
}

export function* setData() {
    const dataToken = (store: ReduxStoreState) => store.fullProject.shortProforma.form
    const initialToken = (store: ReduxStoreState) => store.fullProject.shortProforma.data.proforma
    const defaultToken = (store: ReduxStoreState) => store.fullProject.shortProforma.data.default
    const data = yield select(dataToken)
    const initialData = yield select(initialToken)
    const defaultData = yield select(defaultToken)

    // console.log(JSON.stringify(JSON.parse(initialData)))

    let initialParsed = initialData ? JSON.stringify(JSON.parse(initialData)) : ''
    let defaultParsed = defaultData ? JSON.stringify(JSON.parse(defaultData)) : ''
    let dataParsed = data?.proforma ? JSON.parse(JSON.stringify(data?.proforma)) : ''
    let dataNotChanged = dataParsed == defaultParsed || dataParsed == initialParsed

    if (data && !dataNotChanged) {
        let preparedData = {
            propertyDetails: JSON.stringify(data),
        }
        const response = yield getAxios().post(API_URL, objectToFormData(preparedData))
        yield put(ReduxHelper.updateInAction(['fullProject', 'shortProforma', 'isDefault'], () => false))
        yield put(ReduxHelper.updateInAction(['fullProject', 'status', STEP_ID.SHORT_PROFORMA], () => data.status))

        return response.data
    }
}
